import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { ExamResultsYesNoEnum } from '../../../../Common/entities';
import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { ExamResultsFeedbackZoomConsent } from '../../../../Feedback/entities';
import { submitExamResultsFeedbackForm } from '../../../../Feedback/services/slices';
import {
  SAT_RESULTS_SLICE_NAME,
  setSATIsFeedbackSubmitted,
} from '../SATResultsSlice';

export const trackSATResultsFeedbackData = createAsyncThunk(
  `${SAT_RESULTS_SLICE_NAME}/trackSATResultsFeedbackData`,
  async (_, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const satResultsState = rootState.SATResults;

    let feedbackFormZoomConsent: ExamResultsFeedbackZoomConsent | undefined;
    if (satResultsState.zoomChatConsent) {
      feedbackFormZoomConsent =
        satResultsState.zoomChatConsent === ExamResultsYesNoEnum.YES
          ? ExamResultsFeedbackZoomConsent.YES
          : ExamResultsFeedbackZoomConsent.NO;
    }

    trackAnalyticsEvent(Analytics.examPreparationFeedbackSubmitted, {
      feedback: satResultsState.satResultsFeedback,
      zoomChatConsent: satResultsState.zoomChatConsent ?? '',
    });
    thunkAPI.dispatch(setSATIsFeedbackSubmitted(true));

    if (
      !satResultsState.satResultsFeedback?.trim() &&
      feedbackFormZoomConsent === undefined
    ) {
      return;
    }

    thunkAPI.dispatch(
      submitExamResultsFeedbackForm({
        feedback: satResultsState.satResultsFeedback || '',
        zoomConsent: feedbackFormZoomConsent,
      }),
    );
  },
);
