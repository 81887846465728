import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { SetAndCalculateSATCategoryScoreParams } from '../../../entities';
import { mapSatScoreAutoSelection } from '../../mappers';
import { getSATLastScoreValueByKey } from '../../utils';
import {
  ONBOARDING_SLICE_NAME,
  OnboardingSliceState,
} from '../OnboardingSlice';

export const setAndCalculateSATCategoryLastScore = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/setAndCalculateSATCategoryLastScore`,
  async (params: SetAndCalculateSATCategoryScoreParams, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const state = rootState.onboarding;
    const examLastScore = getSATLastScoreValueByKey(state.examLastScoreKey);

    if (!examLastScore) {
      return { skip: true, math: 0, reading: 0 };
    }

    const { math, readingWriting } = mapSatScoreAutoSelection(
      examLastScore,
      params.category,
      params.unitCategoryScore,
    );

    return { skip: false, math, readingWriting };
  },
);

export const setAndCalculateSATCategoryLastScoreExtraReducers = (
  builder: ActionReducerMapBuilder<OnboardingSliceState>,
): void => {
  builder.addCase(
    setAndCalculateSATCategoryLastScore.fulfilled,
    (state, action) => {
      const { skip, math, readingWriting } = action.payload;
      const { category } = action.meta.arg;

      if (skip) {
        return;
      }

      state.lastSelectedSATUnitCategory = category;
      state.lastSATMathScore = math;
      state.lastSATReadingWritingScore = readingWriting;
    },
  );
};
