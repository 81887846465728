import { RootState } from '../../../App/store';
import { ExamResultsYesNoEnum } from '../../../Common/entities';
import { SATLastScoreKey } from '../../../Onboarding/entities';
import { getSATLastScoreValueByKey } from '../../../Onboarding/services/utils';
import {
  CheckIfSATResultsRetakeInputValidProps,
  SATResultsCongratulationsMessageType,
  SubmitSATResultsAcademicDataRequest,
} from '../../entities';

const MIN_SCORE_DIFFERENCE_WITH_LAST_SCORE = 50;

export const mapSATResultsCongratulationMessageType = (
  state: RootState,
): SATResultsCongratulationsMessageType => {
  const targetScore = state.SATResults.lastSATTargetScore ?? 0;
  const lastScore = state.SATResults.lastSATCurrentScore ?? 0;
  const selectedExamResultKey = state.SATResults.examResultKey;

  if (selectedExamResultKey === SATLastScoreKey.NOT_TAKEN) {
    return SATResultsCongratulationsMessageType.NONE;
  }

  const selectedScoreValue = getSATLastScoreValueByKey(selectedExamResultKey);

  if (!selectedScoreValue && typeof selectedScoreValue !== 'number') {
    return SATResultsCongratulationsMessageType.NONE;
  }

  const scoreDifferenceWithTarget = selectedScoreValue - targetScore;
  const scoreDifferenceWithLastScore = selectedScoreValue - lastScore;

  if (scoreDifferenceWithTarget === 0) {
    return SATResultsCongratulationsMessageType.ACHIEVED_TARGET;
  }

  if (scoreDifferenceWithTarget > 0) {
    return SATResultsCongratulationsMessageType.ABOVE_TARGET;
  }

  if (
    scoreDifferenceWithTarget < 0 &&
    scoreDifferenceWithLastScore >= MIN_SCORE_DIFFERENCE_WITH_LAST_SCORE
  ) {
    return SATResultsCongratulationsMessageType.ABOVE_LAST_SCORE;
  }

  if (
    scoreDifferenceWithTarget < 0 &&
    scoreDifferenceWithLastScore < MIN_SCORE_DIFFERENCE_WITH_LAST_SCORE
  ) {
    return SATResultsCongratulationsMessageType.BELOW_LAST_SCORE;
  }

  return SATResultsCongratulationsMessageType.NONE;
};

export const mapSATResultsBoostedScoreValue = (state: RootState): number => {
  const selectedExamResultKey = state.SATResults.examResultKey;

  if (selectedExamResultKey === SATLastScoreKey.NOT_TAKEN) {
    return 0;
  }

  const selectedScoreValue = getSATLastScoreValueByKey(selectedExamResultKey);
  const lastScore = state.SATResults.lastSATCurrentScore ?? 0;

  if (!selectedScoreValue && typeof selectedScoreValue !== 'number') {
    return 0;
  }

  return selectedScoreValue - lastScore;
};

export const mapSATResultsInputValidationStatus = (
  state: RootState,
): boolean => {
  const selectedExamResultKey = state.SATResults.examResultKey;

  if (selectedExamResultKey === SATLastScoreKey.NOT_TAKEN) {
    return true;
  }

  const selectedScoreValue = getSATLastScoreValueByKey(selectedExamResultKey);
  if (!selectedScoreValue && typeof selectedScoreValue !== 'number') {
    return false;
  }

  const messageType = mapSATResultsCongratulationMessageType(state);

  if (
    [
      SATResultsCongratulationsMessageType.ACHIEVED_TARGET,
      SATResultsCongratulationsMessageType.ABOVE_TARGET,
    ].includes(messageType)
  ) {
    return true;
  }

  if (
    [
      SATResultsCongratulationsMessageType.ABOVE_LAST_SCORE,
      SATResultsCongratulationsMessageType.BELOW_LAST_SCORE,
    ].includes(messageType)
  ) {
    return state.SATResults.isUserHappyWithSATResults !== undefined;
  }

  return false;
};

export const checkIfSATResultsRetakeInputValid = (
  props: CheckIfSATResultsRetakeInputValidProps,
): boolean => {
  const { examTargetScore, examDate } = props;

  if (!examTargetScore || !examDate) {
    return false;
  }

  return true;
};

export const mapSATResultsNextScreenValidationStatus = (
  state: RootState,
): boolean => {
  const planToRetakeSAT = state.SATResults.planToRetakeSAT;
  const planToTakeAPNextYearMay = state.SATResults.planToTakeAPNextYearMay;

  if (planToRetakeSAT === ExamResultsYesNoEnum.NO && planToTakeAPNextYearMay) {
    return true;
  }

  const selectedRetakeSATExamDate = state.SATResults.retakeSATExamDate;
  const selectedTargetScore = state.SATResults.retakeSATTargetScore;

  if (
    checkIfSATResultsRetakeInputValid({
      examTargetScore: selectedTargetScore,
      examDate: selectedRetakeSATExamDate,
    })
  ) {
    return true;
  }

  return false;
};

export const mapSubmitSATResultsAcademicDataRequest = (
  state: RootState,
): SubmitSATResultsAcademicDataRequest => {
  const { SATResults } = state;
  const planToRetakeSAT =
    SATResults.planToRetakeSAT === ExamResultsYesNoEnum.YES;
  const planToTakeAP =
    SATResults.planToTakeAPNextYearMay === ExamResultsYesNoEnum.YES;

  const didNotTake =
    state.SATResults.examResultKey === SATLastScoreKey.NOT_TAKEN;

  const achievedScore =
    state.SATResults.examResultKey === SATLastScoreKey.NOT_TAKEN
      ? 0
      : getSATLastScoreValueByKey(state.SATResults.examResultKey);

  return {
    examDate: SATResults.lastSATExamDate ?? null,
    score: achievedScore,
    didNotTake,
    planToRetakeSAT,
    planToTakeAP: planToRetakeSAT ? !planToTakeAP : planToTakeAP,
    ...(planToRetakeSAT && {
      plannedSATExamDate: SATResults.retakeSATExamDate,
      plannedSATTargetScore: SATResults.retakeSATTargetScore,
    }),
    isSatisfied: SATResults.isUserHappyWithSATResults,
    targetScore: SATResults.lastSATTargetScore,
    lastScore: SATResults.lastSATCurrentScore,
  };
};
