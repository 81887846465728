import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react-native';
import { persistReducer } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';

import {
  getUserApProgramDetails,
  resetApSliceState,
} from '../../../AP/services/slices';
import { getAuthService } from '../../../App/services/amplify/AuthService';
import {
  resetLifeCycleState,
  upsertTimezoneToUserSettings,
} from '../../../App/services/slices';
import {
  getAppsFlyerTracker,
  getMixpanelTracker,
} from '../../../App/services/trackers';
import {
  handleNetworkActionError,
  handleNetworkActionErrorSilently,
} from '../../../App/services/utils';
import { RootState } from '../../../App/store';
import { resetApResultsSlice } from '../../../ApResults/services/slices';
import { syncAnalyticsIdsWithMixpanel } from '../../../Common/services/utils';
import { isWebPlatform } from '../../../Common/services/utils/AppConstants';
import {
  fetchCoursesEnrollmentCount,
  fetchEnrolledCourses,
  resetCourseEnrollmentsState,
  syncSelectedCourse,
} from '../../../CourseEnrollment/services/slices';
import {
  initRetrieveBookmarkedLikedContent,
  resetFeedbackState,
} from '../../../Feedback/services/slices';
import { resetLeaderboardState } from '../../../Leaderboard/services/slices';
import {
  getAllCourses,
  getCourseProgressOnSessionInit,
  resetCourseStarted,
  resetFeedState,
  resetProgress,
} from '../../../Learn/services/slices';
import {
  clearMessageData,
  setShowDmsMessages,
  setShowStartNewConversation,
} from '../../../Messages/services/slices';
import {
  getNotificationPermission,
  handleInvalidateInitialNotification,
  unsubscribeFromPushNotifications,
} from '../../../Notification/services/slices/NotificationSlice';
import {
  proceedOnboardingDataOnLogin,
  resetApOnboardingState,
  resetOnboardingState,
  setShowNotificationRequestScreen,
} from '../../../Onboarding/services/slices';
import {
  resetBootcampPaywallSlice,
  resetHighSchoolCoursePurchaseData,
  unsubscribeForPurchasesUpdate,
} from '../../../Paywall/services/slices';
import { AuthUser } from '../../../Profile/entities';
import {
  getAndAssignUserFlags,
  getUserStats,
  resetProfileState,
} from '../../../Profile/services/slices';
import { clearRaiseHandMessageData } from '../../../RaiseHand/services/slices';
import {
  getUserSATProgramDetails,
  resetSATSlice,
  unenrollSATCourse,
} from '../../../SAT/services/slices';
import { resetSATResultsSliceState } from '../../../SATResults/services/slices';
import { clearTestPrepStateOnLogout } from '../../../TestPrep/services/slices';
import { resetTutorialState } from '../../../Tutorial/services/slices';
import {
  getUserAccess,
  resetUserAccessSliceState,
} from '../../../UserAccess/services/slices';
import { TrackedUserEventType } from '../../../UserEvent/entities';
import { trackUserEvent } from '../../../UserEvent/services/slices';
import {
  InvalidateAllUserRelatedDataParams,
  LogoutParams,
} from '../../entities';

import { signInExtraReducers } from './AuthSliceActions';
import {
  deleteUserProfileExtraReducers,
  getUserProfileExtraReducers,
  updateUsernameExtraReducers,
  updateUserProfileExtraReducers,
} from './UserProfileActions';
import { updateUserIdentifier } from './UserProfileActions/UpdateUserIdentifierAction';

export const AUTH_SLICE_NAME = 'AuthSlice';

export type AuthSliceState = {
  // For any loading state in this slice. Used in buttons and another scope.
  isLoading: boolean;

  // Only specifically for signIn actions, because we want to use this in navigation handling.
  signInLoading: boolean;

  authUser?: AuthUser;
  hasBeenLoggedInBefore: boolean;

  // super safe-belt, there's an edge-case where a user quickly logs out and leaves the app,
  // the session may still exist when the user comes back to the app (our logouts have delay)
  isSignedOut: boolean;
};

const persistConfig = {
  key: AUTH_SLICE_NAME,
  storage: AsyncStorage,
  whitelist: ['hasBeenLoggedInBefore', 'authUser', 'isSignedOut'],
} as PersistConfig<AuthSliceState>;

export const AuthSliceInitialState: AuthSliceState = {
  signInLoading: false,
  isLoading: false,
  authUser: undefined,
  hasBeenLoggedInBefore: false,
  isSignedOut: true,
};

export const handleSyncUserDataAfterSignIn = createAsyncThunk(
  `${AUTH_SLICE_NAME}/handleSyncUserDataAfterSignIn`,
  async (_, thunkApi) => {
    try {
      if (isWebPlatform) {
        return;
      }

      await thunkApi.dispatch(invalidateAllUserRelatedData()).unwrap();
      const getAllCourseActionPromise = thunkApi.dispatch(getAllCourses());

      thunkApi.dispatch(fetchCoursesEnrollmentCount());
      thunkApi.dispatch(updateUserIdentifier({ updateAlias: true }));
      thunkApi.dispatch(initRetrieveBookmarkedLikedContent());

      const blockingPromises: Promise<unknown>[] = [
        thunkApi.dispatch(fetchEnrolledCourses()).unwrap(),
        thunkApi.dispatch(proceedOnboardingDataOnLogin()).unwrap(),
        thunkApi.dispatch(getAndAssignUserFlags()).unwrap(),
        thunkApi.dispatch(getUserStats({ enforce: true })).unwrap(),
        thunkApi.dispatch(getUserAccess()).unwrap(),
      ];

      const state = thunkApi.getState() as RootState;
      if (Object.values(state.courses.coursesMap).length === 0) {
        blockingPromises.push(getAllCourseActionPromise.unwrap());
      }

      await Promise.all(blockingPromises);

      // Need this to verify if the user is enrolled in bootcamp,
      // cannot be done in parallel with "proceedOnboardingDataOnLogin"
      await Promise.all([
        await thunkApi.dispatch(getUserSATProgramDetails()).unwrap(),
        await thunkApi.dispatch(getUserApProgramDetails()).unwrap(),
      ]);

      thunkApi.dispatch(syncSelectedCourse({ overrideStudyListState: true }));

      thunkApi.dispatch(
        trackUserEvent({
          eventType: TrackedUserEventType.SessionInitiated,
        }),
      );

      thunkApi.dispatch(resetProgress());
      thunkApi.dispatch(setShowDmsMessages(false));
      thunkApi.dispatch(getCourseProgressOnSessionInit());
      thunkApi.dispatch(upsertTimezoneToUserSettings());
      thunkApi.dispatch(unenrollSATCourse());

      const isNotificationEnabled = await thunkApi
        .dispatch(getNotificationPermission())
        .unwrap();
      if (!isNotificationEnabled) {
        thunkApi.dispatch(setShowNotificationRequestScreen(true));
      }
    } catch (e: unknown) {
      console.log(e);
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

export const invalidateAllUserRelatedData = createAsyncThunk(
  `${AUTH_SLICE_NAME}/invalidateAllUserRelatedData`,
  async (params: InvalidateAllUserRelatedDataParams, thunkApi) => {
    try {
      thunkApi.dispatch(resetProgress());
      thunkApi.dispatch(resetCourseStarted());
      thunkApi.dispatch(resetCourseEnrollmentsState());
      thunkApi.dispatch(setShowDmsMessages(false));
      thunkApi.dispatch(clearMessageData());
      thunkApi.dispatch(clearRaiseHandMessageData());
      thunkApi.dispatch(resetTutorialState());
      thunkApi.dispatch(setShowStartNewConversation);
      thunkApi.dispatch(clearTestPrepStateOnLogout());
      thunkApi.dispatch(resetFeedbackState());
      thunkApi.dispatch(resetLifeCycleState());
      thunkApi.dispatch(resetFeedState());
      thunkApi.dispatch(resetSATSlice());
      thunkApi.dispatch(resetApResultsSlice());
      thunkApi.dispatch(resetProfileState());
      thunkApi.dispatch(resetUserAccessSliceState());
      thunkApi.dispatch(resetApSliceState());
      thunkApi.dispatch(resetHighSchoolCoursePurchaseData());
      thunkApi.dispatch(resetBootcampPaywallSlice());
      thunkApi.dispatch(resetLeaderboardState());
      thunkApi.dispatch(resetSATResultsSliceState());

      if (params?.isLogout && !params.keepPreSignedInData) {
        thunkApi.dispatch(resetOnboardingState());
        thunkApi.dispatch(resetApOnboardingState());
        thunkApi.dispatch(unsubscribeForPurchasesUpdate());
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

export const logout = createAsyncThunk(
  `${AUTH_SLICE_NAME}/logout`,
  async (params: LogoutParams, thunkApi) => {
    try {
      thunkApi.dispatch(
        invalidateAllUserRelatedData({
          isLogout: true,
          keepPreSignedInData: params?.keepPreSignedInData,
        }),
      );
      thunkApi.dispatch(handleInvalidateInitialNotification());

      // make sure sign out won't break if endpoint breaks
      await thunkApi
        .dispatch(unsubscribeFromPushNotifications())
        .unwrap()
        .catch(() => undefined);

      await getAuthService().signOut();

      if (!isWebPlatform) {
        // Clear user information when the user logs out
        Sentry.configureScope(scope => scope.setUser(null));
        getMixpanelTracker().reset();
        syncAnalyticsIdsWithMixpanel().then(() => getAppsFlyerTracker().stop());
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

const slice = createSlice({
  name: AUTH_SLICE_NAME,
  initialState: AuthSliceInitialState,
  reducers: {},
  extraReducers: builder => {
    signInExtraReducers(builder);
    getUserProfileExtraReducers(builder);
    updateUserProfileExtraReducers(builder);
    deleteUserProfileExtraReducers(builder);
    updateUsernameExtraReducers(builder);

    builder.addCase(logout.pending, state => {
      return {
        ...AuthSliceInitialState,
        authUser: undefined,
        isSignedOut: true,
        hasBeenLoggedInBefore: state.hasBeenLoggedInBefore,
      };
    });
  },
});

export const AuthSlice = persistReducer(persistConfig, slice.reducer);
