import { createAsyncThunk } from '@reduxjs/toolkit';

import { checkIfShouldSkipActionWithCoolDown } from '../../../../Common/services/utils';
import { upsertUserSettingsGraphQLCall } from '../../../../Profile/graphql';
import { RootState } from '../../../store';
import { handleNetworkActionErrorSilently } from '../../utils';
import {
  LIFECYCLE_SLICE_NAME,
  setUserSettingsCoolDown,
} from '../LifecycleSlice';

export const upsertTimezoneToUserSettings = createAsyncThunk(
  `${LIFECYCLE_SLICE_NAME}/upsertTimezoneToUserSettings`,
  async (_, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const userSettingsCoolDown = rootState.lifecycle.userSettingsCoolDown;

      const shouldSkip = checkIfShouldSkipActionWithCoolDown(
        30 * 60 * 1000,
        userSettingsCoolDown,
      );
      if (shouldSkip) {
        return;
      }

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      await upsertUserSettingsGraphQLCall({
        timezone,
      });
      setUserSettingsCoolDown(new Date().toISOString());
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);
