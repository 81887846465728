import moment from 'moment';

import { RootState } from '../../../App/store';
import { CommonPeriodType, Unit } from '../../../Common/entities';
import { UpdateUserPropertiesPayload } from '../../../Common/services/utils';
import {
  SATBootcamp,
  SATBootcampAnalyticsWithTimeTrack,
  SATHistoryData,
  SATHistoryDataSummary,
  SATSummaryAnalytics,
  SATUnitTestHistoryUnit,
} from '../../entities';

export const mapSATBootcampForAnalytics = (
  bootcamp?: SATBootcamp,
): SATBootcampAnalyticsWithTimeTrack => {
  if (!bootcamp?.details) {
    return {
      examDate: '',
      bootcampName: '',
      bootcampPeriod: '',
      bootcampPeriodCount: 0,
      bootcampDuration: '',
      currentScore: 0,
      targetScore: 0,
      timeCommitment: '',
      studyCommitmentHours: 0,
      currentBootcampPeriod: 0,
      studyHoursSpent: 0,
      SATTotalBaselineScore: 0,
      SATMathBaselineScore: 0,
      SATReadingWritingBaselineScore: 0,
    };
  }

  const durationWord =
    bootcamp.details.durationPeriod === CommonPeriodType.WEEK ? 'Week' : 'Day';
  const bootcampName = `${bootcamp.details.durationLength}-${durationWord} ${bootcamp.details.programName}`;

  const enrolledAtDate = moment(bootcamp.details.enrolledAt).format(
    'YYYY-MM-DD',
  );
  const managedExamDate = moment(bootcamp.details.examDate, 'YYYY-MM-DD')
    .subtract(1, 'days')
    .format('YYYY-MM-DD');

  const bootcampDuration = `${enrolledAtDate} - ${managedExamDate}`;

  const timeCommitmentWord =
    bootcamp.details.targetTimeCommitmentPeriod === CommonPeriodType.WEEK
      ? 'Week'
      : 'Day';
  const timeCommitment = `${bootcamp.details.targetTimeCommitmentHours} Hours per ${timeCommitmentWord}`;

  return {
    bootcampName,
    bootcampDuration,
    targetScore: bootcamp.details.targetScore,
    currentScore: bootcamp.details.currentScore,
    examDate: bootcamp.details.examDate,
    timeCommitment,
    bootcampPeriod: bootcamp.details.durationPeriod,
    bootcampPeriodCount: bootcamp.details.durationLength,
    studyCommitmentHours: bootcamp.details.targetTimeCommitmentHours,
    currentBootcampPeriod: bootcamp.details.currentDuration,
    studyHoursSpent:
      Math.floor((bootcamp.details.timeSpentMinutes / 60) * 10) / 10,
    SATTotalBaselineScore: bootcamp.details.currentScore,
    SATMathBaselineScore: bootcamp.details.mathScore,
    SATReadingWritingBaselineScore: bootcamp.details.readingWritingScore,
  };
};

export const mapSATSummaryForUserProperties = (
  satScore: SATHistoryDataSummary | undefined,
): UpdateUserPropertiesPayload => {
  return {
    SATMathScore: satScore?.showMath ? satScore?.mathScore : undefined,
    SATReadingAndWritingScore: satScore?.showReadingWriting
      ? satScore?.readingWritingScore
      : undefined,
    SATTotalScore: satScore?.showTotal ? satScore?.totalScore : undefined,
  };
};

export const mapSATBaselineSummaryForAnalytics = (
  state: RootState,
): SATSummaryAnalytics => {
  const bootcampDetails = state.SAT.bootcamp?.details;
  return {
    SATMathBaselineScore: bootcampDetails?.mathScore,
    SATReadingWritingBaselineScore: bootcampDetails?.readingWritingScore,
    SATTotalBaselineScore: bootcampDetails?.currentScore,
  };
};

export const mapSATUnitTestScoreForAnalytics = (
  unit: Unit,
  state: RootState,
): number | undefined => {
  const satHistory = state.testPrep.SATHistory;
  if (!satHistory) {
    return undefined;
  }
  const historyUnit = satHistory[unit.id];
  return historyUnit?.baselineScore ?? undefined;
};
