import LogRocket from '@logrocket/react-native';
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react-native';
import { Platform, StatusBar } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { requestTrackingPermission } from 'react-native-tracking-transparency';

import {
  getAppVersion,
  isAppVersionExpired,
} from '../../../../AppVersion/services/slices';
import {
  getAppConfigsFromS3,
  initCustomEnvOnAppLoad,
  setTrackingPermission,
} from '../../../../Common/services/slices';
import {
  environment,
  isProductionEnv,
  logger,
} from '../../../../Common/services/utils';
import { isWebPlatform } from '../../../../Common/services/utils/AppConstants';
import {
  getAllCourses,
  getCourseProgressOnSessionInit,
  getPaywallCharacters,
  setContentMuted,
} from '../../../../Learn/services/slices';
import {
  clearScheduledLocalNotification,
  initNotificationListener,
} from '../../../../Notification/services/slices/NotificationSlice';
import { subscribeForPurchasesUpdate } from '../../../../Paywall/services/slices';
import { AppLogRocketSlug } from '../../../entities';
import locale from '../../../locale';
import { RootState } from '../../../store';
import { getConfigService } from '../../amplify/ConfigService';
import { initSentry } from '../../trackers';
import { handleNetworkActionErrorSilently } from '../../utils';
import { setIsAppActive } from '../AppSlice';
import { initAppsFlyer } from '../AppSliceActions';
import { LIFECYCLE_SLICE_NAME, LifecycleSliceState } from '../LifecycleSlice';

import { getAppConfigs } from './GetAppConfigsAction';
import { syncUserDataOnAppLoad } from './SyncUserDataOnAppLoadAction';

export const loadApp = createAsyncThunk(
  `${LIFECYCLE_SLICE_NAME}/loadApp`,
  async (_, thunkApi) => {
    thunkApi.dispatch(setIsAppActive(true));
    await logger.clear();

    let state = thunkApi.getState() as RootState;
    const settings = state.settings;

    await environment.load();
    initSentry();
    getConfigService().setDefaultEnvironment();

    await thunkApi.dispatch(initAppsFlyer()).unwrap();

    if (!isWebPlatform && isProductionEnv()) {
      LogRocket.init(AppLogRocketSlug.DEFAULT);
    }

    // Super-special case for this action, please always prioritize the following at the top:
    // 1. Switching environment - we want to make sure all the data is properly loaded from the selected env.
    // 2. App version checking - we want to make sure the app version is up-to-date before we do any further action.
    const shouldCheckCustomEnvForDeveloperMode =
      isProductionEnv() &&
      settings.developerMode &&
      settings.useCustomEnvironment;

    // This is where: Switching environment
    if (!isProductionEnv() || shouldCheckCustomEnvForDeveloperMode) {
      await thunkApi.dispatch(initCustomEnvOnAppLoad()).unwrap();
    }

    // This is where: App version checking
    try {
      if (!isWebPlatform) {
        await thunkApi
          .dispatch(getAppVersion())
          .unwrap()
          .catch(e => e);

        state = thunkApi.getState() as RootState;

        thunkApi.dispatch(subscribeForPurchasesUpdate());

        thunkApi.dispatch(getPaywallCharacters());
      }

      StatusBar.setBarStyle('light-content', true);

      // if app version expired, do not proceed at all
      // this is to prevent any further action that might cause the app to crash

      // other than this, we can proceed with gathering the rest of the data
      // because we still expect the app to be fully functional
      if (isAppVersionExpired(state.appVersion)) {
        return;
      }

      await thunkApi.dispatch(getAppConfigsFromS3()).unwrap();

      thunkApi.dispatch(clearScheduledLocalNotification());
      thunkApi.dispatch(initNotificationListener());

      if (
        state.settings.trackingPermission === undefined &&
        Platform.OS === 'ios'
      ) {
        requestTrackingPermission()
          .then(result => {
            thunkApi.dispatch(setTrackingPermission(result));
          })
          .catch(e => {
            handleNetworkActionErrorSilently(e);
          });
      }

      thunkApi.dispatch(setContentMuted(false));
      thunkApi.dispatch(getAppConfigs());

      const blockingPromises: Promise<any>[] = [
        thunkApi.dispatch(syncUserDataOnAppLoad()).unwrap(),
      ];

      if (Object.values(state.courses.coursesMap).length === 0) {
        blockingPromises.push(thunkApi.dispatch(getAllCourses()).unwrap());
      } else {
        thunkApi.dispatch(getAllCourses());
      }

      await Promise.all(blockingPromises);

      state = thunkApi.getState() as RootState;
      if (!state.auth.isSignedOut) {
        thunkApi.dispatch(getCourseProgressOnSessionInit());
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);

export const loadAppExtraReducers = (
  builder: ActionReducerMapBuilder<LifecycleSliceState>,
): void => {
  builder.addCase(loadApp.pending, state => {
    state.appLoading = true;
    state.isAppLoaded = false;
  });
  builder.addCase(loadApp.fulfilled, state => {
    state.appLoading = false;
    state.isAppLoaded = true;
  });
  builder.addCase(loadApp.rejected, (state, action) => {
    Sentry.captureException(action.payload);
    showMessage({
      message: locale.errors.something_went_wrong,
      type: 'danger',
    });
    state.appLoading = false;
    state.isAppLoaded = true;
  });
};
