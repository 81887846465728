import { createAsyncThunk } from '@reduxjs/toolkit';

import { logger } from '../../../../Common/services/utils';
import { submitQueuedAnswers } from '../../../../TestPrep/services/slices';
import { NETWORK_SLICE_NAME, NetworkSliceState } from '../NetworkSlice';

export const handleReconnection = createAsyncThunk(
  `${NETWORK_SLICE_NAME}/handleReconnection`,
  async (_, thunkApi) => {
    logger.debug('Handling Reconnection');
    const state = thunkApi.getState() as { network: NetworkSliceState };
    if (state.network.isOnline) {
      thunkApi.dispatch(submitQueuedAnswers());
    }
  },
);
