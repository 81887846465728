import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';

import locale from '../../../App/locale';
import { useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import { CommonActionButton } from '../../../Common/components';
import { Colors, Sizes } from '../../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../../Common/services/utils/polyfills';
import { BootcampEntryPoint } from '../../../Onboarding/entities';
import { syncSATBootcampAndNavigateToPaywall } from '../../services/slices';

import { SATProgramOverlayDetails } from './SATProgramOverlayDetails';
import { SATProgramOverlayHeader } from './SATProgramOverlayHeader';

const CONTAINER_HEIGHT = Sizes.getAppWindowHeight() * 0.6;

export const SATProgramOverlay = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(state => state.SAT.isLoading);

  const handleUnlockMorePoints = useCallback(() => {
    dispatch(
      syncSATBootcampAndNavigateToPaywall({
        bootcampEntryPoint: BootcampEntryPoint.SAT_FREE_ACCESS_ENDED,
      }),
    );
  }, [dispatch]);

  return (
    <LinearGradient
      style={styles.container}
      colors={Colors.topAlphaGradientStrongInvert}
      start={{ x: 0, y: 0 }}
      end={{ x: 0, y: 0.6 }}>
      <SATProgramOverlayHeader />

      <View style={styles.textContainer}>
        <SATProgramOverlayDetails />
      </View>

      <View style={styles.actionContainer}>
        <CommonActionButton
          isLoading={isLoading}
          onPress={handleUnlockMorePoints}
          text={locale.sat.unlock_more_points}
        />
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: CONTAINER_HEIGHT,
    width: '100%',
    position: 'absolute',
    padding: Sizes.medium,
    justifyContent: 'flex-end',
    bottom: 0,
    left: 0,
    right: 0,
  },

  textContainer: {
    marginTop: Sizes.xsmall,
  },

  actionContainer: {
    marginTop: Sizes.medium,
  },
});
