import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  finalizeApLearnModeCoursesTimeSpent,
  getUserApProgramDetails,
} from '../../../../AP/services/slices';
import { getUserProfile, logout } from '../../../../Auth/services/slices';
import { updateUserIdentifier } from '../../../../Auth/services/slices/UserProfileActions/UpdateUserIdentifierAction';
import {
  fetchCoursesEnrollmentCount,
  fetchEnrolledCourses,
  syncSelectedCourse,
} from '../../../../CourseEnrollment/services/slices';
import { initRetrieveBookmarkedLikedContent } from '../../../../Feedback/services/slices';
import { getNotificationPermission } from '../../../../Notification/services/slices/NotificationSlice';
import { BootcampEntryPoint } from '../../../../Onboarding/entities';
import { checkIfUserHasEntitlement } from '../../../../Onboarding/services/slices';
import {
  getAndAssignUserFlags,
  getUserStats,
} from '../../../../Profile/services/slices';
import {
  getUserSATProgramDetails,
  unenrollSATCourse,
} from '../../../../SAT/services/slices';
import { getUserAccess } from '../../../../UserAccess/services/slices';
import { TrackedUserEventType } from '../../../../UserEvent/entities';
import { trackUserEvent } from '../../../../UserEvent/services/slices';
import { RootState } from '../../../store';
import { getAuthService } from '../../amplify/AuthService';
import { handleNetworkActionErrorSilently } from '../../utils';
import { LIFECYCLE_SLICE_NAME } from '../LifecycleSlice';

import { getTeachTapUserSettings } from './GetTeachTapUserSettingsAction';
import { upsertTimezoneToUserSettings } from './UpsertTimezoneToUserSettingsAction';

export const syncUserDataOnAppLoad = createAsyncThunk(
  `${LIFECYCLE_SLICE_NAME}/syncUserData`,
  async (_, thunkApi) => {
    try {
      const isAuthValid = await getAuthService().isAuthenticated();

      const state = thunkApi.getState() as RootState;
      const isSignedOut = state.auth.isSignedOut;

      if (!isAuthValid || isSignedOut) {
        // if the user is not logged in, invalidate any user data
        await thunkApi.dispatch(logout()).unwrap();
        return;
      }

      if (isAuthValid) {
        if (!state.auth.authUser?.id) {
          await thunkApi
            .dispatch(
              getUserProfile({
                silent: true,
              }),
            )
            .unwrap()
            .catch((e: any) => {
              // extra-checking: if the user data is not loaded, logout the user
              thunkApi.dispatch(logout());
              throw e;
            });
        }

        thunkApi.dispatch(
          trackUserEvent({
            eventType: TrackedUserEventType.SessionInitiated,
          }),
        );

        await Promise.all([
          thunkApi.dispatch(getTeachTapUserSettings()).unwrap(),
          thunkApi.dispatch(fetchEnrolledCourses()).unwrap(),
          thunkApi.dispatch(fetchCoursesEnrollmentCount()).unwrap(),
          thunkApi
            .dispatch(updateUserIdentifier({ updateAlias: true }))
            .unwrap(),
          thunkApi.dispatch(getUserSATProgramDetails()).unwrap(),
          thunkApi.dispatch(getUserApProgramDetails()).unwrap(),
          thunkApi.dispatch(getAndAssignUserFlags()).unwrap(),
          thunkApi.dispatch(getUserStats()).unwrap(),
          thunkApi.dispatch(getUserAccess()).unwrap(),
        ]);

        thunkApi.dispatch(getNotificationPermission());
        thunkApi.dispatch(initRetrieveBookmarkedLikedContent());
        thunkApi.dispatch(syncSelectedCourse({ overrideStudyListState: true }));
        thunkApi.dispatch(upsertTimezoneToUserSettings());
        thunkApi.dispatch(unenrollSATCourse());

        thunkApi.dispatch(
          checkIfUserHasEntitlement(BootcampEntryPoint.UNCLAIMED_ENTITLEMENT),
        );
        thunkApi.dispatch(finalizeApLearnModeCoursesTimeSpent());
      }
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
      }
    }
  },
);
