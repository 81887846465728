import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import { GetLeaderboardResponse } from '../../entities';

export const getLeaderboardGraphQLCall =
  async (): Promise<GetLeaderboardResponse> => {
    const authHeader = (await getAuthHeader()) as any;
    if (Object.keys(authHeader).length > 0) {
      authHeader['x-api-key'] = undefined;
    }

    const response = await graphqlApi.post(
      '',
      {
        query: `
      query TeachTapGetLeaderboard {
        teachTapGetLeaderboard {
          leaderboard {
            topUsers {
              id
              username
              points
              rank
              profileImageUrl
            }
            userRank {
              id
              username
              points
              rank
              profileImageUrl
            }
            adjacentUsers {
              id
              username
              points
              rank
              profileImageUrl
            }
            totalUsers
          }
        }
      }`,
      },
      {
        headers: {
          ...authHeader,
        },
      },
    );

    handleGraphQLError(response);
    return response.data.data?.teachTapGetLeaderboard;
  };
