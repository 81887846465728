import React, { useCallback, useEffect, useMemo } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { validate, version } from 'uuid';

import locale from '../../../../App/locale';
import { useAppSelector } from '../../../../App/services/hooks';
import { useAppDispatch } from '../../../../App/store';
import { ContentCard } from '../../../../Common/entities';
import { useSafeAreaCustomInsets } from '../../../../Common/services/hooks';
import { getLoadingCard } from '../../../../Common/services/utils';
import { getAICard, setCardAttributes } from '../../../services/slices';

import FeedCardView from './FeedCardView';
import { FeedPageProps } from './FeedPage';

const RETRY_NUMBER = 3;

export default (props: FeedPageProps): React.ReactElement => {
  const dispatch = useAppDispatch();

  const questions = useAppSelector(
    state => state.questions.feeds[props.tabTag],
  );

  const isLoading = useAppSelector(
    state => state.questions.feedsLoading[props.tabTag],
  );

  const isError = useAppSelector(
    state => state.questions.feedsError[props.tabTag],
  );

  const coursesMap = useAppSelector(state => state.courses.coursesMap);

  const topics = useMemo(() => {
    return Object.values(coursesMap).flatMap(course => {
      return course.units.flatMap(unit => unit.topics);
    });
  }, [coursesMap]);

  const prepareWebCard = useCallback(
    (card?: ContentCard) => {
      if (!card) {
        return;
      }
      dispatch(
        setCardAttributes({
          card,
          attributes: { visibilityStyle: 'eye', tapToRevealShown: false },
        }),
      );
    },
    [dispatch],
  );

  const loadQuestion = useCallback(async (): Promise<void> => {
    const id = window.location.href.split('/cid/')[1]?.split('/')[0];
    const isUuidV4 = validate(id) && version(id) === 4;
    if (!isUuidV4) {
      return;
    }

    if (id) {
      let card: ContentCard | undefined;

      for (let i = 0; i < RETRY_NUMBER; i++) {
        try {
          card = await dispatch(
            getAICard({
              feedId: props.tabTag,
              itemId: id,
            }),
          ).unwrap();
          break;
        } catch (e) {}
      }
      prepareWebCard(card);
    }
  }, [dispatch, prepareWebCard, props.tabTag]);

  useEffect(() => {
    loadQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible]);

  const feedItems = [...questions, getLoadingCard(props.tabTag)];

  const { topHeaderSafeAreaHeight } = useSafeAreaCustomInsets();

  const containerTopStyle = {
    marginTop: topHeaderSafeAreaHeight + 80,
  };

  if (isLoading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" style={containerTopStyle} />
      </View>
    );
  } else if (isError) {
    return (
      <View style={styles.container}>
        <TouchableOpacity
          style={[styles.reloadButton, containerTopStyle]}
          onPress={loadQuestion}>
          <Text style={styles.reloadButtonText}>
            {locale.home_screen_card.reload}
          </Text>
        </TouchableOpacity>
      </View>
    );
  } else if (questions.length === 0 || props.isSearchVisible) {
    return <View />;
  } else {
    return (
      <View style={styles.container}>
        <FeedCardView
          index={0}
          holderTag={props.tabTag}
          item={feedItems[0]}
          itemHeight={props.itemHeight}
        />
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  listContainer: {},
  reloadButton: {
    alignSelf: 'center',
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 10,
  },
  reloadButtonText: {
    color: 'black',
    fontWeight: 'bold',
  },
});
