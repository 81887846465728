import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import locale from '../../../../App/locale';
import { RootNavigatorRef } from '../../../../App/navigation/RootNavigator';
import { handleNetworkActionError } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import {
  mapDataToUnitTestCompletedPayload,
  mapSATScoresChangedAnalyticsPayload,
} from '../../../../Common/services/mappers';
import {
  isSATCourse,
  trackAnalyticsEvent,
} from '../../../../Common/services/utils';
import {
  Analytics,
  MainStack,
  ScreenNames,
  TabNames,
  TestStack,
} from '../../../../Common/services/utils/AppConstants';
import { incrementAwardedPoints } from '../../../../Profile/services/slices';
import { mapSATUnitTestScoreForAnalytics } from '../../../../SAT/services/mappers';
import { TestPrepProgressAlgorithm } from '../../../algorithms';
import { submitUnitTestGraphQLCall } from '../../../graphql';
import {
  checkAndAssignCurrentTestBestScore,
  getSATTestPrepHistory,
  getTestPrepHistory,
  getTestScorePercentile,
  submitQueuedAnswers,
  TEST_PREP_SLICE_NAME,
  TestPrepSliceState,
} from '../TestPrepSlice';

export const submitTestResults = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/submitTestResults`,
  async ({ timesUp }: { timesUp: boolean }, thunkApi) => {
    let state = thunkApi.getState() as RootState;
    const localState = state.testPrep;
    if (!localState.currentTest) {
      return thunkApi.rejectWithValue(locale.errors.unknown_error);
    }

    if (isSATCourse(localState.currentTest.course)) {
      const score =
        TestPrepProgressAlgorithm.getSATTestScore(localState.currentTest) ?? 0;

      await Promise.all([
        thunkApi
          .dispatch(
            getTestScorePercentile({
              unit: localState.currentTest.unit,
              score: score,
              maxScore: localState.currentTest.maxScore ?? 1,
            }),
          )
          .unwrap(),
        thunkApi.dispatch(checkAndAssignCurrentTestBestScore()).unwrap(),
      ]);
    }

    try {
      if (!(await thunkApi.dispatch(submitQueuedAnswers()).unwrap())) {
        // If we fail to submit the queued answers, we will ask the user to try again
        return thunkApi.rejectWithValue(locale.errors.network_error);
      }
      const response = await submitUnitTestGraphQLCall({
        courseId: localState.currentTest.course.id,
        unitId: localState.currentTest.unit.id,
        testId: localState.currentTest.id,
        percentageScore:
          TestPrepProgressAlgorithm.getTestCorrectAnswersPercentage(
            localState.currentTest,
          ) * 100,
        pointsAwarded: TestPrepProgressAlgorithm.getTestPointsAwarded(
          localState.currentTest,
        ),
        rawScore: TestPrepProgressAlgorithm.getTestRawScore(
          localState.currentTest,
        ),
      });

      const baselineScore = mapSATUnitTestScoreForAnalytics(
        localState.currentTest.unit,
        state,
      );

      trackAnalyticsEvent(
        Analytics.unitTestCompleted,
        mapDataToUnitTestCompletedPayload(
          localState.currentTest.course,
          localState.currentTest.unit,
          response,
          localState.currentTest.timeRemaining,
          isSATCourse(localState.currentTest.course)
            ? TestPrepProgressAlgorithm.getSATTestScore(localState.currentTest)
            : undefined,
          localState.currentTest.testNumber,
          isSATCourse(localState.currentTest.course)
            ? baselineScore
            : undefined,
        ),
      );

      const course = localState.currentTest.course;
      if (isSATCourse(course)) {
        await thunkApi
          .dispatch(
            getSATTestPrepHistory({
              course,
              enforce: true,
            }),
          )
          .unwrap();

        state = thunkApi.getState() as RootState;
        trackAnalyticsEvent(
          Analytics.SATScoresChanged,
          mapSATScoresChangedAnalyticsPayload(state, course),
        );
      } else {
        await thunkApi
          .dispatch(
            getTestPrepHistory({
              course,
              enforce: true,
            }),
          )
          .unwrap();
      }

      thunkApi.dispatch(incrementAwardedPoints(response.pointsAwarded));

      if (RootNavigatorRef.isReady()) {
        RootNavigatorRef.reset({
          index: 0,
          routes: [
            {
              name: ScreenNames.MainStack.BOTTOM_TABS,
              params: {
                screen: TabNames.TEST,
                params: {
                  screen: TestStack.TEST_PREP_LIST,
                },
              },
            },
            {
              name: MainStack.TEST_COMPLETION_SCREEN,
            },
          ],
        });
      }

      return response;
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(locale.errors.network_error);
      }
    }
  },
);

export const submitTestResultsExtraReducers = (
  builder: ActionReducerMapBuilder<TestPrepSliceState>,
): void => {
  builder.addCase(submitTestResults.pending, (state, action) => {
    state.isLoading = true;
    if (state.currentTest) {
      state.currentTest.endedDueToTimeout = action.meta.arg.timesUp;
    }
  });

  builder.addCase(submitTestResults.rejected, state => {
    state.isLoading = false;
  });

  builder.addCase(submitTestResults.fulfilled, (state, action) => {
    state.isLoading = false;

    if (state.currentTest && action.payload) {
      state.currentTest!.grade = action.payload!.grade;
      state.currentTest!.pointsAwarded = action.payload!.pointsAwarded;
    }
  });
};
