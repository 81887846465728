import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { CommonApExamsConfigs } from '../../../Common/entities';
import { SatBootcampPersistedScreen } from '../../entities';
import { mapNextLastApExamDate } from '../mappers';

import {
  skipAndTrackSatBootcampPaywallExtraReducers,
  toggleSATBootcampAPAddonTrackActionExtraReducers,
} from './BootcampPaywallSliceActions';

export const BOOTCAMP_PAYWALL_SLICE_NAME = 'BootcampPaywallSlice';

export type BootcampPaywallSliceState = {
  isLoading: boolean;
  nextLastApExamDate?: string;
  isAPAddOnSelected: boolean;

  satBootcampPersistedScreen?: SatBootcampPersistedScreen;
};

const persistConfig = {
  key: BOOTCAMP_PAYWALL_SLICE_NAME,
  storage: AsyncStorage,
  whitelist: ['satBootcampPersistedScreen'],
} as PersistConfig<BootcampPaywallSliceState>;

const initialState: BootcampPaywallSliceState = {
  isLoading: false,
  nextLastApExamDate: undefined,
  isAPAddOnSelected: false,

  satBootcampPersistedScreen: undefined,
};

const slice = createSlice({
  name: BOOTCAMP_PAYWALL_SLICE_NAME,
  initialState: initialState,
  reducers: {
    resetBootcampPaywallSlice: state => {
      state.isAPAddOnSelected = false;
      state.isLoading = false;
      state.satBootcampPersistedScreen = undefined;
    },
    setBootcampPaywallLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setNextLastApExamDate: (
      state,
      action: PayloadAction<CommonApExamsConfigs | undefined>,
    ) => {
      state.nextLastApExamDate = mapNextLastApExamDate(action.payload);
    },
    setSatBootcampPersistedScreen: (
      state,
      action: PayloadAction<SatBootcampPersistedScreen | undefined>,
    ) => {
      state.satBootcampPersistedScreen = action.payload;
    },
  },
  extraReducers: builder => {
    toggleSATBootcampAPAddonTrackActionExtraReducers(builder);
    skipAndTrackSatBootcampPaywallExtraReducers(builder);
  },
});

export const {
  resetBootcampPaywallSlice,
  setBootcampPaywallLoading,
  setNextLastApExamDate,
  setSatBootcampPersistedScreen,
} = slice.actions;

export const BootcampPaywallSlice = persistReducer(
  persistConfig,
  slice.reducer,
);
