import { Dimensions, Platform, TextStyle } from 'react-native';

import { normalize, StaticSafeAreaInsets } from './polyfills';

export const isAndroidPlatform = Platform.OS === 'android';
export const isWebPlatform = Platform.OS === 'web';
export const isIOSPlatform = Platform.OS === 'ios';

export enum MainStack {
  BOTTOM_TABS = 'BottomTabs',
  PROFILE_MAIN = 'ProfileMain',
  LOGIN_STACK = 'LoginStack',
  SPLASH_SCREEN = 'SplashScreen',
  TUTORIAL_SCREEN = 'TutorialScreen',
  EDIT_FIELD = 'EditField',
  ACCOUNT_DELETE_SCREEN = 'AccountDeleteScreen',
  EDIT_DETAILS_SCREEN = 'EditDetailsScreen',
  CONFIRM_ACCOUNT_DELETE_SCREEN = 'ConfirmAccountDeleteScreen',
  COURSE_MAIN = 'CourseMain',
  PASSWORD_RESET_CODE_SCREEN = 'PasswordResetCodeScreen',
  RESET_PASSWORD_SCREEN = 'ResetPasswordScreen',
  BETA_FEEDBACK = 'BetaFeedback',
  HOME_MAIN = 'HomeMain',
  COURSE_LIST_MAIN_SCREEN = 'COURSE_LIST_MAIN_SCREEN',
  COURSE_LIST_PROFILE_SCREEN = 'COURSES_LIST_PROFILE_SCREEN',
  UPDATE_APP_SCREEN = 'UpdateAppScreen',
  SET_CUSTOM_ENV_SCREEN = 'SetCustomEnvScreen',
  MESSAGES_MAIN = 'MessagesMain',
  MESSAGE_MAIN = 'MessageMain',
  AI_FIGURE_PROFILE = 'AiFigureProfile',
  BOOKMARKS_FEED_MAIN = 'BookmarksFeedMain',
  ONBOARDING_STACK = 'OnboardingStack',
  PAYWALL_SCREEN = 'PaywallScreen',
  BOOTCAMP_PAYWALL_SCREEN = 'BootcampPaywallScreen',
  AP_PROGRAM_PAYWALL_SCREEN = 'APProgramPaywallScreen',
  TEST_SCREEN = 'MainTestScreen',
  TEST_FLOW_SCREEN = 'TestFlowScreen',
  PRACTICE_FEED_SCREEN = 'PracticeFeedScreen',
  TEST_COMPLETION_SCREEN = 'TestCompletionScreen',
  TEST_PRACTICE_SCREEN = 'TestPracticeScreen',
  TEST_PRACTICE_GAPS_BRIDGED_SCREEN = 'TestPracticeGapsBridgedScreen',
  COURSE_PICKER_SCREEN = 'CoursePickerScreen',
  SAT_TEST_PREVIEW_SCREEN = 'SATTestPreviewScreen',
  NOTIFICATION_REQUEST_SCREEN = 'NotificationRequestScreen',
  BOOTCAMP_ENTITLEMENT_SCREEN = 'BootcampEntitlementScreen',
  AP_RESULTS_STACK = 'ApResultsStack',
  BOOKMARKS_SCREEN = 'BookmarksScreen',
  SAT_RESULTS_STACK = 'SATResultsStack',
}

export enum StudyStack {
  COURSE_LIST_SCREEN = 'CourseListScreenFromStudy',
  FEED_SCREEN = 'FeedScreenFromStudy',
  STUDY_FEED_SCREEN = 'StudyFeedScreen',
  TOPIC_COMPLETION_SCREEN = 'TopicCompletionScreen',
  TOPIC_MASTERY_DROP_SCREEN = 'TopicMasteryDropScreen',
  MILESTONE_ACHIEVED_SCREEN = 'MilestoneAchievedScreen',
}

export enum OnboardingStack {
  INTRO_VIDEO = 'IntroVideo',
  EXAM_SELECTION = 'ExamSelection',
  EXAM_INPUTS = 'ExamInputs',
  LOADING_SCREEN = 'LoadingScreen',
  SUMMARY = 'Summary',
  SAT_TUTORIAL_SCREEN = 'SATTutorialScreen',
  AP_COURSE_SELECTION = 'ApCourseSelection',
  AP_TARGET_GRADE_SELECTION = 'ApTargetGradeSelection',
  AP_PERSONALIZED_STUDY_PLAN_ANIMATION = 'ApPersonalizedStudyPlanAnimation',
  AP_PERSONALIZED_STUDY_PLAN = 'ApPersonalizedStudyPlan',
  AP_PAYWALL = 'ApPaywall',
  AP_SAT_BOOTCAMP_UPSELL = 'ApSatBootcampUpsell',
  AP_COURSES_COMING_SOON = 'ApCoursesComingSoon',
  USERNAME_AND_PICTURE = 'UsernameAndPicture',
  SAT_FREE_ACCESS = 'SATFreeAccess',
  SAT_CATEGORY_SCORE_INPUT_SCREEN = 'SATCategoryScoreInputScreen',
}

export enum TestStack {
  TEST_PREP_LIST = 'TestPrepList',
  TEST_SCREEN = 'TestScreen',
  MILESTONE_ACHIEVED_TEST_SCREEN = 'MilestoneAchievedTestScreen',
}

export enum LoginStack {
  FORGOT_PASSWORD_SCREEN = 'ForgotPasswordScreen',
  FULL_SIGNUP_SCREEN = 'FullSignupScreen',
  FULL_LOGIN_SCREEN = 'FullLoginScreen',
}

export enum CourseStack {
  COURSE_FEED_SCREEN = 'CourseFeedScreen',
}

export enum MessagesStack {
  MESSAGES_LIST_SCREEN = 'MessagesListScreen',
  DMS_FROM_THE_DEAD_SCREEN = 'DmsFromTheDeadScreen',
}

export enum MessageStack {
  MESSAGE_SCREEN = 'MessageScreen',
  VIDEO_MESSAGE_SCREEN = 'VideoMessageScreen',
  RAISE_HAND_MESSAGE_SCREEN = 'RaiseHandMessageScreen',
}

export enum ApResultsStack {
  AP_RESULTS_LANDING_SCREEN = 'ApResultsLandingScreen',
  AP_RESULTS_INPUT_SCREEN = 'ApResultsInputScreen',
  AP_RESULTS_NEXT_SCREEN = 'ApResultsNextScreen',
  AP_RESULTS_FEEDBACK_SCREEN = 'ApResultsFeedbackScreen',
  AP_RESULTS_UPSELL_SCREEN = 'ApResultsUpsellScreen',
  AP_RESULTS_THANK_YOU_SCREEN = 'ApResultsThankYouScreen',
}

export enum SATResultsStack {
  SAT_RESULTS_LANDING_SCREEN = 'SATResultsLandingScreen',
  SAT_RESULTS_INPUT_SCREEN = 'SATResultsInputScreen',
  SAT_RESULTS_NEXT_SCREEN = 'SATResultsNextScreen',
  SAT_RESULTS_FEEDBACK_SCREEN = 'SATResultsFeedbackScreen',
  SAT_RESULTS_UPSELL_SCREEN = 'SATResultsUpsellScreen',
  SAT_RESULTS_THANK_YOU_SCREEN = 'SATResultsThankYouScreen',
}

export enum TemplateExampleStack {
  TEMPLATE_SCREEN = 'TemplateScreen',
  TEMPLATE_SCREEN_B = 'TemplateScreenB',
}

export const ScreenNames = {
  MainStack,
  LoginStack,
  CourseStack,
  MessagesStack,
  MessageStack,
  StudyStack,
  TestStack,
  OnboardingStack,
  ApResultsStack,
  SATResultsStack,
};

export enum TabNames {
  HOME = 'Home',
  TEST = 'Test',
  DISCOVER = 'Discover',
  PROFILE = 'Profile',
  INBOX = 'Inbox',
  LEADERBOARD = 'Leaderboard',
}

export enum ErrorNames {
  UNKNOWN_GENERATED_CONTENT = 'Unknown generated content',
}

export enum MCQAnswerSelectionState {
  'correctChoosen',
  'correct',
  'choosen',
  'neutral',
}

// 0.7 to hex = #B3
export const Colors = {
  bottomBar: '#000000',
  lightRed: '#DC5F5F',
  lightRed40: '#DC5F5F66',
  lightRed50: '#DC5F5F80',
  lightGrey: '#c3c3c3',
  avatarGradient: ['#ECEFC3', '#B0B40A'],
  screenGradient: ['#001D28', '#00425A'],
  screenGradientDarkBlue: ['#002634', '#00425A'],
  screenGradientDarkBlueToBlack: ['#002634', '#000000'],
  screenGradientDarkBlack: ['#001D28', '#000000'],
  screenGradientTransparentToDarkBlue: ['#00263400', '#002634'],
  transparentToFullDarkBlue: ['#00263400', '#002634FF'],
  topAlphaGradient: ['#0000002D', '#00000000'],
  topAlphaGradientStrong: ['#000000FF', '#00000000'],
  topAlphaGradientStrongInvert: ['#00000000', '#000000FF'],
  bottomGradientAppColor: ['#00000000', '#002634'],
  bottomGradientTestPrepColor: ['#00000000', '#000000'],
  bottomCardAlphaGradient: ['#00000000', '#00000022'],
  bookmarksAlphaGradient: ['#00000000', '#000000DD'],
  unitCardAlphaGradient: ['#00000000', '#000000'],
  ultimatePackageGradient: ['#00000000', '#00000080', '#000000'],
  ultimatePackageButtonGradient: ['#9E8119', '#E2C045', '#C79C04'],
  topicCompletionHeaderGradient: ['#000000', '#00000000'],
  aiProfileGradient: ['#54BDF9', '#FFCF53', '#FF5053', '#55FFD5'],
  tutorialIndicatorContainer: ['#00263400', '#002634FF'],
  testPrepQuestionGradient: ['#193B4800', '#193B48'],
  onboardingFreeAccessBackgroundGradient: ['#00516F', '#000000'],
  onboardingFreeAccessMainContainerGradient: ['#000000ff', '#00000000'],
  transparentToDarkBlueLinearGradient: ['#00000000', '#00516F'],
  answerRight: '#28B18FB3',
  answerWrong: '#DC5F5FB3',
  answerNeutral: '#00000099',
  topicProgressBackgroundColor: '#2F4250',
  lightYellow50: '#F19F2380',
  lightOrange20: '#FF8B3133',
  lightOrange30: '#F17D234D',
  gray29_70: '#29292970',
  khaki: '#AE912B',
  white100: '#FFFFFF',
  white04: '#FFFFFF0A',
  white06: '#FFFFFF0F',
  white08: '#FFFFFF14',
  white10: '#FFFFFF1A',
  white12: '#FFFFFF1F',
  white16: '#FFFFFF29',
  white20: '#FFFFFF33',
  white22: '#FFFFFF38',
  white24: '#FFFFFF3D',
  white26: '#FFFFFF42',
  white29: '#FFFFFF29',
  white30: '#FFFFFF33',
  white31: '#FFFFFF4D',
  white40: '#FFFFFF66',
  white50: '#FFFFFF80',
  white60: '#FFFFFF99',
  white70: '#FFFFFFB2',
  white80: '#FFFFFF80',
  white90: '#FFFFFFE5',
  white99: '#FFFFFF99',
  whiteCC: '#FFFFFFCC',
  black06: '#0000000F',
  appBackground: '#05071B',

  greyF2: '#F2F2F2',
  greyAA: '#AAAAAA',
  swamp4E: '#4B594E',
  swamp3B: '#505A3B',

  defaultQuestionBackground: 'black',
  defaultQuestionOpacity: 0.5,
  defaultQestionTextColor: 'white',

  greyBlinkingHighlight: '#FFFFFF99',
  navigationShadow: '#FFFFFF4D',

  captionGrey: '#FFFFFF99',

  buttonGreen: '#28B18F',
  buttonGrey: '#c3c3c366',
  greenBonusOption: '#28B18F33',
  greenBonusOptionNoOpacity: '#28B18F',

  darkBlue: '#002634',
  darkBlueB: '#00425A',
  lightBlue: '#349ECB',
  lightBlue40: '#349ECB66',
  lightGreen: '#2AC2B9',
  actionGreen: '#28B18F',
  actionGreen30: '#28B18F4D',
  actionYellow: '#E0B11A',
  darkerGreen: '#438871',

  lightBlue60: '#349ECB99',

  profileItemBackground: 'rgba(255, 255, 255, 0.1)',
  switchBackground: '#507180',
  emeraldGreen: '#28B18F',
  emeraldGreen20: '#28B18F33',

  mustard: '#FFD449',

  transparent55: '#00000055',
  answerRight70: '#28B18FB2',
  answerWrong70: '#DC5F5FB2',
  answerSelected70: '#FFD449B2',

  freeTrialView20: '#FFD44933',

  black20: '#00000033',
  black40: '#00000066',
  black50: '#00000080',
  black60: '#00000099',
  black80: '#000000CC',
  black100: '#000000',

  lightGray: '#346578',
  lightGray2: '#1A5166',

  brightRed: '#FF453A',

  sliceGreen: '#56D9B8',
  sliceGreen30: '#56D9B84D',
  sliceLightGreen: '#AAECDB',
  sliceLightGreen30: '#AAECDB4D',

  targetScoreTextColor: '#1F4E5F',

  provideFeedbackOptionColor: '#1F8A70',
  inactiveFeedbackButtonColor: '#3C3C432E',

  tryMeToolTipBlueColor: '#349ECBA1',

  helpTutorPanelBackgroundColor: '#349ECB',

  prussianBlue: '#003A4F',
  prussianBlueFromTransparent: ['#003A4F00', '#003A4F'],
  backgroundGradientDarkBlue: ['#1A4E6100', '#1A4E61FF'],

  greyScale: {
    29: '#3C3C434A',
    30: '#3C3C434D',
    36: '#3C3C435C',
  },

  promoCodeBlue: '#007AFF',

  promoCodeError: '#D84E4E',
  overlayBackgroundColor: 'rgba(0,0,0,0.5)',

  primary: {
    1000: '#0B56D5',
    900: '#0D63F3',
    800: '#2B77F5',
    700: '#4A8AF6',
    600: '#689EF8',
    500: '#86B1F9',
    400: '#A4C5FB',
    300: '#C3D8FC',
    200: '#E1ECFE',
    100: '#F9FAFE',
  },
  grayscale: {
    1000: '#16172E',
    900: '#1D3145',
    800: '#2C4A67',
    700: '#3B6289',
    600: '#4A7BAC',
    500: '#6893BE',
    400: '#8AACCD',
    300: '#ACC4DC',
    200: '#CFDDEA',
    100: '#F1F5F9',
    0: '#FFFFFF',
  },
  additional: {
    red: {
      100: '#D50B24',
      70: '#F54157',
      20: '#FCC9CF',
    },
    green: {
      100: '#0BD58A',
      70: '#92F9D3',
      20: '#C9FCE9',
    },
    orange: {
      100: '#F17D23',
      90: '#F5BE51',
      70: '#F9D897',
      20: '#FDF2DC',
    },
  },

  mediumGray: '#4F4F4F',
  mediumGray2: '#5B6365',

  darkGray: '#252525',
  darkGray90: '#252525E6',
};

const FontParts = {
  _defaultColor: {
    color: 'white',
  },
  _GochiHandFont: {
    fontFamily: 'GochiHand-Regular',
  } as TextStyle,
  _SFProRoundedFont: {
    fontFamily: 'SFProRounded-Regular',
  } as TextStyle,
  _BoldFont: {
    fontFamily: 'SFProRounded-Bold',
  } as TextStyle,
  _SFProRoundedFontBlack: {
    fontFamily: 'SFRounded-Black',
  } as TextStyle,
  _SFProRoundedFontHeavy: {
    fontFamily: 'SFRounded-Heavy',
  } as TextStyle,
  _SFProRoundedFontLight: {
    fontFamily: 'SFRounded-Light',
  } as TextStyle,
  _SFProRoundedFontMedium: {
    fontFamily: 'SFRounded-Medium',
  } as TextStyle,
  _SFProRoundedFontSemibold: {
    fontFamily: 'SFRounded-Semibold',
  } as TextStyle,
  _SFProRoundedFontThin: {
    fontFamily: 'SFRounded-Thin',
  } as TextStyle,
  _SFProRoundedFontUltralight: {
    fontFamily: 'SFRounded-Ultralight',
  } as TextStyle,
  _xxsmallSize: {
    fontSize: 9,
  },
  _xsmallSize: {
    fontSize: 11,
  },
  _smallSize: {
    fontSize: 13,
  },
  _semiMediumSize: {
    fontSize: 14,
  },
  _captionSize: {
    fontSize: 15,
  },
  _mediumSize: {
    fontSize: 16,
  },
  _semiLargeSize: {
    fontSize: 17,
  },
  _largeSize: {
    fontSize: 18,
  },
  _semixlargeSize: {
    fontSize: 19,
  },
  _xlargeSize: {
    fontSize: 20,
  },
  _xxlargeSize: {
    fontSize: 22,
  },
  _xxxlargeSize: {
    fontSize: 24,
  },
  _xxxxlargeSize: {
    fontSize: 28,
  },
  _xxxxxlargeSize: {
    fontSize: 32,
  },
  _plusSize: {
    fontSize: 36,
  },
  _plusPlusSize: {
    fontSize: 48,
  },

  _xsmallNormSize: {
    fontSize: normalize(11),
  },
  _smallNormSize: {
    fontSize: normalize(13),
  },
  _captionNormSize: {
    fontSize: normalize(15),
  },
  _mediumNormSize: {
    fontSize: normalize(16),
  },
  _largeNormSize: {
    fontSize: normalize(18),
  },
  _xlargeNormSize: {
    fontSize: normalize(20),
  },
  _xxlargeNormSize: {
    fontSize: normalize(22),
  },
  _xxxlargeNormSize: {
    fontSize: normalize(24),
  },
};

export enum Analytics {
  likeComment = 'likeComment',
  dislikeComment = 'dislikeComment',
  likeCard = 'likeCard',
  dislikeCard = 'dislikeCard',
  shareCard = 'shareCard',
  bookmarkCard = 'bookmarkCard',
  viewCard = 'viewCard',
  submitAnswer = 'submitAnswer',
  reportCard = 'reportCard',
  explanationShown = 'explanationShown',
  finishedReadingExplanation = 'finishedReadingExplanation',
  helpShown = 'helpShown',
  videoFinished = 'videoFinished',
  videoRestarted = 'videoRestarted',
  videoStarted = 'videoStarted',
  appInstalled = 'appInstalled',
  login = 'login',
  signUp = 'signUp',
  completeCourse = 'completeCourse',
  completeUnit = 'completeUnit',
  completeLevel = 'completeLevel',
  completeTopic = 'completeTopic',
  feedViewed = 'feedViewed',
  followCourse = 'followCourse',
  unfollowCourse = 'unfollowCourse',
  postComment = 'postComment',
  askDMFigureQuestion = 'askDMFigureQuestion',
  answerDMFigureQuestion = 'answerDMFigureQuestion',
  openDmMessageListScreen = 'openDmMessageListScreen',
  openDmMessageScreen = 'openDmSpeakerMessageScreen',
  closeDmMessageScreen = 'closeDmSpeakerMessageScreen',
  dmAnswerLike = 'dmAnswerLike',
  dmAnswerDislike = 'dmAnswerDislike',
  dmAnswerShare = 'dmAnswerShare',
  dmAnswerBookmark = 'dmAnswerBookmark',
  dmAnswerView = 'dmAnswerView',
  dmAnswerReport = 'dmAnswerReport',
  dmAnswerDislikeComment = 'dmAnswerDislikeComment',
  dmAnswerLikeComment = 'dmAnswerLikeComment',
  dmAnswerPostComment = 'dmAnswerPostComment',
  shareAiFigure = 'shareAiFigure',
  firstContentViewed = 'firstContentViewed',
  signupCompleted = 'signupCompleted',
  userLikesApp = 'userLikesApp',
  userDislikesApp = 'userDislikesApp',
  userSkippedRating = 'userSkippedRating',
  userGoal = 'appGoal',
  anonymousAppGoal = 'anonymousAppGoal',
  raiseHandAskTutorQuestion = 'raiseHandAskTutorQuestion',
  raiseHand = 'raiseHand',
  frqOpenLinkLocal = 'openFrqTutorLinkLocal',
  frqOpenLinkLocalRequest = 'openFrqTutorLinkLocalRequest',
  frqOpenLinkLocalFail = 'openFrqTutorLinkLocalFail',
  frqShareEmail = 'emailFrqTutorLink',
  frqShareEmailRequest = 'emailFrqTutorLinkRequest',
  frqShareEmailFail = 'emailFrqTutorLinkFail',
  frqShareLink = 'shareFrqTutorLink',
  frqLinkMissing = 'missingFrqTutorLink',
  openRaiseHandMessageScreen = 'openRaiseHandMessageScreen',
  closeRaiseHandMessageScreen = 'closeRaiseHandMessageScreen',
  raiseHandTryMeUsed = 'raiseHandTryMeUsed',
  raiseHandTutorHelpUsed = 'raiseHandTutorHelpUsed',
  answerRaiseHandQuestion = 'answerRaiseHandQuestion',
  raiseHandMessageFeedback = 'raiseHandMessageFeedback',
  pregeneratedTestPrepMessage = 'pregeneratedTestPrepMessage',
  coursePurchased = 'coursePurchased',
  inAppPurchase = 'inAppPurchase',
  coursePurchaseAvatarClicked = 'coursePurchaseAvatarClicked',
  coursePaywallViewed = 'coursePaywallViewed',
  viewedTutorial = 'viewedTutorial',
  nextTutorialPressed = 'nextTutorialPressed',
  getStartedTutorialPressed = 'getStartedTutorialPressed',
  notificationPermission = 'notificationPermission',
  notificationReceived = 'notificationReceived',
  notificationOpened = 'notificationOpened',
  unitTestSubmitAnswer = 'unitTestSubmitAnswer',
  testPrepTabOpened = 'testPrepTabOpened',
  courseSelected = 'courseSelected',
  unitTestOpened = 'unitTestOpened',
  unitTestStarted = 'unitTestStarted',
  unitTestCompleted = 'unitTestCompleted',
  unitTestExited = 'unitTestExited',
  unitTestQuestionViewed = 'unitTestQuestionViewed',
  unitTestSkippedQuestionReviewDialogShown = 'unitTestSkippedQuestionReviewDialogShown',
  targetedPracticeOpened = 'targetedPracticeOpened',
  targetedPracticeStarted = 'targetedPracticeStarted',
  completeTargetedPractice = 'completeTargetedPractice',
  purchaseButtonClicked = 'purchaseButtonClicked',
  referralLinkShared = 'referralLinkShared',
  referralWebpageOpened = 'referralWebpageOpened',
  referralEmailRegistered = 'referralEmailRegistered',
  referralSignUpSuccess = 'referralSignUpSuccess',
  referralCoursePurchased = 'referralCoursePurchased',
  referralCourseRedeemed = 'referralCourseRedeemed',
  examSelected = 'examSelected',
  loginClicked = 'loginClicked',
  signUpClicked = 'signUpClicked',
  screenViewed = 'Viewed',
  satExamDateSelected = 'satExamDateSelected',
  satLastScoreProvided = 'satLastScoreProvided',
  satLastReadingWritingScoreProvided = 'satLastReadingWritingScoreProvided',
  satLastMathScoreProvided = 'satLastMathScoreProvided',
  satTargetScoreSet = 'satTargetScoreSet',
  satPrepDataSubmitted = 'satPrepDataSubmitted',
  satBootcampLearnMoreClicked = 'satBootcampLearnMoreClicked',
  satBootcampDetailsViewed = 'satBootcampDetailsViewed',
  testimonialScrolled = 'testimonialScrolled',
  moneyBackLearnMoreClicked = 'moneyBackLearnMoreClicked',
  emailOptInStateChanged = 'emailOptInStateChanged',
  followCourseSatBootcamp = 'followCourse_sat_bootcamp',
  satExamDateFinalized = 'sat_exam_date',
  takingNextSATExam = 'taking_next_sat_exam',
  enterPromoCodeClicked = 'enterPromoCodeClicked',
  promoCodeEntered = 'promoCodeEntered',
  promoCodeRedeemed = 'promoCodeRedeemed',
  apExamResultFlowStarted = 'apExamResultFlowStarted',
  apExamResultScreenClosed = 'apExamResultScreenClosed',
  apExamResultsSubmitted = 'apExamResultsSubmitted',
  nextExamsDataSubmitted = 'nextExamsDataSubmitted',
  examPreparationFeedbackSubmitted = 'examPreparationFeedbackSubmitted',
  satOfferShown = 'satOfferShown',
  masteryLevelDropped = 'masteryLevelDropped',
  masteryLevelGained = 'masteryLevelGained',
  entitlementConfirmationShown = 'entitlementConfirmationShown',
  entitlementRedeemed = 'entitlementRedeemed',
  apOnboardingCourseSelected = 'APOnboardingCourseSelected',
  apOnboardingCourseDeselected = 'APOnboardingCourseDeselected',
  apOnboardingCoursesSubmitted = 'APOnboardingCoursesSubmitted',
  apTargetGradeSet = 'apTargetGradeSet',
  apPrepDataSubmitted = 'apPrepDataSubmitted',
  apProgramDetailsViewed = 'apProgramDetailsViewed',
  apProgramOverviewContinueTapped = 'apProgramOverviewContinueTapped',
  deepLinkRegistered = 'deepLinkRegistered',
  deepLinkFollowed = 'deepLinkFollowed',
  deepLinkFailed = 'deepLinkFailed',
  deepLinkSucceeded = 'deepLinkSucceeded',
  purchasedSATBootcamp = 'purchased_sat_bootcamp',
  purchasedAPProgram = 'purchased_ap_program',
  apOfferShown = 'apOfferShown',
  addOnToggled = 'addOnToggled',
  redeemedApProgramPurchase = 'redeemed_ap_program_purchase',
  redeemedSATBootcampPurchase = 'redeemed_sat_bootcamp_purchase',
  profileDetailsUpdated = 'profileDetailsUpdated',
  leaderboardOpened = 'leaderboardOpened',
  paywallSkipped = 'paywallSkipped',
  SATFreeAccessClaimed = 'SATFreeAccessClaimed',
  SATScoresChanged = 'SATScoresChanged',
  satExamResultFlowStarted = 'satExamResultFlowStarted',
  satExamResultScreenClosed = 'satExamResultScreenClosed',
  satExamResultsSubmitted = 'satExamResultsSubmitted',
}

// If events are supposed to be sent to AppsFlyer, they should be added to this set
export const APPSFLYER_EVENTS_WHITELIST = new Set([
  Analytics.takingNextSATExam,
  Analytics.satExamDateFinalized,
  Analytics.followCourseSatBootcamp,
  Analytics.followCourse,
  Analytics.examSelected,
  Analytics.satPrepDataSubmitted,
  Analytics.signUp,
  Analytics.login,
  Analytics.unitTestOpened,
  Analytics.unitTestStarted,
  Analytics.unitTestSubmitAnswer,
  Analytics.unitTestCompleted,
  Analytics.unitTestExited,
  Analytics.unitTestQuestionViewed,
  Analytics.unitTestSkippedQuestionReviewDialogShown,
  Analytics.targetedPracticeOpened,
  Analytics.targetedPracticeStarted,
  Analytics.completeTargetedPractice,
  Analytics.raiseHand,
  Analytics.answerRaiseHandQuestion,
  Analytics.raiseHandAskTutorQuestion,
  Analytics.viewCard,
  Analytics.submitAnswer,
  Analytics.completeTopic,
  Analytics.completeUnit,
  Analytics.likeCard,
  Analytics.bookmarkCard,
  Analytics.shareCard,
  Analytics.askDMFigureQuestion,
  Analytics.answerDMFigureQuestion,
  Analytics.coursePurchased,
  Analytics.coursePurchaseAvatarClicked,
  Analytics.coursePaywallViewed,
  Analytics.purchaseButtonClicked,
  Analytics.testimonialScrolled,
  Analytics.moneyBackLearnMoreClicked,
  Analytics.masteryLevelDropped,
  Analytics.masteryLevelGained,
  Analytics.purchasedSATBootcamp,
  Analytics.purchasedAPProgram,
  Analytics.redeemedApProgramPurchase,
  Analytics.redeemedSATBootcampPurchase,
]);

export const Sizes = {
  xxsmall: 2,
  xsmall: 4,
  small: 8,
  normal: 10,
  semiMedium: 12,
  medium: 16,
  semiLarge: 20,
  large: 24,
  xlarge: 32,
  xxlarge: 47,

  verticalActionsMaxWidth: 50,

  defaultOptionHeight: 64,
  smallProgressRadius: 16,

  // normalizateion according to IPhone 14 Pro Max
  normalizeIP14PM: (size: number): number => normalize(size * (33 / 38)),

  fonts: {
    h1: 20,
    h2: 16,
    h3: 14,
  },

  getAppWindowWidth: (): number => {
    return isWebPlatform
      ? Math.min(
          Dimensions.get('window').width,
          Dimensions.get('window').height * (9 / 15),
        )
      : Dimensions.get('window').width;
  },

  getAppWindowHeight: (): number => {
    return Dimensions.get('window').height;
  },

  safeAreaInsetsTop: !isWebPlatform
    ? StaticSafeAreaInsets.safeAreaInsetsTop
    : 0,
  safeAreaInsetsBottom: !isWebPlatform
    ? StaticSafeAreaInsets.safeAreaInsetsBottom
    : 0,
  safeAreaInsetsLeft: !isWebPlatform
    ? StaticSafeAreaInsets.safeAreaInsetsLeft
    : 0,
  safeAreaInsetsRight: !isWebPlatform
    ? StaticSafeAreaInsets.safeAreaInsetsRight
    : 0,

  header: {
    height:
      Platform.select({
        ios: 50,
        android: 50,
        web: 50,
      }) ?? 0,
  },
  tabs: {
    height:
      Platform.select({
        ios: 50,
        android: 56,
        web: 0,
      }) ?? 0,
  },

  gap: {
    small: 8,
    medium: 16,
    large: 24,
    xlarge: 32,
    xxlarge: 40,
  },

  icons: {
    [18]: 18,
    [80]: 80,
  },
};

export const Fonts = {
  normalize: (
    style: TextStyle,
    transformer?: (scale: number) => number,
  ): TextStyle => {
    if (!style.fontSize) {
      return style;
    }
    return {
      ...style,
      fontSize: (transformer ?? normalize)(style.fontSize),
    };
  },
  xxsmall: {
    ...FontParts._defaultColor,
    ...FontParts._SFProRoundedFont,
    ...FontParts._xxsmallSize,
  },
  xsmall: {
    ...FontParts._defaultColor,
    ...FontParts._SFProRoundedFont,
    ...FontParts._xsmallSize,
  },
  small: {
    ...FontParts._defaultColor,
    ...FontParts._SFProRoundedFont,
    ...FontParts._smallSize,
  },
  smallGochiHand: {
    ...FontParts._defaultColor,
    ...FontParts._GochiHandFont,
    ...FontParts._smallSize,
  },
  semiMedium: {
    ...FontParts._defaultColor,
    ...FontParts._SFProRoundedFont,
    ...FontParts._semiMediumSize,
  },
  caption: {
    ...FontParts._defaultColor,
    ...FontParts._SFProRoundedFont,
    ...FontParts._captionSize,
  },
  medium: {
    ...FontParts._defaultColor,
    ...FontParts._SFProRoundedFont,
    ...FontParts._mediumSize,
  },
  mediumGochiHand: {
    ...FontParts._defaultColor,
    ...FontParts._GochiHandFont,
    ...FontParts._mediumSize,
  },
  largeGochiHand: {
    ...FontParts._defaultColor,
    ...FontParts._GochiHandFont,
    ...FontParts._largeSize,
  },
  semiLarge: {
    ...FontParts._defaultColor,
    ...FontParts._SFProRoundedFont,
    ...FontParts._semiLargeSize,
  },
  large: {
    ...FontParts._defaultColor,
    ...FontParts._SFProRoundedFont,
    ...FontParts._largeSize,
  },
  semixlarge: {
    ...FontParts._defaultColor,
    ...FontParts._SFProRoundedFont,
    ...FontParts._semixlargeSize,
  },
  xlarge: {
    ...FontParts._defaultColor,
    ...FontParts._SFProRoundedFont,
    ...FontParts._xlargeSize,
  },
  xlargeGochiHand: {
    ...FontParts._defaultColor,
    ...FontParts._GochiHandFont,
    ...FontParts._xlargeSize,
  },
  xxlarge: {
    ...FontParts._defaultColor,
    ...FontParts._SFProRoundedFont,
    ...FontParts._xxlargeSize,
  },
  xxxlarge: {
    ...FontParts._defaultColor,
    ...FontParts._SFProRoundedFont,
    ...FontParts._xxxlargeSize,
  },
  plusPlusSize: {
    ...FontParts._defaultColor,
    ...FontParts._SFProRoundedFont,
    ...FontParts._plusPlusSize,
  },
  xxsmallBold: {
    ...FontParts._defaultColor,
    ...FontParts._xxsmallSize,
    ...FontParts._BoldFont,
  },
  xsmallBold: {
    ...FontParts._defaultColor,
    ...FontParts._xsmallSize,
    ...FontParts._BoldFont,
  },
  smallBold: {
    ...FontParts._defaultColor,
    ...FontParts._smallSize,
    ...FontParts._BoldFont,
  },
  semiMediumBold: {
    ...FontParts._defaultColor,
    ...FontParts._smallSize,
    ...FontParts._BoldFont,
  },
  captionBold: {
    ...FontParts._defaultColor,
    ...FontParts._captionSize,
    ...FontParts._BoldFont,
  },
  mediumBold: {
    ...FontParts._defaultColor,
    ...FontParts._mediumSize,
    ...FontParts._BoldFont,
  },
  largeBold: {
    ...FontParts._defaultColor,
    ...FontParts._largeSize,
    ...FontParts._BoldFont,
  },
  semiLargeBold: {
    ...FontParts._defaultColor,
    ...FontParts._semiLargeSize,
    ...FontParts._BoldFont,
  },
  xlargeBold: {
    ...FontParts._defaultColor,
    ...FontParts._xlargeSize,
    ...FontParts._BoldFont,
  },
  semixlLargeBold: {
    ...FontParts._defaultColor,
    ...FontParts._semixlargeSize,
    ...FontParts._BoldFont,
  },
  xxlargeBold: {
    ...FontParts._defaultColor,
    ...FontParts._xxlargeSize,
    ...FontParts._BoldFont,
  },
  xxxlargeBold: {
    ...FontParts._defaultColor,
    ...FontParts._xxxlargeSize,
    ...FontParts._BoldFont,
  },
  xxxxlargeBold: {
    ...FontParts._defaultColor,
    ...FontParts._xxxxlargeSize,
    ...FontParts._BoldFont,
  },
  xxxxxlargeBold: {
    ...FontParts._defaultColor,
    ...FontParts._xxxxxlargeSize,
    ...FontParts._BoldFont,
  },
  plusSizeBold: {
    ...FontParts._defaultColor,
    ...FontParts._plusSize,
    ...FontParts._BoldFont,
  },
  plusPlusSizeBold: {
    ...FontParts._defaultColor,
    ...FontParts._plusPlusSize,
    ...FontParts._BoldFont,
  },
  xsmall500: {
    ...FontParts._defaultColor,
    ...FontParts._xsmallSize,
    ...FontParts._SFProRoundedFontMedium,
  },
  semiMedium500: {
    ...FontParts._defaultColor,
    ...FontParts._semiMediumSize,
    ...FontParts._SFProRoundedFontMedium,
  },
  caption500: {
    ...FontParts._defaultColor,
    ...FontParts._captionSize,
    ...FontParts._SFProRoundedFontMedium,
  },
  caption600: {
    ...FontParts._defaultColor,
    ...FontParts._captionSize,
    ...FontParts._SFProRoundedFontSemibold,
  },
  caption800: {
    ...FontParts._defaultColor,
    ...FontParts._captionSize,
    ...FontParts._SFProRoundedFontHeavy,
  },
  semiLarge500: {
    ...FontParts._defaultColor,
    ...FontParts._semiLargeSize,
    ...FontParts._SFProRoundedFontMedium,
  },
  large500: {
    ...FontParts._defaultColor,
    ...FontParts._SFProRoundedFontMedium,
    ...FontParts._largeSize,
  },
  semiLarge600: {
    ...FontParts._defaultColor,
    ...FontParts._semiLargeSize,
    ...FontParts._SFProRoundedFontSemibold,
  },
  semiMedium600: {
    ...FontParts._defaultColor,
    ...FontParts._semiMediumSize,
    ...FontParts._SFProRoundedFontSemibold,
  },
  large800: {
    ...FontParts._defaultColor,
    ...FontParts._largeSize,
    ...FontParts._SFProRoundedFontHeavy,
  },
  xxxlarge600: {
    ...FontParts._defaultColor,
    ...FontParts._xxxlargeSize,
    ...FontParts._SFProRoundedFontSemibold,
  },
  xxlargeBold600: {
    ...FontParts._defaultColor,
    ...FontParts._xxlargeSize,
    ...FontParts._SFProRoundedFontSemibold,
  },
  xxlargeBold800: {
    ...FontParts._defaultColor,
    ...FontParts._xxlargeSize,
    ...FontParts._SFProRoundedFontHeavy,
  },
  xxlargeBold900: {
    ...FontParts._defaultColor,
    ...FontParts._xxlargeSize,
    ...FontParts._SFProRoundedFontBlack,
  },
  small500: {
    ...FontParts._defaultColor,
    ...FontParts._smallSize,
    ...FontParts._SFProRoundedFontMedium,
  },
  small600: {
    ...FontParts._defaultColor,
    ...FontParts._smallSize,
    ...FontParts._SFProRoundedFontSemibold,
  },
  xsmall600: {
    ...FontParts._defaultColor,
    ...FontParts._xsmallSize,
    ...FontParts._SFProRoundedFontSemibold,
  },
  xsmall800: {
    ...FontParts._defaultColor,
    ...FontParts._xsmallSize,
    ...FontParts._SFProRoundedFontHeavy,
  },
  smallSemiBold: {
    ...FontParts._defaultColor,
    ...FontParts._smallSize,
    ...FontParts._SFProRoundedFontSemibold,
  },
};

export const Durations = {
  blinkDelay: 10000,
  blinkInterval: 500,

  actionFeedbackDelay: 2000,
  longNotificationDelay: 5000,

  superQuickTransition: 50,
  quickTransition: 500,

  defaultRetryDelay: 10000,
};

export enum AppMajorVersionPatch {
  ProgressV2 = 'ProgressV2',
}
