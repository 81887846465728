import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, Text, TextInput, View } from 'react-native';

import locale from '../../App/locale';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { checkIsUsernameValid } from '../../Profile/services/utils';

export type UsernameInputProps = {
  value: string;
  onChangeText?: (val: string) => void;
  error?: string;
  onValidate: (isValid: boolean) => void;
};
export const UsernameInput = ({
  value,
  onChangeText,
  error,
  onValidate,
}: UsernameInputProps): React.ReactElement => {
  const [updated, setUpdated] = useState(false);

  const isValidUsername = useMemo(() => {
    if (error) {
      return {
        error,
      };
    }
    return checkIsUsernameValid(value);
  }, [value, error]);

  const isValid = useMemo(() => {
    if (error || (updated && isValidUsername?.error)) {
      return false;
    }
    return true;
  }, [error, updated, isValidUsername]);

  const inputStyles = useMemo(() => {
    if (!isValid) {
      return {
        input: [styles.input, styles.invalidInput],
        inputField: [styles.inputField, styles.invalidInputFiled],
      };
    }
    return {
      input: styles.input,
      inputField: styles.inputField,
    };
  }, [isValid]);

  const onText = useCallback(
    (text: string) => {
      if (!updated) {
        setUpdated(true);
      }
      onChangeText?.(text);
      onValidate(isValid);
    },
    [updated, onChangeText, onValidate, isValid],
  );
  return (
    <View style={styles.container}>
      <Text style={styles.inputLabel}>{locale.onboarding.username_label}</Text>
      <View style={inputStyles.input}>
        <TextInput
          value={value}
          onChangeText={onText}
          placeholder={locale.onboarding.username_placeholder}
          style={inputStyles.inputField}
          autoCapitalize="none"
          returnKeyType="done"
        />
      </View>
      {isValid ? (
        <Text style={styles.hint}>
          {locale.profile_screen_settings.edit_username_requirements}
        </Text>
      ) : (
        <Text style={styles.error}>{isValidUsername?.error}</Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  input: {
    width: '100%',
    padding: Sizes.semiMedium,
    borderRadius: Sizes.small,
    borderWidth: 1,
    borderColor: Colors.white12,
    marginTop: 5,
  },
  invalidInput: {
    borderColor: Colors.additional.red[70],
  },
  inputLabel: {
    ...Fonts.small,
  },
  inputField: {
    ...Fonts.semiLarge500,
  },
  invalidInputFiled: {
    color: Colors.additional.red[70],
  },
  hint: {
    ...Fonts.small,
    marginTop: Sizes.small,
    color: Colors.white60,
  },
  error: {
    ...Fonts.small,
    marginTop: Sizes.small,
    color: Colors.additional.red[70],
  },
});
