import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { useAppDispatch } from '../../App/store';
import { CommonActionButton } from '../../Common/components';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { BootcampEntryPoint } from '../../Onboarding/entities';
import { mapSatBootcampFreePoints } from '../services/mappers';
import { syncSATBootcampAndNavigateToPaywall } from '../services/slices';

export const SATUnlockImprovement = (): React.ReactElement | null => {
  const dispatch = useAppDispatch();
  const freePoints = useAppSelector(mapSatBootcampFreePoints);
  const isLoading = useAppSelector(state => state.SAT.isLoading);

  const handleNavigateToPaywall = useCallback(() => {
    dispatch(
      syncSATBootcampAndNavigateToPaywall({
        bootcampEntryPoint: BootcampEntryPoint.SAT_FREE_ACCESS_PROMO,
      }),
    );
  }, [dispatch]);

  const text = useMemo(() => {
    return {
      title: locale.sat.unlock_unlimited_improvement,
      description: locale.sat.unlock_unlimited_improvement_description.replace(
        '{POINTS}',
        freePoints.toString(),
      ),
    };
  }, [freePoints]);

  if (!freePoints) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text style={styles.textTitle}>{text.title}</Text>
        <Text style={styles.textDescription}>{text.description}</Text>
      </View>

      <CommonActionButton
        onPress={handleNavigateToPaywall}
        text={locale.common.unlock}
        style={styles.buttonContainer}
        textStyle={styles.buttonText}
        isLoading={isLoading}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
    borderRadius: Sizes.semiMedium,
    backgroundColor: Colors.emeraldGreen20,
    borderWidth: 1,
    borderColor: Colors.white12,
    flexDirection: 'row',
    alignItems: 'center',
    gap: Sizes.semiMedium,
    justifyContent: 'space-between',
    width: '100%',
  },

  textContainer: {
    flex: 1,
  },

  textTitle: {
    ...Fonts.semiMediumBold,
  },
  textDescription: {
    ...Fonts.small,
    color: Colors.white60,
  },

  buttonText: {
    ...Fonts.caption500,
  },
  buttonContainer: {
    paddingVertical: 10,
  },
});
