import { useIsFocused } from '@react-navigation/core';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  KeyboardAvoidingView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';

import locale from '../../../App/locale';
import store, { useAppDispatch } from '../../../App/store';
import { setApResultsFeedback } from '../../../ApResults/services/slices';
import { setSATResultsFeedback } from '../../../SATResults/services/slices';
import { CommonExamType } from '../../entities';
import {
  Colors,
  Durations,
  Fonts,
  Sizes,
} from '../../services/utils/AppConstants';

type ExamResultsFeedbackTextProps = {
  examType: CommonExamType;
};

export const ExamResultsFeedbackText = React.memo(
  ({ examType }: ExamResultsFeedbackTextProps): React.ReactElement => {
    const ref = useRef<TextInput>(null);
    const dispatch = useAppDispatch();
    const isFocused = useIsFocused();
    const handleFeedbackTextChange = useCallback(
      (value: string) => {
        if (examType === CommonExamType.AP) {
          dispatch(setApResultsFeedback(value));
        } else {
          dispatch(setSATResultsFeedback(value));
        }
      },
      [dispatch, examType],
    );

    const getFeedbackText = useMemo(() => {
      if (examType === CommonExamType.AP) {
        return store.getState().apResults.apResultsFeedback || '';
      } else {
        return store.getState().SATResults.satResultsFeedback || '';
      }
    }, [examType]);

    useEffect(() => {
      if (isFocused) {
        setTimeout(() => {
          ref.current?.setNativeProps({
            text: getFeedbackText,
          });
        }, Durations.quickTransition);
      }
    }, [getFeedbackText, isFocused]);

    const getFeedbackDescription = useMemo(() => {
      if (examType === CommonExamType.AP) {
        return locale.ap_results.feedback_description;
      } else {
        return locale.sat_results.feedback_description;
      }
    }, [examType]);

    return (
      <View>
        <Text style={styles.text}>{getFeedbackDescription}</Text>

        <KeyboardAvoidingView behavior="padding" keyboardVerticalOffset={100}>
          <View style={styles.fieldContainer}>
            <TextInput
              ref={ref}
              placeholder={locale.ap_results.add_feedback_placeholder}
              placeholderTextColor={Colors.white70}
              style={styles.field}
              onChangeText={handleFeedbackTextChange}
              multiline
            />
          </View>
        </KeyboardAvoidingView>
      </View>
    );
  },
);

const styles = StyleSheet.create({
  text: {
    ...Fonts.semiMedium,
    color: Colors.white70,
    textAlign: 'center',
  },

  fieldContainer: {
    height: 216,
    backgroundColor: Colors.white10,
    marginTop: Sizes.medium,
    borderRadius: Sizes.small,
    borderWidth: 1,
    borderColor: Colors.white12,
    padding: Sizes.semiMedium,
  },
  field: {
    ...Fonts.caption,
    flex: 1,
  },
});
