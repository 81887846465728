import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import locale from '../../../../App/locale';
import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { mapUsername } from '../../../../Auth/services/mappers';
import { getLeaderboardGraphQLCall } from '../../../graphql';
import {
  LEADERBOARD_SLICE_NAME,
  LeaderboardSliceState,
} from '../LeaderboardSlice';

export const getLeaderboardUserListAction = createAsyncThunk(
  `${LEADERBOARD_SLICE_NAME}/getLeaderboardUserList`,
  async (_params, thunkApi) => {
    try {
      const response = await getLeaderboardGraphQLCall();
      const authState = thunkApi.getState() as RootState;
      const userId = authState.auth.authUser?.id || '0';
      const username = mapUsername(authState);
      const profileImageUrl = authState.auth.authUser?.profileImageURL;

      return {
        leaderboard: response.leaderboard,
        userId,
        username,
        profileImageUrl,
      };
    } catch (error) {
      if (error instanceof Error) {
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const getLeaderboardUserListActionExtraReducers = (
  builder: ActionReducerMapBuilder<LeaderboardSliceState>,
): void => {
  builder.addCase(getLeaderboardUserListAction.pending, state => {
    state.isLoading = true;
  });
  builder.addCase(getLeaderboardUserListAction.fulfilled, (state, action) => {
    state.topUsers = action.payload.leaderboard.topUsers;
    state.adjacentUsers = action.payload.leaderboard.adjacentUsers;
    state.totalUsers = action.payload.leaderboard.totalUsers;
    const userRank = action.payload.leaderboard.userRank;
    const { userId, username, profileImageUrl } = action.payload;

    if (userRank) {
      state.userRank = userRank;
    } else {
      state.userRank = {
        id: userId,
        username,
        points: 0,
        rank: action.payload.leaderboard.totalUsers,
        profileImageUrl,
      };
    }
    state.isLoading = false;
  });
  builder.addCase(getLeaderboardUserListAction.rejected, state => {
    state.isLoading = false;
  });
};
