import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import { SATBootcamp, SetSATCategoryScoresPayload } from '../../entities';

import {
  getUserSATProgramDetailsExtraReducers,
  syncSATBootcampAndNavigateToPaywallExtraReducers,
} from './SATSliceActions';

const PERSIST_KEY = 'SATPersistKey';
export const SAT_SLICE_NAME = 'SATSlice';

export type SATSliceState = {
  bootcamp?: SATBootcamp;

  satTimeSpentSeconds: number;

  // Flag to record if free trial is ended for SAT bootcamp
  isFreeTrialEnded: boolean;

  isLoading: boolean;
  lastSATProgramSyncTime?: number;
};

const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: ['bootcamp', 'isFreeTrialEnded'],
  blacklist: ['satTimeSpentSeconds', 'isLoading', 'lastSATProgramSyncTime'],
};

const initialState: SATSliceState = {
  bootcamp: undefined,
  satTimeSpentSeconds: 0,
  isFreeTrialEnded: false,
  isLoading: false,
  lastSATProgramSyncTime: undefined,
};

const satSlice = createSlice({
  name: SAT_SLICE_NAME,
  initialState,
  reducers: {
    resetSATSlice: state => {
      state.bootcamp = undefined;
      state.isFreeTrialEnded = false;
      state.lastSATProgramSyncTime = undefined;
    },

    setSATFreeTrialEnded: (state, action: PayloadAction<boolean>) => {
      state.isFreeTrialEnded = action.payload;
    },

    setSatTimeSpentSeconds: (state, action: PayloadAction<number>) => {
      state.satTimeSpentSeconds = action.payload;
    },
    setSatBootcampCategoryScores: (
      state,
      action: SetSATCategoryScoresPayload,
    ) => {
      if (!state.bootcamp?.details) {
        return;
      }

      state.bootcamp.details.mathScore = action.payload.mathScore;
      state.bootcamp.details.readingWritingScore =
        action.payload.readingWritingScore;
    },
  },
  extraReducers: builder => {
    getUserSATProgramDetailsExtraReducers(builder);
    syncSATBootcampAndNavigateToPaywallExtraReducers(builder);
  },
});

export const {
  resetSATSlice,
  setSatTimeSpentSeconds,
  setSATFreeTrialEnded,
  setSatBootcampCategoryScores,
} = satSlice.actions;

export const SATSlice = persistReducer(persistConfig, satSlice.reducer);
