import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

export enum NetworkStatus {
  Online = 'ONLINE',
  Offline = 'OFFLINE',
}

export type NetworkSliceState = {
  isOnline: boolean;
};

const PERSIST_KEY = 'network';
export const NETWORK_SLICE_NAME = 'NetworkSlice';

const initialState: NetworkSliceState = {
  isOnline: true,
};

const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: ['isOnline'],
} as PersistConfig<NetworkSliceState>;

const slice = createSlice({
  name: NETWORK_SLICE_NAME,
  initialState,
  reducers: {
    setNetworkStatus: (state, action: PayloadAction<NetworkStatus>) => {
      state.isOnline = action.payload === NetworkStatus.Online;
    },
  },
});

export const { setNetworkStatus } = slice.actions;

export const NetworkSlice = persistReducer(persistConfig, slice.reducer);
