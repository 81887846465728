import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { CommonLinearProgress } from '../../../Common/components';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { SATUnitsIndex } from '../../entities';
import { useSATStatsScore } from '../../services/hooks';

type Props = {
  shouldShowStats: boolean;
  title: string;
  score?: number;
  maxScore?: number;
  unitsIndex?: SATUnitsIndex;
  lastExamScore?: number;
};

export const SATProfileStatsCategoryScore = (
  props: Props,
): React.ReactElement => {
  const { shouldShowStats, score, maxScore, unitsIndex, lastExamScore } = props;

  const {
    noScoreTextDescription,
    progressPercentage,
    lastExamScorePercentage,
    getScoreBoostedText,
    isScoreBoosted,
  } = useSATStatsScore({
    score,
    maxScore,
    unitsIndex,
    lastExamScore,
    isTotalScore: false,
  });

  const scoreTextStyle = useMemo(() => {
    return [styles.scoreText, isScoreBoosted && styles.scoreTextHighlight];
  }, [isScoreBoosted]);

  return (
    <View style={styles.container}>
      <Text style={styles.titleText}>{props.title}</Text>

      <View style={styles.detailContainer}>
        {!shouldShowStats && (
          <Text style={styles.detailText}>{noScoreTextDescription}</Text>
        )}

        {shouldShowStats && (
          <>
            <View style={styles.scoreContainer}>
              <Text style={scoreTextStyle}>{score}</Text>
              <Text style={styles.boostScoreText}>{getScoreBoostedText}</Text>
            </View>

            <View style={styles.scoreProgressContainer}>
              <CommonLinearProgress
                progressPercentage={progressPercentage}
                lastExamScorePercentage={lastExamScorePercentage}
              />
            </View>
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },

  titleText: {
    ...Fonts.smallBold,
    color: 'white',
    textAlign: 'center',
  },
  detailContainer: {
    marginTop: Sizes.xsmall,
  },
  detailText: {
    ...Fonts.small,
    textAlign: 'center',
    color: Colors.white60,
  },

  scoreProgressContainer: {
    marginTop: Sizes.xsmall,
  },
  scoreText: {
    ...Fonts.xlargeBold,
    color: Colors.emeraldGreen,
    textAlign: 'center',
  },
  boostScoreText: {
    ...Fonts.smallBold,
    color: Colors.sliceLightGreen,
    textAlign: 'right',
  },
  scoreTextHighlight: {
    color: Colors.sliceLightGreen,
  },
  scoreContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
