import { createAsyncThunk } from '@reduxjs/toolkit';
import { Product } from 'react-native-iap';

import { RootState } from '../../../../App/store';
import { PaywallPurchaseType } from '../../../../Common/entities';
import {
  getBoolAnalyticsText,
  trackAnalyticsEvent,
} from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import {
  getFromEntryPoint,
  getProductDetails,
  getProgramName,
  getTargetGrades,
} from '../../mappers';
import { PURCHASE_SLICE_NAME } from '../PurchasesSlice';

type MapPurchaseToAnalyticsEventParams = {
  purchasedItem: Product;
  purchaseType: PaywallPurchaseType;
  canBeSkipped?: boolean;
};

export const mapPurchaseToAnalyticsEvent = createAsyncThunk(
  `${PURCHASE_SLICE_NAME}/mapPurchaseToAnalyticsEvent`,
  async (params: MapPurchaseToAnalyticsEventParams, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { purchasedItem, purchaseType } = params;
    const targetGrades = getTargetGrades({
      purchaseType,
      state,
    });
    const programName = getProgramName({
      purchaseType,
      state,
    });

    const fromEntryPoint = getFromEntryPoint(state, purchaseType);
    const { price, currency, localizedPrice } =
      getProductDetails(purchasedItem);

    const shouldAddTargetGrades =
      purchaseType === PaywallPurchaseType.AP_PROGRAM;

    trackAnalyticsEvent(Analytics.coursePurchased, {
      _firebase: true,
      course: programName,
      price,
      currency,
      localizedPrice,
      from: fromEntryPoint,
      purchaseType,
      af_revenue: price,
      af_currency: currency,
      canBeSkipped: getBoolAnalyticsText(params.canBeSkipped ?? false),
      ...(shouldAddTargetGrades && { targetGrades }),
    });
  },
);
