import {
  Notification,
  NotificationBackgroundFetchResult,
  RegistrationError,
} from 'react-native-notifications';

import { MainStack, TestStack } from '../../Common/services/utils/AppConstants';

export type RegisterPushNotificationsListenersProps = {
  onRegistered: (deviceToken: string) => void;
  onRegistrationFailed: (error: RegistrationError) => void;
  onRegistrationDenied: () => void;
  onForegroundNotification: (notification: Notification) => void;
  onBackgroundNotification: (
    notification: Notification,
    completion: (response: NotificationBackgroundFetchResult) => void,
  ) => void;
  onNotificationOpened: (
    notification: Notification,
    completion: () => void,
  ) => void;
};

export type TrackNotificationPermissionPayload = {
  allowed: boolean;
};

export enum PushNotificationEnvironment {
  PRODUCTION = 'production',
  DEVELOPMENT = 'sandbox',
}

export enum NotificationRedirectionsScreen {
  TEST_PREP_TAB = TestStack.TEST_PREP_LIST, // TestPrepList
  START_UNIT_TEST = MainStack.TEST_SCREEN, // MainTestScreen
  PAYWALL = MainStack.PAYWALL_SCREEN,
  AP_RESULTS_SCREEN = 'APResultsScreen',
  SAT_RESULTS_SCREEN = 'SATResultsScreen',
}

export type TestPrepTabNotificationPayload = {
  courseId: string;
};

export type StartUnitTestNotificationPayload = {
  courseId: string;
  unitId: string;
};

export type ShowCoursePaywallNotificationPayload = {
  courseId: string;
};

export enum VideoNotificationType {
  DMS_FROM_DEAD = 'dmsFromDead',
  VIDEO_NOTIFICATION_V3 = 'videoNotificationV3',
}
