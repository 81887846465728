import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  GetUserAPProgramDetailsResponse,
  GetUserSATProgramDetailsResponse,
} from '../../entities';

export const getUserSATProgramDetailsGraphQLCall =
  async (): Promise<GetUserSATProgramDetailsResponse> => {
    const authHeader = (await getAuthHeader()) as any;
    if (Object.keys(authHeader).length > 0) {
      authHeader['x-api-key'] = undefined;
    }

    const response = await graphqlApi.post(
      '',
      {
        query: `
      query TeachTapGetUserSATProgramDetails {
        teachTapGetUserSATProgramDetails {
          details {
            programType
            programName
            durationLength
            durationPeriod
            currentDuration
            examDate
            timeSpentMinutes
            targetTimeCommitmentHours
            targetTimeCommitmentPeriod
            currentScore
            targetScore
            enrolledAt
            
            currentDurationStartDate
            currentDurationEndDate
            
            readingWritingScore
            mathScore
            hasTakenTest
            freePointsOffered
          }
          accessDetails {
            accessType
            expiresAt
            purchaseSource
          }
        }
      }`,
        variables: {},
      },
      {
        headers: {
          ...authHeader,
        },
      },
    );

    handleGraphQLError(response);
    return response.data.data?.teachTapGetUserSATProgramDetails;
  };

export const getUserAPProgramDetailsGraphQLCall =
  async (): Promise<GetUserAPProgramDetailsResponse> => {
    const authHeader = (await getAuthHeader()) as any;
    if (Object.keys(authHeader).length > 0) {
      authHeader['x-api-key'] = undefined;
    }

    const response = await graphqlApi.post(
      '',
      {
        query: `
      query TeachTapGetUserAPProgramDetails {
        teachTapGetUserAPProgramDetails {
          details {
            enrolledAt
            courses {
              id
              examDate
              targetGrade
              
              currentDuration
              currentDurationStartDate
              currentDurationEndDate
              timeSpentMinutes
              targetTimeCommitmentHours
              targetTimeCommitmentPeriod
            }
          }
          accessDetails {
            accessType
            expiresAt
            purchaseSource
          }
        }
      }`,
        variables: {},
      },
      {
        headers: {
          ...authHeader,
        },
      },
    );

    handleGraphQLError(response);
    return response.data.data?.teachTapGetUserAPProgramDetails;
  };
