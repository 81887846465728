import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  AuthUser,
  GetUserFlagsRequest,
  GetUserFlagsResponse,
  GetUsernameAvailabilityRequest,
  GetUsernameAvailabilityResponse,
  GetUserStatsResponse,
  TeachTapUserSettingsResponse,
} from '../../entities';

export const getTeachTapUserSettingsGraphQLCall =
  async (): Promise<TeachTapUserSettingsResponse> => {
    const response = await graphqlApi.post(
      '',
      {
        query: `
        query GetTeachTapUserSettings {
          getTeachTapUserSettings {
            goal
            examType
            emailOptIn
          }
        }`,
      },
      {
        headers: {
          ...(await getAuthHeader()),
          'x-api-key': undefined,
        },
      },
    );
    handleGraphQLError(response);
    return response.data?.data.getTeachTapUserSettings;
  };

export const getUserProfileGraphQLCall = async (
  userId: string,
): Promise<AuthUser> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        userId: userId,
      },
      query: `query GetUserDetails($userId: String!) {
        getUserDetails(userId: $userId) {
          id
          firstName
          lastName
          email
          roleName
          profileImageURL
          coverImageURL
          acceptedTerms
          permissions
          preferredName
          userEnrollments {
            role
            username
            school
          }
          __typename  
        }
      }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data.data.getUserDetails;
};

export const getUserFlagsGraphQLCall = async (
  request: GetUserFlagsRequest,
): Promise<GetUserFlagsResponse> => {
  const response = await graphqlApi.post(
    '',
    {
      query: `
      query TeachTapGetUserFlags($input: TeachTapGetUserFlagsInput!) {
        teachTapGetUserFlags(input: $input) {
          flags {
            key
            value
            metadata
          }
        }
      }`,
      variables: {
        input: request,
      },
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );

  handleGraphQLError(response);
  return response.data.data.teachTapGetUserFlags;
};

export const getUserStatsGraphQLCall =
  async (): Promise<GetUserStatsResponse> => {
    const response = await graphqlApi.post(
      '',
      {
        query: `
        query TeachTapGetUserStats {
          teachTapGetUserStats {
            totalAwardedPoints
            totalCorrectQuestions
          }
        }`,
      },
      {
        headers: {
          ...(await getAuthHeader()),
          'x-api-key': undefined,
        },
      },
    );

    handleGraphQLError(response);
    return response.data.data.teachTapGetUserStats;
  };

export const getUsernameAvailabilityGraphQLCall = async (
  params: GetUsernameAvailabilityRequest,
): Promise<GetUsernameAvailabilityResponse> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: params,
      },
      query: `
        query TeachTapCheckUsernameAvailability($input: TeachTapCheckUsernameAvailabilityInput!) {
          teachTapCheckUsernameAvailability(input: $input) {
            isAvailable
            isAppropriate
          }
        }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );

  handleGraphQLError(response);
  return response.data.data.teachTapCheckUsernameAvailability;
};
