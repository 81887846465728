import { createAsyncThunk } from '@reduxjs/toolkit';

import { setIsAppActive } from '../AppSlice';
import { LIFECYCLE_SLICE_NAME } from '../LifecycleSlice';

export const onAppGoesToBackground = createAsyncThunk(
  `${LIFECYCLE_SLICE_NAME}/onAppGoesToBackground`,
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setIsAppActive(false));
  },
);
