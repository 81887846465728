export enum AppLogRocketSlug {
  DEFAULT = 'teachtales/teachtap',
}

export enum AppInstallerPackageName {
  TESTFLIGHT = 'TestFlight',
  APPSTORE = 'AppStore',
}

export enum AppSchoolName {
  TEACHTAP = 'TeachTap',
}
