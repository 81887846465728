import { createAsyncThunk } from '@reduxjs/toolkit';

import locale from '../../../../App/locale';
import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { submitSATAcademicDataGraphQLCall } from '../../../graphql/mutations/SATResultsGraphQLMutations';
import {
  getSATResultsNextInputsSummary,
  mapSubmitSATResultsAcademicDataRequest,
} from '../../mappers';
import { SAT_RESULTS_SLICE_NAME, setIsDataSubmitted } from '../SATResultsSlice';

export const trackSATResultsNextInputsSubmitted = createAsyncThunk(
  `${SAT_RESULTS_SLICE_NAME}/trackSATResultsNextInputsSubmitted`,
  async (_params, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;

    const nextInputSummary = getSATResultsNextInputsSummary(rootState);

    trackAnalyticsEvent(Analytics.nextExamsDataSubmitted, {
      ...nextInputSummary,
    });

    thunkAPI.dispatch(submitSATResultsAcademicData());
  },
);

export const submitSATResultsAcademicData = createAsyncThunk(
  `${SAT_RESULTS_SLICE_NAME}/submitSATResultsAcademicData`,
  async (_params, thunkAPI) => {
    try {
      const mappedRequest = mapSubmitSATResultsAcademicDataRequest(
        thunkAPI.getState() as RootState,
      );

      await submitSATAcademicDataGraphQLCall(mappedRequest);
      thunkAPI.dispatch(setIsDataSubmitted(true));
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);
