import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { SATResultsEntryPoint } from '../../../entities';
import { SAT_RESULTS_SLICE_NAME } from '../SATResultsSlice';

export const startTrackingSATResultsFlowStarting = createAsyncThunk(
  `${SAT_RESULTS_SLICE_NAME}/startTrackingSATResultsFlowStarting`,
  async (_, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const SATResultsState = rootState.SATResults;

    trackAnalyticsEvent(Analytics.satExamResultFlowStarted, {
      from: SATResultsState.entryPoint || SATResultsEntryPoint.APP_OPEN,
    });
  },
);
