import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { SAT_UNIT_CATEGORY } from '../../../../Learn/entities';
import { mapSatScoreAutoSelection } from '../../mappers';
import { getSATLastScoreValueByKey } from '../../utils';
import {
  ONBOARDING_SLICE_NAME,
  OnboardingSliceState,
  setLastSelectedSATUnitCategory,
} from '../OnboardingSlice';

export const setExamLastScoreKey = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/setExamLastScoreKey`,
  async (lastScoreKey: string, thunkAPI) => {
    if (!lastScoreKey) {
      return {
        skip: true,
        readingWriting: 0,
        math: 0,
      };
    }

    const rootState = thunkAPI.getState() as RootState;
    const state = rootState.onboarding;

    const lastSelectedSATUnitCategory = state.lastSelectedSATUnitCategory;
    const lastSATMathScore = state.lastSATMathScore;
    const lastSATReadingWritingScore = state.lastSATReadingWritingScore;

    const lastScore = getSATLastScoreValueByKey(lastScoreKey);
    if (!lastScore) {
      return {
        skip: true,
        readingWriting: 0,
        math: 0,
      };
    }

    const { math, readingWriting, resetLastSelectedCategory } =
      mapSatScoreAutoSelection(
        lastScore,
        lastSelectedSATUnitCategory,
        lastSelectedSATUnitCategory === SAT_UNIT_CATEGORY.MATH
          ? lastSATMathScore
          : lastSATReadingWritingScore,
      );

    if (resetLastSelectedCategory) {
      thunkAPI.dispatch(setLastSelectedSATUnitCategory());
    }

    return { skip: false, readingWriting, math };
  },
);

export const setExamLastScoreExtraReducers = (
  builder: ActionReducerMapBuilder<OnboardingSliceState>,
): void => {
  builder.addCase(setExamLastScoreKey.fulfilled, (state, action) => {
    const lastScoreKey = action.meta.arg;
    const { skip, readingWriting, math } = action.payload;

    if (skip) {
      return;
    }

    state.examLastScoreKey = lastScoreKey;
    state.lastSATMathScore = math;
    state.lastSATReadingWritingScore = readingWriting;
  });
};
