import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../../App/locale';
import { useAppSelector } from '../../../App/services/hooks';
import { formatNumber } from '../../../Common/services/utils';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { SATLockOverlay } from '../../entities';
import {
  mapSatBootcampBoostedScore,
  mapSatBootcampPointsUntilTargetScore,
  mapSatLockOverlayStatus,
} from '../../services/mappers';

export const SATProgramOverlayDetails = (): React.ReactElement | null => {
  const satLockOverlayStatus = useAppSelector(mapSatLockOverlayStatus);
  const pointsUntilTargetScore = useAppSelector(
    mapSatBootcampPointsUntilTargetScore,
  );
  const boostedPoints = useAppSelector(mapSatBootcampBoostedScore);

  const text = useMemo(() => {
    if (!satLockOverlayStatus) {
      return { title: '', description: '' };
    }

    let selectedTitle = locale.sat.bootcamp_locked_only_points_left;
    let selectedDescription =
      locale.sat.bootcamp_locked_only_points_left_description;

    if (satLockOverlayStatus === SATLockOverlay.TARGET_ACHIEVED) {
      selectedTitle = locale.sat.bootcamp_locked_boost_points_further;
      selectedDescription =
        locale.sat.bootcamp_locked_boost_points_further_description;
    } else if (
      satLockOverlayStatus === SATLockOverlay.TARGET_WITHIN_FREE_POINTS_REACH
    ) {
      selectedTitle = locale.sat.bootcamp_locked_almost_there;
      selectedDescription = locale.sat.bootcamp_locked_almost_there_description;
    }

    return {
      title: selectedTitle.replace(
        '{POINTS}',
        formatNumber(pointsUntilTargetScore),
      ),
      description: selectedDescription.replace(
        '{BOOSTED_POINTS}',
        formatNumber(boostedPoints),
      ),
    };
  }, [satLockOverlayStatus, pointsUntilTargetScore, boostedPoints]);

  if (!satLockOverlayStatus) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Text style={styles.titleText}>{text.title}</Text>
      <Text style={styles.descriptionText}>{text.description}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: Sizes.xsmall,
  },

  titleText: {
    ...Fonts.xlargeBold,
    textAlign: 'center',
  },

  descriptionText: {
    ...Fonts.caption,
    color: Colors.white60,
    textAlign: 'center',
  },
});
