import { configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';

import { AiFigureSlice } from '../../AiFigure/services/slices';
import { APSlice } from '../../AP/services/slices';
import { AppVersionSlice } from '../../AppVersion/services/slices';
import { ApResultsSlice } from '../../ApResults/services/slices';
import { AuthSlice, SignupSlice } from '../../Auth/services/slices';
import { DeepLinkingSlice, SettingsSlice } from '../../Common/services/slices';
import { CourseEnrollmentsSlice } from '../../CourseEnrollment/services/slices';
import {
  CommentsSlice,
  FeedbackFormSlice,
  FeedbackSlice,
} from '../../Feedback/services/slices';
import { LeaderboardSlice } from '../../Leaderboard/services/slices';
import {
  AnswersSlice,
  CoursesSlice,
  FeedSlice,
} from '../../Learn/services/slices';
import { MessagesSlice } from '../../Messages/services/slices';
import NotificationSlice from '../../Notification/services/slices/NotificationSlice';
import {
  ApOnboardingSlice,
  OnboardingSlice,
} from '../../Onboarding/services/slices';
import {
  ApProgramPaywallSlice,
  BootcampPaywallSlice,
} from '../../Paywall/services/slices';
import PurchasesSlice from '../../Paywall/services/slices/PurchasesSlice';
import { PopupSlice } from '../../Popup/services/slices';
import { ProfileSlice } from '../../Profile/services/slices/ProfileSlice';
import {
  RaiseHandSlice,
  StreamingSlice,
} from '../../RaiseHand/services/slices';
import { SATSlice } from '../../SAT/services/slices';
import { SATResultsSlice } from '../../SATResults/services/slices';
import { TestPrepSlice } from '../../TestPrep/services/slices';
import { TutorialSlice } from '../../Tutorial/services/slices';
import { UserAccessSlice } from '../../UserAccess/services/slices';
import { TrackUserEventSlice } from '../../UserEvent/services/slices';
import {
  AppSlice,
  LifecycleSlice,
  NetworkSlice,
  TimerSlice,
} from '../services/slices';

const store = configureStore({
  reducer: {
    app: AppSlice,
    aiFigure: AiFigureSlice,
    appVersion: AppVersionSlice,
    questions: FeedSlice,
    answer: AnswersSlice,
    timer: TimerSlice,
    settings: SettingsSlice,
    courseEnrollments: CourseEnrollmentsSlice,
    auth: AuthSlice,
    feedback: FeedbackSlice,
    lifecycle: LifecycleSlice,
    comments: CommentsSlice,
    popup: PopupSlice,
    signup: SignupSlice,
    courses: CoursesSlice,
    form: FeedbackFormSlice,
    messages: MessagesSlice,
    raiseHand: RaiseHandSlice,
    streaming: StreamingSlice,
    purchases: PurchasesSlice,
    tutorial: TutorialSlice,
    testPrep: TestPrepSlice,
    notification: NotificationSlice,
    trackUserEvent: TrackUserEventSlice,
    onboarding: OnboardingSlice,
    SAT: SATSlice,
    SATResults: SATResultsSlice,
    profile: ProfileSlice,
    deepLinking: DeepLinkingSlice,
    bootcampPaywall: BootcampPaywallSlice,
    apResults: ApResultsSlice,
    network: NetworkSlice,
    apOnboarding: ApOnboardingSlice,
    apProgramPaywall: ApProgramPaywallSlice,
    AP: APSlice,
    userAccess: UserAccessSlice,
    leaderboard: LeaderboardSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        warnAfter: 200,
      },
      immutableCheck: false, //{warnAfter: 200},
    }),
});
persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type AppDispatchHooks = ThunkDispatch<
  RootState,
  undefined,
  Action<string>
>;

export const useAppDispatch = (): AppDispatchHooks =>
  useDispatch<AppDispatch>();

export type AppThunkAPI = BaseThunkAPI<RootState, unknown, AppDispatch>;

export type RootState = ReturnType<typeof store.getState>;
export default store;
