import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import locale from '../../App/locale';
import { SATAssets } from '../assets';
import { SATHistoryDataSummary } from '../entities';

import { SATStatsCategoryScore, SATStatsTotalScore } from './stats';

type Props = {
  historyDataSummary?: SATHistoryDataSummary;
  readingWritingLastExamScore?: number;
  mathLastExamScore?: number;
  totalLastExamScore?: number;
};

export const SATStatsSummary = ({
  historyDataSummary,
  readingWritingLastExamScore,
  mathLastExamScore,
  totalLastExamScore,
}: Props): React.ReactElement => {
  const shouldShowStats = useMemo(
    () => historyDataSummary?.showTotal ?? false,
    [historyDataSummary?.showTotal],
  );

  const shouldShowReadingStats = useMemo(
    () => historyDataSummary?.showReadingWriting ?? false,
    [historyDataSummary?.showReadingWriting],
  );

  const shouldShowMathStats = useMemo(
    () => historyDataSummary?.showMath ?? false,
    [historyDataSummary?.showMath],
  );

  return (
    <View style={styles.container}>
      <SATStatsTotalScore
        shouldShowStats={shouldShowStats}
        score={historyDataSummary?.totalScore}
        maxScore={historyDataSummary?.maxTotalScore}
        unitsIndex={historyDataSummary?.totalUnitsIndex}
        percentile={historyDataSummary?.totalPercentile}
        lastExamScore={totalLastExamScore}
      />

      <View style={styles.categoriesContainer}>
        <View style={styles.categoryContainer}>
          <SATStatsCategoryScore
            shouldShowStats={shouldShowReadingStats}
            imageAsset={SATAssets.writeIcon}
            title={locale.sat.stats_read_write_title}
            unitsIndex={historyDataSummary?.readingWritingUnitsIndex}
            score={historyDataSummary?.readingWritingScore}
            maxScore={historyDataSummary?.maxReadingWritingScore}
            lastExamScore={readingWritingLastExamScore}
          />
        </View>

        <View style={styles.categoryContainer}>
          <SATStatsCategoryScore
            shouldShowStats={shouldShowMathStats}
            imageAsset={SATAssets.mathIcon}
            title={locale.sat.stats_math_title}
            unitsIndex={historyDataSummary?.mathUnitsIndex}
            score={historyDataSummary?.mathScore}
            maxScore={historyDataSummary?.maxMathScore}
            lastExamScore={mathLastExamScore}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    gap: 12,
  },
  categoriesContainer: {
    gap: 12,
    flexDirection: 'row',
  },
  categoryContainer: {
    flex: 1,
  },
});
