import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { Asset } from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import { CommonLinearProgress } from '../../../Common/components';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { SATUnitsIndex } from '../../entities';
import { useSATStatsScore } from '../../services/hooks';

type Props = {
  shouldShowStats: boolean;
  imageAsset: Asset;
  title: string;
  score?: number;
  maxScore?: number;
  unitsIndex?: SATUnitsIndex;
  lastExamScore?: number;
};

export const SATStatsCategoryScore = (props: Props): React.ReactElement => {
  const { shouldShowStats, score, maxScore, unitsIndex, lastExamScore } = props;

  const {
    noScoreTextDescription,
    progressPercentage,
    lastExamScorePercentage,
    getScoreBoostedText,
    isScoreBoosted,
  } = useSATStatsScore({
    score,
    maxScore,
    unitsIndex,
    lastExamScore,
    isTotalScore: false,
  });

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <AssetImage
          asset={props.imageAsset}
          height={Sizes.large}
          containerStyle={styles.icon}
        />

        <Text style={styles.titleText}>{props.title}</Text>
      </View>

      <View style={styles.detailContainer}>
        {!shouldShowStats && (
          <Text style={styles.detailText}>{noScoreTextDescription}</Text>
        )}

        {shouldShowStats && (
          <View>
            <View style={styles.scoreContainer}>
              <Text
                style={[
                  styles.scoreText,
                  isScoreBoosted && styles.scoreTextHighlight,
                ]}>
                {score}
              </Text>
              <Text style={styles.boostScoreText}>{getScoreBoostedText}</Text>
            </View>
            <CommonLinearProgress
              progressPercentage={progressPercentage}
              lastExamScorePercentage={lastExamScorePercentage}
            />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white12,
    padding: Sizes.semiMedium,
    borderRadius: Sizes.semiMedium,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: Sizes.small,
  },
  titleText: {
    ...Fonts.semiMediumBold,
    color: 'white',
  },
  icon: {
    height: Sizes.large,
  },

  detailContainer: {
    marginTop: Sizes.xsmall,
  },
  detailText: {
    ...Fonts.semiMedium,
    color: Colors.white60,
  },

  scoreText: {
    ...Fonts.xxxlargeBold,
    color: Colors.emeraldGreen,
    marginBottom: Sizes.xsmall,
  },
  boostScoreText: {
    ...Fonts.smallBold,
    color: Colors.sliceLightGreen,
    textAlign: 'right',
  },
  scoreContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  scoreTextHighlight: {
    color: Colors.sliceLightGreen,
  },
});
