import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import FastImage from 'react-native-fast-image';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { APProgramExamDate } from '../../../AP/components';
import { mapApProgramCourseDetailsStatusById } from '../../../AP/services/mappers';
import Assets from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import { MainNavigationProp } from '../../../App/navigation/main/MainStackNavigator';
import { useAppSelector } from '../../../App/services/hooks';
import { Course } from '../../../Common/entities';
import { useProgramCourseNameGetter } from '../../../Common/services/hooks';
import { isSATCourse } from '../../../Common/services/utils';
import {
  Colors,
  Fonts,
  ScreenNames,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { useNavigation } from '../../../Common/services/utils/polyfills';
import { SelectedCourseEntryPoint } from '../../../CourseEnrollment/entities';

import { BookmarkButton } from './BookmarkButton';

const COURSE_IMAGE_SIZE = 32;

type Props = {
  course: Course;
  isFromTestPrep?: boolean;
  isFloating?: boolean;
  showFeedbackButton?: boolean;
  hideFollowingText?: boolean;
};

export const CourseFeedHeader = (props: Props): React.ReactElement => {
  const mainNavigation = useNavigation<MainNavigationProp<any>>();

  const { top } = useSafeAreaInsets();
  const courseName = useProgramCourseNameGetter(props.course);
  const isCourseHasApProgram = useAppSelector(state =>
    mapApProgramCourseDetailsStatusById(state, props.course.id),
  );

  const showCoursePicker = useCallback(() => {
    mainNavigation.navigate(ScreenNames.MainStack.COURSE_PICKER_SCREEN, {
      selectedCourse: props.course,
      entryPoint: props?.isFromTestPrep
        ? SelectedCourseEntryPoint.TEST_PREP
        : SelectedCourseEntryPoint.STUDY,
    });
  }, [mainNavigation, props.course, props?.isFromTestPrep]);

  const courseEnrollmentCounts = useAppSelector(
    state => state.courseEnrollments.coursesEnrollmentCount,
  );

  const enrolledCourseCount = useMemo(() => {
    return courseEnrollmentCounts[props.course.id] ?? 0;
  }, [courseEnrollmentCounts, props.course]);

  const followingStatsText = useMemo(() => {
    return locale.study.following_stats.replace(
      '${COUNT}',
      enrolledCourseCount.toLocaleString('en-US'),
    );
  }, [enrolledCourseCount]);

  const containerStyle = useMemo(() => {
    if (props.isFloating) {
      return [styles.containerAbsolute, { top: top }];
    } else {
      return styles.container;
    }
  }, [props.isFloating, top]);

  const isSelectedCourseSAT = useMemo(() => {
    return isSATCourse(props.course);
  }, [props.course]);

  return (
    <View style={containerStyle}>
      <View>
        <View style={styles.courseBookmarkContainer}>
          <TouchableOpacity
            style={styles.courseSelectionContainer}
            onPress={showCoursePicker}>
            <FastImage
              style={styles.courseAvatar}
              source={{ uri: props.course.avatar ?? '' }}
            />
            <Text style={styles.selectedCourse}>{courseName}</Text>
            <AssetImage
              asset={Assets.home.shareMoreArrowIcon}
              height={17}
              fill={Colors.white60}
              containerStyle={styles.arrowContainer}
            />
          </TouchableOpacity>

          {!isSelectedCourseSAT && (
            <BookmarkButton style={styles.bookmarkContainer} />
          )}
        </View>

        {isCourseHasApProgram && (
          <View style={styles.apProgramContainer}>
            <APProgramExamDate courseId={props.course?.id} />
          </View>
        )}
      </View>

      {!props.hideFollowingText && (
        <Text style={styles.followingCourseStats}>{followingStatsText}</Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  containerAbsolute: {
    position: 'absolute',
    width: '100%',
  },
  container: {
    position: 'relative',
    width: '100%',
  },
  courseAvatar: {
    width: COURSE_IMAGE_SIZE,
    height: COURSE_IMAGE_SIZE,
    borderRadius: COURSE_IMAGE_SIZE / 2,
  },
  courseSelectionContainer: {
    borderRadius: 10,
    backgroundColor: Colors.white12,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    padding: Sizes.small,
    marginTop: Sizes.small,
    columnGap: Sizes.small,
  },
  selectedCourse: {
    ...Fonts.mediumBold,
    maxWidth: 216,
  },
  arrowContainer: {
    justifyContent: 'center',
    marginStart: 5,
  },
  followingCourseStats: {
    ...Fonts.small,
    color: Colors.white70,
    marginTop: Sizes.semiMedium,
    alignSelf: 'center',
  },

  apProgramContainer: {
    marginTop: Sizes.semiMedium,
  },
  bookmarkContainer: {
    borderRadius: 10,
    backgroundColor: Colors.white12,
    paddingHorizontal: Sizes.small,
    marginTop: Sizes.xsmall,
    width: Sizes.normalizeIP14PM(48),
    height: Sizes.normalizeIP14PM(48),
    alignItems: 'center',
    alignSelf: 'center',
  },
  courseBookmarkContainer: {
    flexDirection: 'row',
    gap: 4,
    justifyContent: 'center',
  },
});
