import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { LeaderboardUser } from '../../entities';

import { getLeaderboardUserListActionExtraReducers } from './LeaderboardSliceActions/GetLeaderboardUserListAction';

const LEADERBOARD_SLICE_PERSIST_KEY = 'Leaderboard';
export const LEADERBOARD_SLICE_NAME = 'LeaderboardSlice';

export type LeaderboardSliceState = {
  topUsers: LeaderboardUser[];
  userRank?: LeaderboardUser;
  adjacentUsers: LeaderboardUser[];
  totalUsers: number;
  isLoading: boolean;
};

export const LeaderboardSliceInitialState: LeaderboardSliceState = {
  topUsers: [],
  adjacentUsers: [],
  totalUsers: 0,
  userRank: undefined,
  isLoading: false,
};

const persistConfig = {
  key: LEADERBOARD_SLICE_PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: ['topUsers', 'userRank', 'adjacentUsers', 'totalUsers'],
} as PersistConfig<LeaderboardSliceState>;

const leaderboardSlice = createSlice({
  name: LEADERBOARD_SLICE_NAME,
  initialState: LeaderboardSliceInitialState,
  reducers: {
    resetLeaderboardState: state => {
      state.topUsers = [];
      state.userRank = undefined;
      state.adjacentUsers = [];
      state.totalUsers = 0;
      state.isLoading = false;
    },
  },
  extraReducers: builder => {
    getLeaderboardUserListActionExtraReducers(builder);
  },
});

export const { resetLeaderboardState } = leaderboardSlice.actions;

export const LeaderboardSlice = persistReducer(
  persistConfig,
  leaderboardSlice.reducer,
);
