import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { ExamResultsYesNoEnum } from '../../../Common/entities';
import { SATLastScoreKey } from '../../../Onboarding/entities';
import { SATResultsEntryPoint } from '../../entities/SATResultsAnalyticsTypes';

export const SAT_RESULTS_SLICE_NAME = 'SATResultsSlice';

export type SATResultsSliceState = {
  flowExitCount: number;
  isFlowClosed: boolean;

  openSATResultsFlag: boolean;
  entryPoint: SATResultsEntryPoint;

  lastSATCurrentScore?: number;
  lastSATTargetScore?: number;
  lastSATExamDate?: string;

  isUserHappyWithSATResults?: boolean;
  examResultKey: string;

  planToRetakeSAT?: ExamResultsYesNoEnum;
  planToTakeAPNextYearMay?: ExamResultsYesNoEnum;
  retakeSATExamDate?: string;
  retakeSATTargetScore?: number;

  isDataSubmitted: boolean;
  isFeedbackSubmitted: boolean;

  // For tracking if the user has completed the SAT Results flow
  isSATResultsFlowCompleted: boolean;

  satResultsFeedback: string;
  zoomChatConsent?: ExamResultsYesNoEnum;
};

const persistConfig = {
  key: SAT_RESULTS_SLICE_NAME,
  storage: AsyncStorage,
  whitelist: [
    'flowExitCount',
    'openSATResultsFlag',
    'lastSATTargetScore',
    'lastSATCurrentScore',
    'lastSATExamDate',
    'isUserHappyWithSATResults',
    'examResultKey',
    'planToRetakeSAT',
    'planToTakeAPNextYearMay',
    'retakeSATExamDate',
    'retakeSATTargetScore',
    'isDataSubmitted',
    'satResultsFeedback',
    'zoomChatConsent',
    'isFeedbackSubmitted',
    'isSATResultsFlowCompleted',
  ],
  blacklist: ['entryPoint'],
} as PersistConfig<SATResultsSliceState>;

const initialState: SATResultsSliceState = {
  flowExitCount: 0,
  isFlowClosed: false,

  openSATResultsFlag: false,
  entryPoint: SATResultsEntryPoint.APP_OPEN,

  lastSATCurrentScore: undefined,
  lastSATTargetScore: undefined,
  lastSATExamDate: undefined,

  isUserHappyWithSATResults: undefined,
  examResultKey: SATLastScoreKey.NOT_TAKEN,

  planToRetakeSAT: undefined,
  planToTakeAPNextYearMay: undefined,
  retakeSATExamDate: undefined,
  retakeSATTargetScore: undefined,

  isDataSubmitted: false,
  isSATResultsFlowCompleted: false,

  satResultsFeedback: '',
  zoomChatConsent: undefined,
  isFeedbackSubmitted: false,
};

const slice = createSlice({
  name: SAT_RESULTS_SLICE_NAME,
  initialState: initialState,
  reducers: {
    resetSATResultsSliceState: () => {
      return initialState;
    },
    setOpenSATResultsFlag: (state, action: PayloadAction<boolean>) => {
      state.openSATResultsFlag = action.payload;
    },
    incrementSATResultsExitCount: state => {
      state.flowExitCount += 1;
    },

    setIsFlowClosed: (state, action: PayloadAction<boolean>) => {
      state.isFlowClosed = action.payload;
    },
    setSATResultsEntryPoint: (
      state,
      action: PayloadAction<SATResultsEntryPoint>,
    ) => {
      state.entryPoint = action.payload;
    },
    setLastSATTargetScore: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.lastSATTargetScore = action.payload;
    },
    setLastSATCurrentScore: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.lastSATCurrentScore = action.payload;
    },
    setLastSATExamDate: (state, action: PayloadAction<string | undefined>) => {
      state.lastSATExamDate = action.payload;
    },
    setIsUserHappyWithSATResults: (state, action: PayloadAction<boolean>) => {
      state.isUserHappyWithSATResults = action.payload;
    },
    setExamResultKey: (state, action: PayloadAction<string>) => {
      state.examResultKey = action.payload;
    },
    setPlanToRetakeSAT: (
      state,
      action: PayloadAction<ExamResultsYesNoEnum | undefined>,
    ) => {
      state.planToRetakeSAT = action.payload;
    },
    setPlanToTakeAPNextYearMay: (
      state,
      action: PayloadAction<ExamResultsYesNoEnum | undefined>,
    ) => {
      state.planToTakeAPNextYearMay = action.payload;
    },
    setRetakeSATDate: (state, action: PayloadAction<string | undefined>) => {
      state.retakeSATExamDate = action.payload;
    },
    setRetakeSATTargetScore: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.retakeSATTargetScore = action.payload;
    },
    setIsDataSubmitted: (state, action: PayloadAction<boolean>) => {
      state.isDataSubmitted = action.payload;
    },
    setSATResultsFeedback: (state, action: PayloadAction<string>) => {
      state.satResultsFeedback = action.payload;
    },
    setSATZoomChatConsent: (
      state,
      action: PayloadAction<ExamResultsYesNoEnum>,
    ) => {
      state.zoomChatConsent = action.payload;
    },
    setSATIsFeedbackSubmitted: (state, action: PayloadAction<boolean>) => {
      state.isFeedbackSubmitted = action.payload;
    },
    setIsSATResultsFlowCompleted: (state, action: PayloadAction<boolean>) => {
      state.isSATResultsFlowCompleted = action.payload;
    },
  },
});

export const {
  incrementSATResultsExitCount,
  setIsFlowClosed,
  resetSATResultsSliceState,
  setOpenSATResultsFlag,
  setSATResultsEntryPoint,
  setLastSATTargetScore,
  setLastSATCurrentScore,
  setLastSATExamDate,
  setIsUserHappyWithSATResults,
  setExamResultKey,
  setPlanToRetakeSAT,
  setPlanToTakeAPNextYearMay,
  setRetakeSATDate,
  setRetakeSATTargetScore,
  setIsDataSubmitted,
  setSATResultsFeedback,
  setSATZoomChatConsent,
  setSATIsFeedbackSubmitted,
  setIsSATResultsFlowCompleted,
} = slice.actions;

export const SATResultsSlice = persistReducer(persistConfig, slice.reducer);
