import React from 'react';
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

import Assets from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import { MainNavigationProp } from '../../../App/navigation/main/MainStackNavigator';
import { MainStack } from '../../../Common/services/utils/AppConstants';
import { useNavigation } from '../../../Common/services/utils/polyfills';

type Props = {
  style?: StyleProp<ViewStyle>;
};

export const BookmarkButton = (props: Props): React.ReactElement => {
  const mainNavigation = useNavigation<MainNavigationProp<any>>();

  const onPress = () => {
    mainNavigation.navigate(MainStack.BOOKMARKS_SCREEN);
  };

  return (
    <TouchableOpacity
      style={[styles.bookmarkButton, props.style]}
      onPress={onPress}>
      <AssetImage asset={Assets.favoritesTabIcon} height={24} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  bookmarkButton: {
    justifyContent: 'center',
  },
});
