import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { SATAssets } from '../../assets';

export const SATProgramOverlayHeader = (): React.ReactElement => {
  return (
    <View style={styles.headerContainer}>
      <View style={styles.lockIconContainer}>
        <AssetImage width={Sizes.xlarge} asset={SATAssets.lockIcon} />
      </View>

      <Text style={styles.titleText}>{locale.sat.bootcamp_locked}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    gap: Sizes.xsmall,
  },

  lockIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },

  titleText: {
    ...Fonts.captionBold,
    color: Colors.actionGreen,
    textAlign: 'center',
  },
});
