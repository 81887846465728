import React, { useCallback, useMemo } from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native';

import locale from '../../../App/locale';
import { ExamResultsYesNoEnum } from '../../entities';
import { Colors, Fonts, Sizes } from '../../services/utils/AppConstants';

type Props = {
  onValueChange: (value: ExamResultsYesNoEnum) => void;
  label: string;
  value?: ExamResultsYesNoEnum;
  labelStyle?: TextStyle;
};

export const ExamResultsYesNoQuestion = ({
  onValueChange,
  label,
  value,
  labelStyle,
}: Props): React.ReactElement => {
  const handlePressButton = useCallback(
    (value: ExamResultsYesNoEnum) => {
      if (onValueChange) {
        onValueChange(value);
      }
    },
    [onValueChange],
  );

  const textLabelStyle = useMemo(() => {
    return [styles.text, labelStyle];
  }, [labelStyle]);

  const buttonStyles = useMemo(() => {
    return {
      yes: [
        styles.button,
        value === ExamResultsYesNoEnum.YES ? styles.buttonActive : {},
      ],
      no: [
        styles.button,
        value === ExamResultsYesNoEnum.NO ? styles.buttonActive : {},
      ],
    };
  }, [value]);

  const buttonTextStyles = useMemo(() => {
    return {
      yes: [
        styles.buttonText,
        value === ExamResultsYesNoEnum.YES ? styles.buttonTextActive : {},
      ],
      no: [
        styles.buttonText,
        value === ExamResultsYesNoEnum.NO ? styles.buttonTextActive : {},
      ],
    };
  }, [value]);

  return (
    <View>
      <Text style={textLabelStyle}>{label}</Text>

      <View style={styles.buttonContainer}>
        <TouchableOpacity
          style={buttonStyles.yes}
          onPress={() => handlePressButton(ExamResultsYesNoEnum.YES)}>
          <Text style={buttonTextStyles.yes}>{locale.common.yes}</Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={buttonStyles.no}
          onPress={() => handlePressButton(ExamResultsYesNoEnum.NO)}>
          <Text style={buttonTextStyles.no}>{locale.common.no}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    ...Fonts.semiLarge,
    color: Colors.white70,
    textAlign: 'center',
  },

  buttonContainer: {
    marginTop: Sizes.small,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    gap: Sizes.small,
  },

  button: {
    backgroundColor: Colors.white10,
    flex: 1,
    borderWidth: 1,
    borderRadius: Sizes.small,
    borderColor: Colors.white12,
    paddingVertical: Sizes.semiMedium,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonActive: {
    backgroundColor: Colors.actionGreen30,
  },

  buttonText: {
    ...Fonts.medium,
    color: Colors.white70,
    textAlign: 'center',
  },
  buttonTextActive: {
    color: 'white',
  },
});
