import { createAsyncThunk } from '@reduxjs/toolkit';

import { CommonExamType } from '../../../../Common/entities';
import {
  MainStack,
  OnboardingStack,
  SATResultsStack,
} from '../../../../Common/services/utils/AppConstants';
import { getPaywallEntryPointFromBootcamp } from '../../../../CourseEnrollment/services/utils';
import { SATResultsEntryPoint } from '../../../../SATResults/entities/SATResultsAnalyticsTypes';
import { AppNavigatorTarget } from '../../../entities';
import { RootNavigatorRef } from '../../../navigation/RootNavigator';
import { RootState } from '../../../store';
import { APP_SLICE_NAME } from '../AppSlice';

export const doMainAppNavigation = createAsyncThunk(
  `${APP_SLICE_NAME}/doMainAppNavigation`,
  async (mainNavigatorTarget: AppNavigatorTarget, thunkAPI) => {
    const navigation = RootNavigatorRef.current;
    if (!navigation?.isReady() || !mainNavigatorTarget) {
      return;
    }

    const rootState = thunkAPI.getState() as RootState;
    const examType = rootState.onboarding.examType;

    if (mainNavigatorTarget === AppNavigatorTarget.USERNAME_PICTURE_SCREEN) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: MainStack.ONBOARDING_STACK,
            params: {
              screen: OnboardingStack.USERNAME_AND_PICTURE,
              params: {
                examType: examType || CommonExamType.SAT,
              },
            },
          },
        ],
      });
      return;
    }

    if (mainNavigatorTarget === AppNavigatorTarget.ONBOARDING_AUTH) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: MainStack.ONBOARDING_STACK,
            params: {
              screen: OnboardingStack.EXAM_SELECTION,
            },
          },
        ],
      });

      return;
    }

    if (
      mainNavigatorTarget ===
      AppNavigatorTarget.BOOTCAMP_ENTITLEMENT_AFTER_SIGNUP
    ) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: MainStack.BOOTCAMP_ENTITLEMENT_SCREEN,
          },
        ],
      });
      return;
    }

    if (mainNavigatorTarget === AppNavigatorTarget.NOTIFICATION_REQUEST) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: MainStack.NOTIFICATION_REQUEST_SCREEN,
          },
        ],
      });
      return;
    }

    if (mainNavigatorTarget === AppNavigatorTarget.SAT_BOOTCAMP_PAYWALL) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: MainStack.BOOTCAMP_PAYWALL_SCREEN,
            params: {
              entryPoint: getPaywallEntryPointFromBootcamp(undefined, true),
            },
          },
        ],
      });
      return;
    }

    if (mainNavigatorTarget === AppNavigatorTarget.SAT_BOOTCAMP_FREE_ACCESS) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: MainStack.ONBOARDING_STACK,
            params: {
              screen: OnboardingStack.SAT_FREE_ACCESS,
            },
          },
        ],
      });
      return;
    }

    if (
      mainNavigatorTarget ===
      AppNavigatorTarget.SAT_BOOTCAMP_CATEGORY_SCORE_INPUT
    ) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: MainStack.ONBOARDING_STACK,
            params: {
              screen: OnboardingStack.SAT_CATEGORY_SCORE_INPUT_SCREEN,
            },
          },
        ],
      });
      return;
    }

    if (mainNavigatorTarget === AppNavigatorTarget.SAT_RESULTS_LANDING_SCREEN) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: MainStack.SAT_RESULTS_STACK,
            params: {
              screen: SATResultsStack.SAT_RESULTS_LANDING_SCREEN,
              params: {
                entryPoint:
                  rootState.SATResults.entryPoint ||
                  SATResultsEntryPoint.APP_OPEN,
              },
            },
          },
        ],
      });
      return;
    }
  },
);
