import { CommonTrueFalse, ExamResultsYesNoEnum } from '../../Common/entities';

export enum SATResultsEntryPoint {
  APP_OPEN = 'appOpen',
  PUSH_NOTIFICATION = 'pushNotification',
}

export type SATExamResultsSubmittedType = {
  examResults?: number;
  isHappyWithResults?: CommonTrueFalse;
  examDate: string;
  lastSATExamScore: number;
  targetSATExamScore: number;
};

export type SATResultsNextInputsSummary = {
  takingSAT: ExamResultsYesNoEnum;
  satDate?: string;
  takingApNextYear?: ExamResultsYesNoEnum;
  satTargetScore?: number;
};
