export type ShowFlashMessageParams = {
  message: string;
  description?: string;
  duration?: number;
  autoHide?: boolean;
};

export enum CommonTrueFalse {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}
