import { ExamResultsYesNoEnum } from '../../../Common/entities';
import {
  ApResultsAcademicDataToSubmitParams,
  ApResultsScoreEnum,
  SubmitApAcademicDataApExamResult,
  SubmitApAcademicDataRequest,
} from '../../entities';

export const mapApResultsAcademicDataToSubmit = (
  params: ApResultsAcademicDataToSubmitParams,
): SubmitApAcademicDataRequest => {
  const {
    examResults,
    isHappyWithResults,
    apCoursesToTakeNextYear,
    planTakeSat,
    satExamDate,
    courses,
    planApNextYear,
  } = params;

  const apExamResults = Object.keys(examResults ?? {})
    .map(courseName => {
      const course = courses.find(course => course.name === courseName);
      if (!course) {
        return null;
      }

      const score =
        examResults[courseName] !== ApResultsScoreEnum.NO_TEST
          ? parseInt(examResults[courseName] as string, 10)
          : undefined;

      const didNotTake = examResults[courseName] === ApResultsScoreEnum.NO_TEST;

      let isSatisfied: boolean | undefined;
      if (isHappyWithResults[courseName]) {
        isSatisfied =
          isHappyWithResults[courseName] === ExamResultsYesNoEnum.YES;
      }

      return {
        courseId: course.id,
        score: score,
        isSatisfied,
        didNotTake,
      };
    }, [])
    .filter(Boolean) as SubmitApAcademicDataApExamResult[];

  const plannedApCourses =
    planApNextYear === ExamResultsYesNoEnum.YES ? apCoursesToTakeNextYear : [];
  const planningSat = planTakeSat === ExamResultsYesNoEnum.YES;
  const plannedSatExamDate = planningSat ? satExamDate : undefined;

  return {
    academicYear: new Date().getFullYear(),
    apExamResults: apExamResults || [],
    futureExamPlan: {
      plannedApCourses: plannedApCourses,
      planningSat: planningSat,
      satExamDate: plannedSatExamDate,
    },
  };
};
