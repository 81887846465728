import React, { useCallback, useMemo, useState } from 'react';
import {
  LayoutChangeEvent,
  ScrollView,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import { useSafeAreaCustomInsets } from '../../services/hooks';
import { Colors, Sizes } from '../../services/utils/AppConstants';
import { LinearGradient } from '../../services/utils/polyfills';

type Props = {
  children: React.ReactNode;
  noTopPadding?: boolean;
  bottomElement?: React.ReactNode;
  hexColor?: string;
  childrenStyle?: StyleProp<ViewStyle>;
};

export const ExamResultsScreenGradientContainer = (
  props: Props,
): React.ReactElement => {
  const { safeAreaBottom, safeAreaTop } = useSafeAreaCustomInsets();
  const [bottomSectionHeight, setBottomSectionHeight] = useState(0);

  const handleBottomSectionLayout = useCallback((event: LayoutChangeEvent) => {
    setBottomSectionHeight(event.nativeEvent.layout.height);
  }, []);

  const linearGradientColors = useMemo(() => {
    if (props.hexColor) {
      return [`${props.hexColor}00`, `${props.hexColor}FF`];
    }

    return Colors.topAlphaGradientStrongInvert;
  }, [props.hexColor]);

  const scrollViewStyle = useMemo(() => {
    if (props.noTopPadding) {
      return styles.scrollViewContainer;
    }

    return [
      styles.scrollViewContainer,
      {
        paddingTop: safeAreaTop + Sizes.semiMedium,
      },
    ];
  }, [safeAreaTop, props.noTopPadding]);
  const scrollViewContentContainerStyle = useMemo(() => {
    if (props.noTopPadding) {
      return {
        paddingBottom: bottomSectionHeight + Sizes.medium,
      };
    }

    return {
      paddingBottom: bottomSectionHeight + safeAreaBottom + 48,
      ...(props.childrenStyle as ViewStyle),
    };
  }, [
    props.noTopPadding,
    props.childrenStyle,
    bottomSectionHeight,
    safeAreaBottom,
  ]);

  const linearGradientStyle = useMemo(() => {
    return [
      styles.linearGradientContainer,
      { paddingBottom: safeAreaBottom + Sizes.small },
    ];
  }, [safeAreaBottom]);

  return (
    <View style={styles.container}>
      <ScrollView
        bounces={false}
        showsVerticalScrollIndicator={false}
        style={scrollViewStyle}
        contentContainerStyle={scrollViewContentContainerStyle}>
        {props.children}
      </ScrollView>

      <View
        style={styles.screenGradientContainer}
        onLayout={handleBottomSectionLayout}>
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 0, y: 0.4 }}
          colors={linearGradientColors}
          style={linearGradientStyle}>
          <View style={styles.bottomActionContainer}>
            {props.bottomElement}
          </View>
        </LinearGradient>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.prussianBlue,
  },

  scrollViewContainer: {
    flex: 1,
    paddingHorizontal: Sizes.medium,
  },

  screenGradientContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 20,
  },
  linearGradientContainer: {
    paddingTop: 36,
    paddingHorizontal: Sizes.medium,
    width: '100%',
  },

  bottomActionContainer: {
    width: '100%',
  },
});
