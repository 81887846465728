import { isDevelopmentEnv } from '../../../Common/services/utils';
import { SAT_UNIT_CATEGORY } from '../../../Learn/entities';
import {
  TestPrepOption,
  TestPrepQuestionDuration,
  TestType,
} from '../../entities';

export const getTestDurationMultiplier = (
  testType?: TestType,
  unitCategory?: string | null,
): number => {
  if (testType === TestType.SAT_TEST) {
    return unitCategory === SAT_UNIT_CATEGORY.MATH
      ? TestPrepQuestionDuration.SAT_MATH
      : TestPrepQuestionDuration.SAT_READING;
  }

  return TestPrepQuestionDuration.DEFAULT;
};

export const roundToNearestTenth = (score: number): number => {
  return Math.round(score / 10) * 10;
};

export const printTestPrepAnswerFromQuestionScreen = (
  options?: TestPrepOption[],
  enable = false,
): void => {
  if (!options?.length || !isDevelopmentEnv() || !enable) {
    return;
  }

  const correctOption = options.find(option => option.correct);
  if (!correctOption) {
    return;
  }

  console.log('--- ---');
  console.log(`Correct Answer: ${correctOption.id}. ${correctOption.answer}`);
  console.log('--- ---');
};
