import { createAsyncThunk } from '@reduxjs/toolkit';

import { IsShowSATResultsScreenFlagMetadata } from '../../../entities';
import {
  SAT_RESULTS_SLICE_NAME,
  setLastSATCurrentScore,
  setLastSATExamDate,
  setLastSATTargetScore,
} from '../SATResultsSlice';

export const assignSATLastExamMetadata = createAsyncThunk(
  `${SAT_RESULTS_SLICE_NAME}/assignSATLastExamMetadata`,
  async (payload: string, thunkAPI) => {
    try {
      if (!payload) {
        return;
      }

      const data = JSON.parse(payload) as IsShowSATResultsScreenFlagMetadata;

      thunkAPI.dispatch(setLastSATTargetScore(data?.targetScore));
      thunkAPI.dispatch(setLastSATExamDate(data?.examDate));
      thunkAPI.dispatch(setLastSATCurrentScore(data?.currentScore));
    } catch (e) {}
  },
);
