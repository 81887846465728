import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Linking } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { PersistConfig, persistReducer } from 'redux-persist';

import locale from '../../../App/locale';
import { RootNavigatorRef } from '../../../App/navigation/RootNavigator';
import {
  handleNetworkActionError,
  handleNetworkActionErrorSilently,
} from '../../../App/services/utils';
import { RootState } from '../../../App/store';
import { Course, Unit } from '../../../Common/entities';
import { CommonAwardPointsActivityType } from '../../../Common/entities/CommonAwardPointsRequestTypes';
import {
  mapAwardPointsAttributesRequest,
  mapDataToUnitTestQuestionViewedPayload,
  mapDataToUnitTestSubmitAnswerPayload,
} from '../../../Common/services/mappers';
import {
  checkIfShouldSkipActionWithCoolDown,
  isSATCourse,
  logger,
  trackAnalyticsEvent,
  updateUserProperties,
} from '../../../Common/services/utils';
import {
  Analytics,
  Durations,
} from '../../../Common/services/utils/AppConstants';
import { RecordMasteryAction } from '../../../Learn/entities';
import { recordContentViewGraphQLCall } from '../../../Learn/graphql';
import {
  incrementAwardedPoints,
  incrementCorrectAnswers,
  setTotalAwardedPoints,
} from '../../../Profile/services/slices';
import {
  SATHistoryData,
  SATHistoryDataSummary,
  SATUserPercentileData,
} from '../../../SAT/entities';
import { getSATUnitTestsHistoryGraphQLCall } from '../../../SAT/graphql';
import {
  getSATBootcampCourseName,
  mapSATBaselineSummaryForAnalytics,
  mapSATBootcampForAnalytics,
  mapSATHighestUnitTestHistoryScore,
  mapSATHistoryResponseSummary,
  mapSATHistoryResponseSummaryWithBaselineScore,
  mapSATHistorySummary,
  mapSATMaxScoresFromRawHistory,
  mapSATSummaryForUserProperties,
} from '../../../SAT/services/mappers';
import { getUserSATProgramDetails } from '../../../SAT/services/slices';
import {
  AssignBestScoreToCurrentTest,
  AttemptsHistory,
  GetGeneratorExternalUrlParams,
  GetSATUserPercentilePayload,
  GetTestPrepHistoryParams,
  SetAnswerChoosenForTheCurrentTestPayload,
  SetChatPanelOpenedStatusParams,
  SetPreGeneratedMessagesParams,
  StartNewTestParams,
  SubmitTestQuestionAnswerParams,
  SubmitUnitTestQuestionRequest,
  TestAttempt,
  TestPrepData,
  TestPrepEntryPoint,
  TestPrepQuestionEntryPoint,
  TestScopeType,
  TestType,
  TrackTestPrepTabOpenedPayload,
  TrackTutorPanelPressAction,
  UnitTargetedPractice,
} from '../../entities';
import {
  awardPointsGraphQLCall,
  createUnitTestGraphQLCall,
  getGeneratorExternalUrlGraphQLCall,
  getTestScorePercentileGraphQLCall,
  getUnitTestsHistoryGraphQLCall,
  recordPracticeAnswerGraphQLCall,
  sendUserEmailGraphQLCall,
  submitUnitTestQuestionGraphQLCall,
} from '../../graphql';
import { mapTestPrepShareFrqEmailPayload } from '../mappers/TestPrepAnalyticsMappers';
import {
  mapFrqLinkToSendUserEmailRequest,
  mapUnitTestResponseToTestPrepData,
} from '../mappers/TestPrepRequestMappers';
import { redirectToMilestoneAchievedFromTestPrep } from '../utils';
import { submitTestResultsExtraReducers } from './TestPrepSliceActions';

export const TEST_PREP_SLICE_NAME = 'TestPrepSlice';
export const BONUS_POINTS_FOR_TEST_PRACTICE = 200;
const TEST_PREP_HISTORY_COOLDOWN = 60 * 60 * 1000; // 1 hour
const START_NEW_TEST_COOLDOWN = 60 * 60 * 24 * 1000; // 24 hours
const FRQ_TEST_COOLDOWN = 60 * 60 * 1000; // 1 hour

const PERSIST_KEY = 'testPrep';

export type TestPrepSliceState = {
  attemptsHistory: Record<string, AttemptsHistory>;
  bestAttempts: Record<string, TestAttempt>;
  targetedPractices: Record<string, UnitTargetedPractice>;

  SATUserPercentile: SATUserPercentileData;
  SATHistory: SATHistoryData;

  isLoading: boolean;
  frqLoading: Record<string, boolean>;

  timerPaused: boolean;
  pregeneratedMessages: string[];
  mathPregeneratedMessages: string[];
  pointsAwardedForPractice: number | null;
  firstTestCompleted: boolean;

  currentTest?: TestPrepData;

  // {[unitId]: TestPrepData}
  currentTestPerUnit: Record<string, TestPrepData>;
  // {[unitId]: string}
  currentTestLastFetchedOn: Record<string, string>;
  currentTestFetchLoading: Record<string, boolean>;

  // {[courseId]: lastFetchedOn - ISO string}
  getUnitTestHistoryLastFetchedOn: Record<string, string>;

  // {[courseId]: boolean}
  getUnitTestHistoryLoading: Record<string, boolean>;

  testPrepEntryPoint?: TestPrepEntryPoint;

  // {[questionId]: boolean}
  chatPanelOpenedStatus: Record<string, boolean>;

  queuedAnswers: Array<SubmitUnitTestQuestionRequest>;
};

const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: [
    'firstTestCompleted',
    'getUnitTestHistoryLastFetchedOn',
    'attemptsHistory',
    'bestAttempts',
    'currentTestPerUnit',
    'currentTestLastFetchedOn',
    'targetedPractices',
    'SATHistory',
    'SATUserPercentile',
    'queuedAnswers',
  ],
} as PersistConfig<TestPrepSliceState>;

const initialState: TestPrepSliceState = {
  attemptsHistory: {},
  bestAttempts: {},
  currentTest: undefined,
  isLoading: false,
  frqLoading: {},
  timerPaused: false,
  pregeneratedMessages: [],
  mathPregeneratedMessages: [],
  targetedPractices: {},
  pointsAwardedForPractice: null,
  firstTestCompleted: false,
  SATHistory: {},
  SATUserPercentile: {},

  currentTestPerUnit: {},
  currentTestLastFetchedOn: {},
  currentTestFetchLoading: {},

  getUnitTestHistoryLastFetchedOn: {},
  getUnitTestHistoryLoading: {},

  // Default to app-open, because test-prep tab is opened by default when we open the app
  testPrepEntryPoint: TestPrepEntryPoint.APP_OPEN,

  chatPanelOpenedStatus: {},

  queuedAnswers: [],
};

export type RecordPracticeMasteryAction = {
  testId: string;
} & RecordMasteryAction;

type AwardPracticePointsAction = {
  course: Course;
  unit: Unit;
};

export const trackTestPrepTabOpened = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/trackTestPrepTabOpened`,
  async (payload: TrackTestPrepTabOpenedPayload, thunkApi) => {
    const { course } = payload;
    const rootState = thunkApi.getState() as RootState;

    const testPrepState = rootState.testPrep;
    const selectedEntryPoint =
      testPrepState.testPrepEntryPoint || TestPrepEntryPoint.NAVBAR;

    const shouldMapSATSummary = isSATCourse(course);
    const satScore = getSATCalculatedScore(rootState, course?.id);
    const satHistorySummary = shouldMapSATSummary
      ? {
          SATReadingAndWritingScore: satScore?.readingWritingScore || null,
          SATMathScore: satScore?.mathScore || null,
          SATTotalScore: satScore?.totalScore || null,
        }
      : {};

    const satState = rootState.SAT;
    const shouldMapSATBootcampData =
      Boolean(satState.bootcamp?.details) && isSATCourse(course);
    const satBootcampData = shouldMapSATBootcampData
      ? mapSATBootcampForAnalytics(satState.bootcamp)
      : {};

    const courseName = shouldMapSATBootcampData
      ? getSATBootcampCourseName(rootState)
      : course?.name;

    trackAnalyticsEvent(Analytics.testPrepTabOpened, {
      from: selectedEntryPoint,
      course: courseName,
      ...satHistorySummary,
      ...satBootcampData,
    });

    const satScoreSummary = shouldMapSATSummary
      ? {
          ...mapSATSummaryForUserProperties(satScore),
          ...mapSATBaselineSummaryForAnalytics(rootState),
        }
      : {};

    if (shouldMapSATSummary) {
      updateUserProperties(satScoreSummary);
    }

    thunkApi.dispatch(setTestPrepEntryPoint(undefined));
  },
);

export const awardPracticePoints = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/awardPracticePoints`,
  async (action: AwardPracticePointsAction, thunkApi) => {
    try {
      const result = await awardPointsGraphQLCall({
        attributes: mapAwardPointsAttributesRequest({
          subjectId: action.course.subjectId,
          courseId: action.course.id,
          unitId: action.unit.id,
        }),
        activityType: CommonAwardPointsActivityType.TARGETED_PRACTICE_FINISHED,
      });
      thunkApi.dispatch(setTotalAwardedPoints(result.newTotalPoints));

      return result;
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

type GetTestScorePercentileAction = {
  unit: Unit;
  maxScore: number;
  score: number;
};

export const getTestScorePercentile = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/getTestScorePercentile`,
  async (payload: GetTestScorePercentileAction, thunkApi) => {
    try {
      return await getTestScorePercentileGraphQLCall({
        score: payload.score,
        testType: TestType.SAT_TEST,
        scope: payload.unit.category!,
        maxScore: payload.maxScore,
      });
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const recordPracticeMastery = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/recordPracticeMastery`,
  async (action: RecordPracticeMasteryAction, thunkApi) => {
    if (
      !action.card.levelId ||
      !action.card.subjectId ||
      !action.card.unit ||
      !action.card.topic
    ) {
      return thunkApi.rejectWithValue(locale.errors.unknown_error);
    }

    let state = thunkApi.getState() as RootState;
    let course = state.courses.coursesMap[action.card.course.id];

    try {
      const response = await recordPracticeAnswerGraphQLCall({
        course: {
          id: course.id,
          name: course.name,
        },
        topic: {
          id: action.card.topic.id,
          name: action.card.topic.name,
        },
        unit: {
          id: action.card.unit.id,
          name: action.card.unit.name,
        },
        subject: {
          id: course.subjectId,
          name: course.subject,
        },
        levelId: action.card.levelId,
        activity: {
          id: action.card.generatedContentId,
          correct: action.correct,
          shownAt: action.shownAt,
          helpShownAt: action.helpShownAt,
          answeredAt: action.answeredAt,
          explanationShownAt: action.explanationShownAt,
          finishedReadingExplanationAt: action.finishedReadingExplanationAt,
          numberOfAttempts: action.numberOfAttempts,
          pointsAwarded: action.pointsAwarded,
        },
        testId: action.testId,
      });

      thunkApi.dispatch(
        setTestProgress({
          unit: action.card.unit,
          progress: response.targetedPracticeProgress,
        }),
      );

      if (response.targetedPracticeProgress >= 100) {
        thunkApi.dispatch(
          awardPracticePoints({
            course: action.card.course,
            unit: action.card.unit,
          }),
        );

        thunkApi.dispatch(
          disableTestPractice({
            unit: action.card.unit,
          }),
        );

        redirectToMilestoneAchievedFromTestPrep({
          course,
          unit: action.card.unit,
        });
      }

      if (action.correct) {
        thunkApi.dispatch(incrementAwardedPoints(action.pointsAwarded ?? 0));
        thunkApi.dispatch(incrementCorrectAnswers(1));
      }

      return response;
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(e);
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const shareFrqEmailLinkAndOpenBrowser = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/shareFrqEmailLink`,
  async (_, thunkApi) => {
    const store = thunkApi.getState() as RootState;

    const currentTest = store.testPrep?.currentTest;
    const loggedInUser = store.auth?.authUser;

    if (!currentTest?.externalUrl) {
      return;
    }

    trackAnalyticsEvent(
      Analytics.frqShareEmailRequest,
      mapTestPrepShareFrqEmailPayload(
        currentTest?.course,
        currentTest?.unit,
        currentTest?.externalUrl,
        currentTest?.id,
      ),
    );

    const request = mapFrqLinkToSendUserEmailRequest(
      currentTest?.externalUrl || '',
      currentTest?.course?.id || '',
      currentTest?.unit?.id || '',
    );

    try {
      await sendUserEmailGraphQLCall(request);

      trackAnalyticsEvent(
        Analytics.frqShareEmail,
        mapTestPrepShareFrqEmailPayload(
          currentTest?.course,
          currentTest?.unit,
          currentTest?.externalUrl,
          currentTest?.id,
        ),
      );

      showMessage({
        duration: Durations.longNotificationDelay,
        message: locale.testPrep.frq_email_share_success.replace(
          '${EMAIL}',
          loggedInUser?.email ?? '',
        ),
      });

      Linking.openURL(currentTest.externalUrl);
    } catch (e) {
      trackAnalyticsEvent(
        Analytics.frqShareEmailFail,
        mapTestPrepShareFrqEmailPayload(
          currentTest?.course,
          currentTest?.unit,
          currentTest?.externalUrl,
          currentTest?.id,
        ),
      );

      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
      }

      return thunkApi.rejectWithValue(locale.errors.network_error);
    }
  },
);

export const trackTutorHelpPanelPressed = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/trackRaiseHandAction`,
  async (payload: TrackTutorPanelPressAction, thunkApi) => {
    const {
      from,
      generatedContentId,
      courseId,
      unitId,
      unitNumber,
      contentType,
      courseName,
    } = payload;
    const state = thunkApi.getState() as RootState;
    const userInteractions =
      state.raiseHand.userInteractionHistory[generatedContentId] || [];

    trackAnalyticsEvent(Analytics.raiseHand, {
      contentId: generatedContentId,
      userInteractions,
      from,
      courseId,
      unitId,
      unitNumber,
      contentType,
      courseName,
    });
  },
);

export const getExternalUrl = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/getExternalUrl`,
  async (params: GetGeneratorExternalUrlParams, thunkApi) => {
    try {
      return await getGeneratorExternalUrlGraphQLCall({
        courseId: params.courseId,
        unitId: params.unitId,
      });
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const startNewTest = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/startNewTest`,
  async (params: StartNewTestParams, thunkApi) => {
    let rootState = thunkApi.getState() as RootState;
    const state = rootState.testPrep;

    if (
      state.currentTestLastFetchedOn[params.unit.id] &&
      state.currentTestPerUnit[params.unit.id]
    ) {
      const lastFetchedOn = new Date(
        state.currentTestLastFetchedOn[params.unit.id],
      );
      const now = new Date();

      const diff = now.getTime() - lastFetchedOn.getTime();

      if (diff < START_NEW_TEST_COOLDOWN) {
        // SINCE WE NEVER KNOW IF THE URL TURNS OUT NEED TO BE UPDATED,
        // SO WE HAVE DIFFERENT COOLDOWN FOR THIS
        if (diff >= FRQ_TEST_COOLDOWN && params.testType === TestType.AP_TEST) {
          thunkApi.dispatch(
            getExternalUrl({
              courseId: params.course.id,
              unitId: params.unit.id,
              shouldAssign: true,
            }),
          );
        }
      }

      return {
        reAssignExisting: true,
        existingTestData: state.currentTestPerUnit[params.unit.id],
        result: undefined,
      };
    }

    try {
      const unitTestRequest = createUnitTestGraphQLCall({
        course: {
          id: params.course.id,
          name: params.course.name,
        },
        subject: {
          id: params.course.subjectId,
          name: params.course.subject,
        },
        unitId: params.unit.id,
        testType: params.testType,
      });

      if (params.testType === TestType.AP_TEST) {
        const [unitTest, externalUrl] = await Promise.all([
          unitTestRequest,
          thunkApi
            .dispatch(
              getExternalUrl({
                courseId: params.course.id,
                unitId: params.unit.id,
                shouldAssign: false,
              }),
            )
            .unwrap(),
        ]);

        return {
          reAssignExisting: false,
          existingTestData: undefined,
          result: { ...externalUrl, ...unitTest },
          defaultFigure: undefined,
        };
      }

      const unitTest = await unitTestRequest;

      return {
        reAssignExisting: false,
        existingTestData: undefined,
        result: unitTest,
      };
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        RootNavigatorRef.goBack();
        return thunkApi.rejectWithValue(locale.errors.network_error);
      }
    }
  },
);

export const recordQuestionViewed = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/recordQuestionViewed`,
  async (generatedContentId: string, thunkApi) => {
    try {
      await recordContentViewGraphQLCall(generatedContentId);
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const checkAndAssignCurrentTestBestScore = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/checkAndAssignCurrentTestBestScore`,
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const localState = state.testPrep;
    if (!localState.currentTest) {
      return;
    }

    if (!isSATCourse(localState.currentTest.course)) {
      return;
    }

    const unit = localState.currentTest.unit;
    const satHistory = localState.SATHistory[unit.id];
    if (!satHistory) {
      return;
    }

    thunkApi.dispatch(
      assignBestScoreToCurrentTest({
        bestScore: satHistory.bestScore,
      }),
    );
  },
);

export const submitTestQuestionAnswer = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/submitTestQuestionAnswer`,
  async (params: SubmitTestQuestionAnswerParams, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const localState = state.testPrep;
    if (!localState.currentTest) {
      return thunkApi.rejectWithValue(locale.errors.unknown_error);
    }

    trackAnalyticsEvent(
      Analytics.unitTestSubmitAnswer,
      mapDataToUnitTestSubmitAnswerPayload(
        localState.currentTest.course,
        localState.currentTest.unit,
        params.testQuestion,
        params.option,
        localState.currentTest.testNumber,
      ),
    );

    if (params.option.correct) {
      thunkApi.dispatch(incrementCorrectAnswers(1));
    }

    const payload = {
      courseId: localState.currentTest.course.id,
      unitId: localState.currentTest.unit.id,
      testId: localState.currentTest.id,
      questionId: params.testQuestion.id,
      answer: params.option.answer,
      isCorrect: params.option.correct,
    };

    if (!state.network.isOnline) {
      thunkApi.dispatch(addQueuedAnswer(payload));
    } else {
      try {
        return await submitUnitTestQuestionGraphQLCall(payload);
      } catch (e) {
        if (e instanceof Error) {
          thunkApi.dispatch(removeFirstQueuedAnswer());

          const error: Error = e;
          handleNetworkActionErrorSilently(error);
          return thunkApi.rejectWithValue(locale.errors.network_error);
        }
      }
    }
  },
);

export const submitQueuedAnswers = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/submitQueuedAnswers`,
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const testPrepState = state.testPrep;
    const queuedAnswers = testPrepState.queuedAnswers;

    if (queuedAnswers.length === 0) {
      return true;
    }

    let answer: SubmitUnitTestQuestionRequest | undefined = queuedAnswers[0];
    while (answer) {
      if (!state.network.isOnline) {
        // If the network is not online, we will try again later
        return false;
      }
      try {
        // We don't parallelize the requests to preserve request order
        logger.debug('Submitting queued answer ' + answer.questionId);
        await submitUnitTestQuestionGraphQLCall(answer);
        thunkApi.dispatch(removeFirstQueuedAnswer());
      } catch (e) {
        if (e instanceof Error) {
          const error: Error = e;
          handleNetworkActionError(error);
          return thunkApi.rejectWithValue(error.message);
        }
      }

      const localState = thunkApi.getState() as RootState;
      answer = localState.testPrep.queuedAnswers[0];
    }

    return true;
  },
);

export const getTestPrepHistory = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/getTestPrepHistory`,
  async (params: GetTestPrepHistoryParams, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const state = rootState.testPrep;

    const shouldSkip = checkIfShouldSkipActionWithCoolDown(
      TEST_PREP_HISTORY_COOLDOWN,
      state.getUnitTestHistoryLastFetchedOn[params.course.id],
      params.enforce,
    );
    if (shouldSkip) {
      return { result: undefined, skip: true };
    }

    try {
      const response = await getUnitTestsHistoryGraphQLCall({
        course: {
          id: params.course.id,
          name: params.course.name,
        },
        subject: {
          id: params.course.subjectId,
          name: params.course.subject,
        },
      });

      return { result: response, skip: false };
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(locale.errors.network_error);
      }
    }
  },
);

export const getSATTestPrepHistory = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/getSATTestPrepHistory`,
  async (params: GetTestPrepHistoryParams, thunkApi) => {
    let rootState = thunkApi.getState() as RootState;
    const state = rootState.testPrep;

    const shouldSkip = checkIfShouldSkipActionWithCoolDown(
      TEST_PREP_HISTORY_COOLDOWN,
      state.getUnitTestHistoryLastFetchedOn[params.course.id],
      params.enforce,
    );
    if (shouldSkip) {
      return { result: undefined, skip: true };
    }

    try {
      const response = await getSATUnitTestsHistoryGraphQLCall({
        course: {
          id: params.course.id,
          name: params.course.name,
        },
        subject: {
          id: params.course.subjectId,
          name: params.course.subject,
        },
      });

      const satMaxScores = mapSATMaxScoresFromRawHistory(
        response?.units,
        params.course?.units,
      );

      let totalScore = 0;

      if (rootState.SAT.bootcamp?.details) {
        const summary = mapSATHistoryResponseSummaryWithBaselineScore(
          params.course,
          response?.units,
          rootState.SAT.bootcamp,
        );

        Object.keys(summary).forEach(key => {
          totalScore += mapSATHighestUnitTestHistoryScore(summary[key]);
        });
      } else {
        const summary = mapSATHistoryResponseSummary(
          params.course?.units,
          response?.units,
        );

        totalScore = summary.totalScore;
      }

      await thunkApi
        .dispatch(
          getSATUserPercentile({
            courseId: params.course.id,
            totalScore: totalScore,
            maxScore: satMaxScores.total,
          }),
        )
        .unwrap();

      rootState = thunkApi.getState() as RootState;
      const bootcamp = rootState.SAT.bootcamp;
      if (bootcamp?.details) {
        await thunkApi.dispatch(getUserSATProgramDetails());
      }

      return { result: response, skip: false, bootcampDetails: bootcamp };
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(locale.errors.network_error);
      }
    }
  },
);

export const getSATUserPercentile = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/getSATUserPercentile`,
  async (params: GetSATUserPercentilePayload, thunkApi) => {
    try {
      const { courseId, totalScore, maxScore } = params;

      const total = await getTestScorePercentileGraphQLCall({
        score: totalScore,
        scope: TestScopeType.TOTAL,
        testType: TestType.SAT_TEST,
        maxScore: maxScore,
      }).catch(() => undefined);

      return {
        courseId,
        total: total?.percentileExtended,
      };
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const testPrepSlice = createSlice({
  name: TEST_PREP_SLICE_NAME,
  initialState,
  reducers: {
    disableTestPractice: (state, action: { payload: { unit: Unit } }) => {
      if (state.targetedPractices[action.payload.unit.id]) {
        state.targetedPractices[action.payload.unit.id].isAvailable = false;
      }
    },
    resetState(state: TestPrepSliceState) {
      state.currentTest = undefined;
      state.chatPanelOpenedStatus = {};
      state.isLoading = false;
      state.timerPaused = false;
    },
    clearTestPrepStateOnLogout(state: TestPrepSliceState) {
      state.currentTest = undefined;
      state.isLoading = false;
      state.timerPaused = false;
      state.currentTestPerUnit = {};
      state.currentTestLastFetchedOn = {};
      state.getUnitTestHistoryLastFetchedOn = {};
      state.getUnitTestHistoryLoading = {};
      state.attemptsHistory = {};
      state.bestAttempts = {};
      state.targetedPractices = {};
      state.SATHistory = {};
      state.SATUserPercentile = {};
      state.chatPanelOpenedStatus = {};
      state.testPrepEntryPoint = TestPrepEntryPoint.APP_OPEN;
    },
    resetCurrentTestLastFetchedOn(
      state,
      action: { payload: { unitId: string } },
    ) {
      if (!action.payload.unitId) {
        return;
      }

      delete state.currentTestLastFetchedOn[action.payload.unitId];
    },
    setRemainingTime(state, action) {
      if (state.currentTest) {
        state.currentTest.timeRemaining = action.payload;
      }
    },
    setTimerPaused(state, action) {
      state.timerPaused = action.payload;
    },
    setTestProgress(
      state,
      action: { payload: { unit: Unit; progress: number } },
    ) {
      if (state.targetedPractices[action.payload.unit.id]) {
        state.targetedPractices[action.payload.unit.id].progress =
          action.payload.progress;
      }
    },
    setFirstTestCompleted(state) {
      state.firstTestCompleted = true;
    },
    assignBestScoreToCurrentTest(state, action: AssignBestScoreToCurrentTest) {
      if (state.currentTest) {
        state.currentTest.bestScore = action.payload?.bestScore;
      }
    },
    setTestPrepEntryPoint(
      state,
      action: PayloadAction<TestPrepEntryPoint | undefined>,
    ) {
      state.testPrepEntryPoint = action.payload;
    },
    setChatPanelOpenedStatus(state, action: SetChatPanelOpenedStatusParams) {
      state.chatPanelOpenedStatus[action.payload.questionId] =
        action.payload.isOpened;
    },
    setCurrentTestQuestionIndex(
      state,
      action: PayloadAction<{
        index: number;
        from: TestPrepQuestionEntryPoint;
      }>,
    ) {
      if (state.currentTest) {
        state.currentTest.currentQuestionIndex = action.payload.index;
        trackAnalyticsEvent(
          Analytics.unitTestQuestionViewed,
          mapDataToUnitTestQuestionViewedPayload(
            state.currentTest.course,
            state.currentTest.unit,
            state.currentTest.questions,
            action.payload.index,
            state.currentTest.highestSeenQuestionIndex ?? 0,
            action.payload.from,
            state.currentTest.testNumber,
          ),
        );
      }
    },
    setCurrentTestHighestSeenQuestionIndex(
      state,
      action: PayloadAction<number>,
    ) {
      if (
        state.currentTest &&
        action.payload > (state.currentTest.highestSeenQuestionIndex ?? -1)
      ) {
        state.currentTest.highestSeenQuestionIndex = action.payload;
      }
    },
    setCurrentTestResultsSeen(state, action: PayloadAction<boolean>) {
      if (state.currentTest) {
        state.currentTest.resultsSeen = action.payload;
      }
    },
    addQueuedAnswer(
      state,
      action: PayloadAction<SubmitUnitTestQuestionRequest>,
    ) {
      state.queuedAnswers.push(action.payload);
    },
    reAddQueuedAnswer(
      state,
      action: PayloadAction<SubmitUnitTestQuestionRequest>,
    ) {
      state.queuedAnswers.unshift(action.payload);
    },
    removeFirstQueuedAnswer(state) {
      state.queuedAnswers.shift();
    },
    setAnswerChoosenForTheCurrentTest(
      state,
      action: SetAnswerChoosenForTheCurrentTestPayload,
    ) {
      if (state.currentTest) {
        const question = state.currentTest.questions.find(
          question => question.id === action.payload.questionId,
        );
        if (question) {
          question.chosenOption = action.payload.answerChoosen;
        }
      }
    },
    setPreGeneratedMessages(state, action: SetPreGeneratedMessagesParams) {
      state.pregeneratedMessages = action.payload?.testPrep || [];
      state.mathPregeneratedMessages = action.payload?.math || [];
    },
  },
  extraReducers: builder => {
    submitTestResultsExtraReducers(builder);
    builder.addCase(shareFrqEmailLinkAndOpenBrowser.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(shareFrqEmailLinkAndOpenBrowser.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(shareFrqEmailLinkAndOpenBrowser.fulfilled, state => {
      state.isLoading = false;
    });

    builder.addCase(getTestPrepHistory.pending, (state, action) => {
      const args = action.meta.arg as GetTestPrepHistoryParams;
      state.getUnitTestHistoryLoading[args.course.id] = true;
    });

    builder.addCase(getTestPrepHistory.rejected, (state, action) => {
      const args = action.meta.arg as GetTestPrepHistoryParams;
      state.getUnitTestHistoryLoading[args.course.id] = false;
    });

    builder.addCase(getTestPrepHistory.fulfilled, (state, action) => {
      const skip = action.payload?.skip;
      const result = action.payload?.result;
      const args = action.meta.arg as GetTestPrepHistoryParams;

      state.getUnitTestHistoryLoading[args.course.id] = false;

      if (skip || !result) {
        return;
      }

      state.getUnitTestHistoryLastFetchedOn[args.course.id] =
        new Date().toISOString();

      state.attemptsHistory = result.units.reduce((acc, unit) => {
        acc[unit.id] = {
          recentAttempts: unit.recentAttempts,
          totalAttempts: unit.totalAttempts,
        };
        return acc;
      }, state.attemptsHistory);

      state.bestAttempts = result.units.reduce((acc, unit) => {
        acc[unit.id] = unit.bestAttempt;
        return acc;
      }, state.bestAttempts);

      state.targetedPractices = result.units.reduce((acc, unit) => {
        acc[unit.id] = unit.targetedPractice;
        return acc;
      }, state.targetedPractices);
    });

    builder.addCase(getSATTestPrepHistory.pending, (state, action) => {
      const args = action.meta.arg as GetTestPrepHistoryParams;
      state.getUnitTestHistoryLoading[args.course.id] = true;
    });

    builder.addCase(getSATTestPrepHistory.rejected, (state, action) => {
      const args = action.meta.arg as GetTestPrepHistoryParams;
      state.getUnitTestHistoryLoading[args.course.id] = false;
    });

    builder.addCase(getSATTestPrepHistory.fulfilled, (state, action) => {
      const skip = action.payload?.skip;
      const result = action.payload?.result;
      const args = action.meta.arg as GetTestPrepHistoryParams;

      const bootcampDetails = action.payload?.bootcampDetails;

      state.getUnitTestHistoryLoading[args.course.id] = false;

      if (skip || !result) {
        return;
      }

      state.getUnitTestHistoryLastFetchedOn[args.course.id] =
        new Date().toISOString();

      if (bootcampDetails) {
        state.SATHistory = {
          ...state.SATHistory,
          ...mapSATHistoryResponseSummaryWithBaselineScore(
            args.course,
            result.units,
            bootcampDetails,
          ),
        };
      } else {
        state.SATHistory = result.units.reduce<SATHistoryData>(
          (acc, unit) => {
            acc[unit.id] = { ...unit };
            return acc;
          },
          { ...state.SATHistory },
        );
      }
    });

    builder.addCase(getSATUserPercentile.fulfilled, (state, action) => {
      const { courseId, total } = action.payload;
      state.SATUserPercentile[courseId] = {
        [TestScopeType.TOTAL]: total,
      };
    });

    builder.addCase(getExternalUrl.pending, (state, action) => {
      const args = action.meta.arg as GetGeneratorExternalUrlParams;
      state.frqLoading[args.unitId] = true;
    });

    builder.addCase(getExternalUrl.rejected, (state, action) => {
      const args = action.meta.arg as GetGeneratorExternalUrlParams;
      state.frqLoading[args.unitId] = false;
    });

    builder.addCase(getExternalUrl.fulfilled, (state, action) => {
      const args = action.meta.arg as GetGeneratorExternalUrlParams;
      state.frqLoading[args.unitId] = false;

      if (!args.shouldAssign) {
        return;
      }

      if (state.currentTestPerUnit[args.unitId]) {
        state.currentTestPerUnit[args.unitId].externalUrl =
          action.payload?.externalUrl;
      }

      if (state.currentTest?.unit?.id === args.unitId) {
        state.currentTest.externalUrl = action.payload?.externalUrl;
      }
    });

    builder.addCase(startNewTest.pending, (state, action) => {
      const args = action.meta.arg as StartNewTestParams;

      state.isLoading = true;
      state.currentTestFetchLoading[args.unit?.id ?? ''] = true;
    });

    builder.addCase(startNewTest.rejected, (state, action) => {
      const args = action.meta.arg as StartNewTestParams;

      state.isLoading = false;
      state.currentTestFetchLoading[args.unit?.id ?? ''] = false;
    });

    builder.addCase(startNewTest.fulfilled, (state, action) => {
      const args = action.meta.arg as StartNewTestParams;
      const testType = args.testType;

      const reAssignExisting = action.payload?.reAssignExisting;
      const existingTestData = action.payload?.existingTestData;
      const result = action.payload?.result;

      state.isLoading = false;
      state.currentTestFetchLoading[args.unit?.id ?? ''] = false;
      const isSAT = isSATCourse(args.course);
      const testNumber = isSAT
        ? (state.SATHistory[args.unit.id]?.totalTestsTaken ?? 0) + 1
        : (state.attemptsHistory[args.unit.id]?.totalAttempts ?? 0) + 1;

      if (reAssignExisting && existingTestData) {
        state.currentTest = existingTestData;
        state.currentTest.testNumber = testNumber;
        return;
      }

      if (!result) {
        return;
      }

      state.currentTest = mapUnitTestResponseToTestPrepData(
        action.meta.arg.course,
        action.meta.arg.unit,
        testType,
        result,
      );

      state.currentTest.testNumber = testNumber;
      state.currentTestPerUnit[args.unit.id] = state.currentTest;
      state.currentTestLastFetchedOn[args.unit.id] = new Date().toISOString();
    });

    builder.addCase(submitTestQuestionAnswer.pending, (state, action) => {
      const arg = action.meta.arg as SubmitTestQuestionAnswerParams;
      if (state.currentTest) {
        const question = state.currentTest.questions.find(
          q => q.id === arg.testQuestion.id,
        );

        if (question) {
          question.chosenOption = arg.option;
        }
      }
    });

    builder.addCase(awardPracticePoints.pending, state => {
      state.pointsAwardedForPractice = null;
    });

    builder.addCase(awardPracticePoints.rejected, state => {
      state.pointsAwardedForPractice = BONUS_POINTS_FOR_TEST_PRACTICE;
    });

    builder.addCase(awardPracticePoints.fulfilled, (state, action) => {
      state.pointsAwardedForPractice = action.payload.pointsAwarded;
    });

    builder.addCase(getTestScorePercentile.fulfilled, (state, action) => {
      if (state.currentTest) {
        state.currentTest.percentileString =
          action.payload.percentileExtended.toString();
      }
    });
  },
});

export const isTestHistoryLoadingSelector = (
  state: TestPrepSliceState,
  courseId: string,
): boolean => {
  if (!courseId) {
    return false;
  }

  return state.getUnitTestHistoryLoading[courseId] ?? false;
};

export const isFetchingTestForUnitSelector = (
  state: TestPrepSliceState,
  unitId: string,
): boolean => {
  if (!unitId) {
    return false;
  }

  return state.currentTestFetchLoading[unitId] ?? false;
};

export const isFrqLoadingSelector = (
  state: TestPrepSliceState,
  unitId?: string,
): boolean => {
  if (!unitId) {
    return false;
  }

  return state.frqLoading[unitId] ?? false;
};

export const getSATCalculatedScore = (
  rootState: RootState,
  courseId?: string,
): SATHistoryDataSummary | undefined => {
  const course: Course | undefined =
    rootState.courses.coursesMap[courseId ?? ''];

  if (!isSATCourse(course)) {
    return undefined;
  }

  return mapSATHistorySummary(
    rootState.testPrep.SATHistory,
    rootState.testPrep.SATUserPercentile[courseId ?? ''],
    course?.units,
  );
};

export const {
  disableTestPractice,
  resetState,
  setRemainingTime,
  setTimerPaused,
  setTestProgress,
  setFirstTestCompleted,
  clearTestPrepStateOnLogout,
  resetCurrentTestLastFetchedOn,
  assignBestScoreToCurrentTest,
  setTestPrepEntryPoint,
  setChatPanelOpenedStatus,
  setCurrentTestQuestionIndex,
  setCurrentTestResultsSeen,
  setCurrentTestHighestSeenQuestionIndex,
  addQueuedAnswer,
  removeFirstQueuedAnswer,
  reAddQueuedAnswer,
  setPreGeneratedMessages,
  setAnswerChoosenForTheCurrentTest,
} = testPrepSlice.actions;

export const TestPrepSlice = persistReducer(
  persistConfig,
  testPrepSlice.reducer,
);
