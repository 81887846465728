import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../../App/locale';
import {
  CommonLinearProgress,
  CommonOrdinalNumber,
} from '../../../Common/components';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { SATUnitsIndex } from '../../entities';
import { useSATStatsScore } from '../../services/hooks';

type Props = {
  shouldShowStats: boolean;
  percentile?: string;
  score?: number;
  maxScore?: number;
  unitsIndex?: SATUnitsIndex;
  lastExamScore?: number;
};

export const SATProfileStatsTotalScore = (props: Props): React.ReactElement => {
  const {
    shouldShowStats,
    score,
    maxScore,
    percentile,
    unitsIndex,
    lastExamScore,
  } = props;
  const {
    targetPercentage,
    target,
    noScoreTextDescription,
    progressPercentage,
    lastExamScorePercentage,
    getScoreBoostedText,
    isScoreBoosted,
  } = useSATStatsScore({
    score,
    maxScore,
    unitsIndex,
    lastExamScore,
    isTotalScore: true,
  });

  const getScoreTextStyle = useMemo(() => {
    return [styles.scoreText, isScoreBoosted && styles.scoreTextHighlight];
  }, [isScoreBoosted]);

  return (
    <View style={styles.container}>
      <Text style={styles.titleText}>{locale.sat.stats_total_score_title}</Text>

      <View style={styles.detailContainer}>
        {!shouldShowStats && (
          <Text style={styles.detailText}>{noScoreTextDescription}</Text>
        )}

        {shouldShowStats && (
          <>
            <Text style={getScoreTextStyle}>{score}</Text>

            <View style={styles.scorePercentileTextContainer}>
              <CommonOrdinalNumber
                numberInString={percentile}
                textStyle={styles.scorePercentileText}
                ordinalTextStyle={styles.scorePercentileText}
              />
              <Text style={styles.scorePercentileTextSuffix}>
                {locale.sat.stats_percentile_suffix}
              </Text>
            </View>
            {isScoreBoosted && (
              <View
                style={[
                  styles.scorePercentileTextContainer,
                  styles.scoreBoostedTextContainer,
                ]}>
                <Text style={styles.scorePercentileText}>
                  <Text style={styles.scorePercentileText}>
                    {locale.sat.score_boosted_message}
                  </Text>
                  <Text style={styles.scorePercentileTextHighlight}>
                    {getScoreBoostedText}
                  </Text>
                </Text>
              </View>
            )}

            <View style={styles.scoreProgressContainer}>
              <CommonLinearProgress
                progressPercentage={progressPercentage}
                targetPercentage={targetPercentage}
                target={target}
                lastExamScorePercentage={lastExamScorePercentage}
              />
            </View>
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  titleText: {
    ...Fonts.smallBold,
    color: 'white',
    textAlign: 'center',
  },
  detailContainer: {
    marginTop: Sizes.small,
  },
  detailText: {
    ...Fonts.small,
    textAlign: 'center',
    color: Colors.white60,
  },

  scoreProgressContainer: {
    marginTop: Sizes.small,
  },
  scoreText: {
    ...Fonts.xxxxlargeBold,
    color: Colors.emeraldGreen,
    textAlign: 'center',
  },
  scorePercentileText: {
    color: Colors.white60,
  },
  scorePercentileTextSuffix: {
    ...Fonts.small,
    color: Colors.white60,
  },
  scorePercentileTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
  },
  scoreTextHighlight: {
    color: Colors.sliceLightGreen,
  },
  scorePercentileTextHighlight: {
    ...Fonts.smallBold,
    color: Colors.sliceLightGreen,
  },
  scoreBoostedTextContainer: {
    marginTop: 4,
  },
});
