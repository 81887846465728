import { RootState } from '../../../App/store';
import {
  CommonTrueFalse,
  ExamResultsYesNoEnum,
} from '../../../Common/entities';
import { SATLastScoreKey } from '../../../Onboarding/entities';
import { getSATLastScoreValueByKey } from '../../../Onboarding/services/utils';
import {
  SATExamResultsSubmittedType,
  SATResultsCongratulationsMessageType,
  SATResultsNextInputsSummary,
} from '../../entities';

import { mapSATResultsCongratulationMessageType } from './SATResultsDataMapper';

export const mapSATExamResultsSubmittedAnalytics = (
  state: RootState,
): SATExamResultsSubmittedType => {
  const satResults = state.SATResults;
  const messageType = mapSATResultsCongratulationMessageType(state);

  let examResultsValue: number | undefined;
  let isHappyWithResultsValue: CommonTrueFalse | undefined;

  if (satResults.examResultKey !== SATLastScoreKey.NOT_TAKEN) {
    examResultsValue = getSATLastScoreValueByKey(satResults.examResultKey);
  }

  if (
    [
      SATResultsCongratulationsMessageType.ABOVE_LAST_SCORE,
      SATResultsCongratulationsMessageType.BELOW_LAST_SCORE,
    ].includes(messageType)
  ) {
    isHappyWithResultsValue = satResults.isUserHappyWithSATResults
      ? CommonTrueFalse.TRUE
      : CommonTrueFalse.FALSE;
  }

  if (
    [
      SATResultsCongratulationsMessageType.ACHIEVED_TARGET,
      SATResultsCongratulationsMessageType.ABOVE_TARGET,
    ].includes(messageType)
  ) {
    isHappyWithResultsValue = CommonTrueFalse.TRUE;
  }

  return {
    examDate: satResults.lastSATExamDate ?? '',
    targetSATExamScore: satResults.lastSATTargetScore ?? 0,
    lastSATExamScore: satResults.lastSATCurrentScore ?? 0,
    examResults: examResultsValue,
    isHappyWithResults: isHappyWithResultsValue,
  };
};

export const getSATResultsNextInputsSummary = (
  state: RootState,
): SATResultsNextInputsSummary => {
  const planSat = state.SATResults.planToRetakeSAT;

  const planApNextYear = state.SATResults.planToTakeAPNextYearMay;

  return {
    takingSAT: planSat ?? ExamResultsYesNoEnum.NO,
    satDate: state.SATResults.retakeSATExamDate,
    takingApNextYear: planApNextYear,
    satTargetScore: state.SATResults.retakeSATTargetScore,
  };
};
