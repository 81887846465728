import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { ExamResultsYesNoQuestion } from '..';
import locale from '../../../App/locale';
import { useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import { setZoomChatConsent } from '../../../ApResults/services/slices';
import { setSATZoomChatConsent } from '../../../SATResults/services/slices';
import { CommonExamType, ExamResultsYesNoEnum } from '../../entities';
import { Colors, Fonts, Sizes } from '../../services/utils/AppConstants';

type ExamResultsFeedbackZoomProps = {
  examType: CommonExamType;
};

export const ExamResultsFeedbackZoom = ({
  examType,
}: ExamResultsFeedbackZoomProps): React.ReactElement => {
  const dispatch = useAppDispatch();
  const apZoomConsent = useAppSelector(
    state => state.apResults.zoomChatConsent,
  );
  const satZoomConsent = useAppSelector(
    state => state.SATResults.zoomChatConsent,
  );

  const handleUpdateZoomConsent = useCallback(
    (value: ExamResultsYesNoEnum) => {
      if (examType === CommonExamType.AP) {
        dispatch(setZoomChatConsent(value));
      } else {
        dispatch(setSATZoomChatConsent(value));
      }
    },
    [dispatch, examType],
  );

  const shouldShowFollowUp = useMemo(() => {
    return (
      apZoomConsent === ExamResultsYesNoEnum.YES ||
      satZoomConsent === ExamResultsYesNoEnum.YES
    );
  }, [apZoomConsent, satZoomConsent]);

  const zoomConsent = useMemo(() => {
    if (examType === CommonExamType.AP) {
      return apZoomConsent;
    }
    return satZoomConsent;
  }, [apZoomConsent, satZoomConsent, examType]);

  return (
    <View>
      <Text style={styles.title}>{locale.ap_results.zoom_chat_title}</Text>

      <View style={styles.fieldContainer}>
        <ExamResultsYesNoQuestion
          onValueChange={handleUpdateZoomConsent}
          value={zoomConsent}
          label={locale.ap_results.zoom_chat_description}
          labelStyle={styles.zoomConsentLabel}
        />
      </View>

      {shouldShowFollowUp && (
        <Text style={styles.followUpText}>
          {locale.ap_results.zoom_chat_accept_follow_up}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    ...Fonts.xxlargeBold,
    textAlign: 'center',
  },

  fieldContainer: {
    marginTop: Sizes.xsmall,
    gap: 40,
  },

  zoomConsentLabel: {
    ...Fonts.semiMedium,
    color: Colors.white70,
  },

  followUpText: {
    ...Fonts.semiMedium,
    marginTop: Sizes.small,
    color: Colors.white70,
    textAlign: 'center',
  },
});
