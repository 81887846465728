import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { showMessage } from 'react-native-flash-message';

import locale from '../../../../App/locale';
import { handleNetworkActionError } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { isComingFromBootcampFlow } from '../../../../Onboarding/services/mappers';
import { proceedOnboardingDataOnSignupSuccess, setShouldShowUsernamePictureScreen } from '../../../../Onboarding/services/slices';
import { createUserAccountGraphQLCall } from '../../../../Profile/graphql';
import { signIn } from '../AuthSliceActions';
import { SIGNUP_SLICE_NAME, SignupSliceState } from '../SignupSlice';
import { updateUserIdentifier } from '../UserProfileActions/UpdateUserIdentifierAction';

export const signUp = createAsyncThunk(
  `${SIGNUP_SLICE_NAME}/signUp`,
  async (_, thunkApi) => {
    try {
      const state = (thunkApi.getState() as RootState).signup;
      await createUserAccountGraphQLCall({
        email: state.email,
        password: state.password,
        preferredName: state.preferredName,
      });

      await thunkApi
        .dispatch(
          signIn({
            username: state.email,
            password: state.password,
            saveToIcloud: true,
            fromScreen: true,
            fromSignup: true,
          }),
        )
        .unwrap();

      thunkApi.dispatch(setShouldShowUsernamePictureScreen(true));

      const onboardingState = (thunkApi.getState() as RootState).onboarding;
      if (isComingFromBootcampFlow(onboardingState)) {
        await thunkApi
          .dispatch(proceedOnboardingDataOnSignupSuccess())
          .unwrap();
      }

      thunkApi.dispatch(
        updateUserIdentifier({
          updateAlias: true,
          isFromSignUp: true,
        }),
      );
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        if (
          error?.message ===
          locale.signup_screen.email_error_message_from_backend
        ) {
          showMessage({
            message: locale.signup_screen.email_already_exists,
            type: 'danger',
          });
        } else {
          handleNetworkActionError(error);
        }
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

export const signUpExtraReducers = (
  builder: ActionReducerMapBuilder<SignupSliceState>,
): void => {
  builder.addCase(signUp.fulfilled, (state: SignupSliceState) => {
    state.isLoading = false;
    state.signUpLoading = false;
  });
  builder.addCase(signUp.pending, (state: SignupSliceState) => {
    state.isLoading = true;
    state.signUpLoading = true;
  });
  builder.addCase(signUp.rejected, (state: SignupSliceState) => {
    state.isLoading = false;
    state.signUpLoading = false;
  });
};
