import { SATClaimedAccessType } from '../../Onboarding/entities';

import { SATUnitTestHistoryUnit } from './SATRequestTypes';

export type SATBootcampAnalytics = {
  examDate?: string;
  bootcampName?: string;
  bootcampPeriod?: string;
  bootcampPeriodCount?: number;
  bootcampDuration?: string;
  currentScore?: number;
  targetScore?: number;
  timeCommitment?: string;
  studyCommitmentHours?: number;
  SATTotalBaselineScore?: number;
  SATMathBaselineScore?: number;
  SATReadingWritingBaselineScore?: number;
  bootcampAccessType?: SATClaimedAccessType;
};

export type SATBootcampAnalyticsWithTimeTrack = SATBootcampAnalytics & {
  currentBootcampPeriod: number;
  studyHoursSpent: number;
} & SATSummaryAnalytics;

export enum EntitlementAnalyticsType {
  PURCHASE = 'purchase',
  FREE_TRIAL = 'free_trial',
}

export type SATSummaryAnalytics = {
  SATTotalBaselineScore?: number;
  SATMathBaselineScore?: number;
  SATReadingWritingBaselineScore?: number;
};

export type SATScoreChangedAnalyticsPayload = SATSummaryAnalytics & {
  SATTotalScore?: number;
  SATMathScore?: number;
  SATReadingWritingScore?: number;
  unitScores: SATUnitTestHistoryUnitAnalytics[];
};

export type SATUnitTestHistoryUnitAnalytics = {
  unitNumber: number;
  totalTestsTaken: number;
  latestScore: number;
  totalScore: number;
  bestScore: number;
  baselineScore?: number;
};
