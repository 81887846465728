import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { CommonActionButton } from '..';
import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import { CommonAssets } from '../../assets';
import { Colors, Fonts, Sizes } from '../../services/utils/AppConstants';

type Props = {
  onPress: () => void;
};

export const ExamResultsLandingButton = (props: Props): React.ReactElement => {
  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <AssetImage asset={CommonAssets.TimerIcon} height={Sizes.medium} />
        <Text style={styles.text}>{locale.ap_results.take_less_minute}</Text>
      </View>

      <CommonActionButton
        text={locale.common.get_started}
        style={styles.button}
        onPress={props.onPress}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: Sizes.small,
  },

  textContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: Sizes.xsmall,
  },
  text: {
    ...Fonts.small,
    color: Colors.white70,
  },

  button: {
    marginTop: Sizes.small,
  },
});
