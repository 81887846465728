import { createAsyncThunk } from '@reduxjs/toolkit';

import { getUserApProgramDetails } from '../../../../AP/services/slices';
import { getNotificationPermission } from '../../../../Notification/services/slices/NotificationSlice';
import { getUserSATProgramDetails } from '../../../../SAT/services/slices';
import { TrackedUserEventType } from '../../../../UserEvent/entities';
import { trackUserEvent } from '../../../../UserEvent/services/slices';
import { getAuthService } from '../../amplify/AuthService';
import { setIsAppActive } from '../AppSlice';
import { LIFECYCLE_SLICE_NAME } from '../LifecycleSlice';

export const onAppGoesToForeground = createAsyncThunk(
  `${LIFECYCLE_SLICE_NAME}/onAppGoesToForeground`,
  async (_, thunkApi) => {
    thunkApi.dispatch(setIsAppActive(true));

    const isAuthValid = await getAuthService().isAuthenticated();

    if (isAuthValid) {
      thunkApi.dispatch(getNotificationPermission());
      thunkApi.dispatch(
        trackUserEvent({
          eventType: TrackedUserEventType.SessionInitiated,
        }),
      );

      await Promise.all([
        await thunkApi.dispatch(getUserSATProgramDetails()).unwrap(),
        await thunkApi.dispatch(getUserApProgramDetails()).unwrap(),
      ]);
    }
  },
);
