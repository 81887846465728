import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { showMessage } from 'react-native-flash-message';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { useAppDispatch } from '../../App/store';
import Avatar from '../../Common/components/common/Avatar';
import { Course, PaywallEntryPoint } from '../../Common/entities';
import {
  useIsCourseListScreenVisible,
  useProgramCourseNameGetter,
} from '../../Common/services/hooks';
import {
  Colors,
  Durations,
  Fonts,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { CourseTag } from '../../CourseEnrollment/components';
import { SelectedCourseEntryPoint } from '../../CourseEnrollment/entities';
import { useGetCourseTag } from '../../CourseEnrollment/services/hooks';
import { setCurrentSelectedCourse } from '../../CourseEnrollment/services/slices';
import { useProfileNavigators } from '../../Profile/services/hooks';
import { getSATCalculatedScore } from '../../TestPrep/services/slices';
import { useCourseLockStatusGetter } from '../../UserAccess/services/hooks';

import {
  SATProfileStatsCategoryScore,
  SATProfileStatsTotalScore,
} from './stats';

type Props = {
  course: Course;
};

export const SATProfileStatsSummary = (props: Props): React.ReactElement => {
  const { course } = props;

  const dispatch = useAppDispatch();
  const courseName = useProgramCourseNameGetter(course);

  const courseTag = useGetCourseTag({ course });

  const bootcampDetails = useAppSelector(state => state.SAT.bootcamp);

  const isCourseListScreenVisible = useIsCourseListScreenVisible();
  const { handleOpenTestPrepScreen, handleOpenPaywallScreen } =
    useProfileNavigators();

  const historyDataSummary = useAppSelector(state =>
    getSATCalculatedScore(state, course?.id),
  );

  const { isCourseLocked } = useCourseLockStatusGetter({
    courseId: course.id,
  });

  const statsVisibility = useMemo(() => {
    return {
      total: historyDataSummary?.showTotal ?? false,
      readingWriting: historyDataSummary?.showReadingWriting ?? false,
      math: historyDataSummary?.showMath ?? false,
    };
  }, [historyDataSummary]);

  const handleStatsPress = useCallback(() => {
    if (isCourseListScreenVisible) {
      showMessage({
        message: locale.courses.please_press_start_learning,
        type: 'default',
        duration: Durations.actionFeedbackDelay,
      });
      return;
    }

    dispatch(
      setCurrentSelectedCourse({
        id: course.id,
        tab: SelectedCourseEntryPoint.TEST_PREP,
      }),
    );
    if (isCourseLocked) {
      handleOpenPaywallScreen(course, true, PaywallEntryPoint.TEST_PREP);
    } else {
      handleOpenTestPrepScreen();
    }
  }, [
    dispatch,
    handleOpenPaywallScreen,
    handleOpenTestPrepScreen,
    isCourseListScreenVisible,
    course,
    isCourseLocked,
  ]);

  return (
    <View style={styles.courseItemContainer}>
      {courseTag.isVisible && (
        <CourseTag
          containerStyle={styles.purchasedContainer}
          textStyle={styles.purchasedText}
          imageFill={Colors.white80}
          courseTagType={courseTag.tag}
        />
      )}
      <View style={styles.courseItemDescriptionContainer}>
        <Avatar image={course.avatar} size={28} />
        <Text style={styles.courseItemTitle}>{courseName}</Text>
      </View>

      <TouchableOpacity
        style={styles.statsContainer}
        onPress={handleStatsPress}>
        <View style={styles.courseHeadingContainer}>
          <AssetImage
            asset={Assets.testTabIcon}
            height={16}
            width={18}
            fill={'white'}
            fillOpacity={0.8}
            containerStyle={styles.courseTagAsset}
          />
        </View>

        <View style={styles.statsSummaryContainer}>
          <SATProfileStatsTotalScore
            shouldShowStats={statsVisibility.total}
            score={historyDataSummary?.totalScore}
            maxScore={historyDataSummary?.maxTotalScore}
            unitsIndex={historyDataSummary?.totalUnitsIndex}
            percentile={historyDataSummary?.totalPercentile}
            lastExamScore={bootcampDetails?.details?.currentScore ?? 0}
          />
        </View>

        <View style={styles.categoriesContainer}>
          <View
            style={[styles.categoryContainer, styles.categoryLeftContainer]}>
            <SATProfileStatsCategoryScore
              shouldShowStats={statsVisibility.readingWriting}
              title={locale.sat.stats_read_write_title}
              unitsIndex={historyDataSummary?.readingWritingUnitsIndex}
              score={historyDataSummary?.readingWritingScore}
              maxScore={historyDataSummary?.maxReadingWritingScore}
              lastExamScore={bootcampDetails?.details?.readingWritingScore ?? 0}
            />
          </View>

          <View style={styles.categoryContainer}>
            <SATProfileStatsCategoryScore
              shouldShowStats={statsVisibility.math}
              title={locale.sat.stats_math_title}
              unitsIndex={historyDataSummary?.mathUnitsIndex}
              score={historyDataSummary?.mathScore}
              maxScore={historyDataSummary?.maxMathScore}
              lastExamScore={bootcampDetails?.details?.mathScore ?? 0}
            />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  courseItemDescriptionContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
  },
  courseItemContainer: {
    backgroundColor: Colors.profileItemBackground,
    borderRadius: 12,
    padding: 10,
    rowGap: Sizes.small,
    borderWidth: 1,
    borderColor: Colors.profileItemBackground,
  },
  courseItemTitle: {
    ...Fonts.captionBold,
  },

  statsContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: Sizes.small,
    backgroundColor: Colors.white08,
    borderWidth: 1,
    borderColor: Colors.white22,
    minHeight: 31,
  },
  statsSummaryContainer: {
    padding: Sizes.small,
    borderBottomWidth: 1,
    borderBottomColor: Colors.white16,
    width: '100%',
  },

  courseTagAsset: {
    borderBottomRightRadius: 8,
    borderTopLeftRadius: 8,
    borderColor: Colors.white22,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    backgroundColor: Colors.white10,
    width: 31,
    height: 31,
    left: 0,
    top: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  courseHeadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },

  categoriesContainer: {
    width: '100%',
    flexDirection: 'row',
  },
  categoryContainer: {
    padding: Sizes.small,
    flex: 1,
  },
  categoryLeftContainer: {
    borderRightColor: Colors.white16,
    borderRightWidth: 1,
  },
  purchasedContainer: {
    backgroundColor: Colors.white08,
    top: 0,
  },
  purchasedText: {
    color: Colors.white80,
  },
});
