import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { RootNavigatorRef } from '../../../../App/navigation/RootNavigator';
import { RootState } from '../../../../App/store';
import {
  CommonAppGoalKeys,
  SATBootcampCategoryScoresMetadata,
  SubmitDataType,
} from '../../../../Common/entities';
import { submitDataGraphQLCall } from '../../../../Common/graphql/mutations';
import {
  trackAnalyticsEvent,
  updateUserProperties,
} from '../../../../Common/services/utils';
import {
  Analytics,
  MainStack,
  TabNames,
} from '../../../../Common/services/utils/AppConstants';
import { SelectedCourseEntryPoint } from '../../../../CourseEnrollment/entities';
import { mapUserFollowingSATCourseStatus } from '../../../../CourseEnrollment/services/mappers';
import {
  enrollUserToCourse,
  setCurrentSelectedCourse,
} from '../../../../CourseEnrollment/services/slices';
import { mapSATCourseData } from '../../../../Learn/services/mappers';
import { mapUserSATCurrentScore } from '../../../../SAT/services/mappers';
import {
  getUserSATProgramDetails,
  setSatBootcampCategoryScores,
} from '../../../../SAT/services/slices';
import { BootcampEntryPoint } from '../../../entities';
import {
  ONBOARDING_SLICE_NAME,
  OnboardingSliceState,
  resetExamInputData,
} from '../OnboardingSlice';

export const submitSATCategoryScore = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/submitSATCategoryScore`,
  async (_, thunkAPI) => {
    const navigation = RootNavigatorRef.current;

    const rootState = thunkAPI.getState() as RootState;
    const state = rootState.onboarding;

    if (
      !state.lastSATMathScore ||
      !state.lastSATReadingWritingScore ||
      !navigation?.isReady()
    ) {
      return;
    }

    const blockingPromises: Promise<unknown>[] = [];

    const followedSATCourse = mapUserFollowingSATCourseStatus(rootState);
    const SATCourse = mapSATCourseData(rootState);

    if (!followedSATCourse && SATCourse) {
      blockingPromises.push(
        thunkAPI
          .dispatch(
            enrollUserToCourse({
              course: SATCourse,
              goal: CommonAppGoalKeys.CRUSH_UPCOMING_TEST,
              isFromCoursePicker: false,
              skipRedirectToPaywall: true,
            }),
          )
          .unwrap(),
      );
    }

    blockingPromises.push(
      submitDataGraphQLCall({
        type: SubmitDataType.SAT_BOOTCAMP_CATEGORY_SCORES,
        metadata: {
          mathScore: state.lastSATMathScore,
          readingWritingScore: state.lastSATReadingWritingScore,
        } as SATBootcampCategoryScoresMetadata,
      }).catch(e => {
        console.log('error', e);
      }),
    );

    updateUserProperties({
      SATTotalBaselineScore: mapUserSATCurrentScore(rootState),
      SATMathBaselineScore: state.lastSATMathScore,
      SATReadingWritingBaselineScore: state.lastSATReadingWritingScore,
    });

    trackAnalyticsEvent(Analytics.satPrepDataSubmitted, {
      from: BootcampEntryPoint.ADDITIONAL_DETAIL_PROMPT,
      lastReadingWritingScore: state.lastSATReadingWritingScore,
      lastMathScore: state.lastSATMathScore,
    });

    await Promise.all(blockingPromises);

    if (SATCourse?.id) {
      thunkAPI.dispatch(
        setCurrentSelectedCourse({
          id: SATCourse?.id,
          tab: SelectedCourseEntryPoint.AUTO_SELECT,
        }),
      );
    }

    thunkAPI.dispatch(
      setSatBootcampCategoryScores({
        mathScore: state.lastSATMathScore,
        readingWritingScore: state.lastSATReadingWritingScore,
      }),
    );
    thunkAPI.dispatch(getUserSATProgramDetails());

    navigation?.reset({
      index: 0,
      routes: [
        {
          name: MainStack.BOTTOM_TABS,
          params: {
            screen: TabNames.TEST,
          },
        },
      ],
    });

    thunkAPI.dispatch(resetExamInputData());
  },
);

export const submitSATCategoryScoreExtraReducers = (
  builder: ActionReducerMapBuilder<OnboardingSliceState>,
): void => {
  builder.addCase(submitSATCategoryScore.pending, state => {
    state.isLoading = true;
  });

  builder.addCase(submitSATCategoryScore.fulfilled, state => {
    state.isLoading = false;
  });

  builder.addCase(submitSATCategoryScore.rejected, state => {
    state.isLoading = false;
  });
};
