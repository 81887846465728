import { createAsyncThunk } from '@reduxjs/toolkit';

import { getUserApProgramDetails } from '../../../../AP/services/slices';
import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import {
  getUserProfile,
  updateMixpanelUserProfile,
} from '../../../../Auth/services/slices';
import {
  CommonExamType,
  CommonTrueFalse,
  ProgramAccessType,
} from '../../../../Common/entities';
import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import {
  enrollUserToCourse,
  setShowCourseListOnboarding,
  setShowCourseListStudyTab,
} from '../../../../CourseEnrollment/services/slices';
import { mapSATCourseData } from '../../../../Learn/services/mappers';
import { setApProgramPaywallLoading } from '../../../../Paywall/services/slices';
import { setShowTutorialScreen } from '../../../../Tutorial/services/slices';
import { getUserAccess } from '../../../../UserAccess/services/slices';
import {
  BootcampEntryPoint,
  ProceedApProgramFlowAfterPurchaseParams,
} from '../../../entities';
import { AP_ONBOARDING_SLICE_NAME } from '../ApOnboardingSlice';
import {
  setDoOnboardingFlowAfterSignup,
  setExamType,
  setIsOnboardingCompleted,
  setShowEntitlementScreen,
  setSignUpExamType,
} from '../OnboardingSlice';
import { trackEntitlementRedemption } from '../OnboardingSliceActions';

import { proceedRedirectionAfterApProgramPurchase } from './ProceedRedirectionAfterApProgramPurchaseAction';
import { recordApOnboardingTargetsAction } from './RecordApOnboardingTargetsAction';

export const proceedApProgramFlowAfterPurchase = createAsyncThunk(
  `${AP_ONBOARDING_SLICE_NAME}/proceedApProgramFlowAfterPurchase`,
  async (props: ProceedApProgramFlowAfterPurchaseParams, thunkApi) => {
    try {
      await thunkApi
        .dispatch(getUserProfile({ silent: true, skipAfterAuth: true }))
        .unwrap();
      await thunkApi.dispatch(recordApOnboardingTargetsAction()).unwrap();
      await thunkApi.dispatch(getUserAccess()).unwrap();

      const rootState = thunkApi.getState() as RootState;
      const courseEnrollmentState = rootState.courseEnrollments;
      const courses = rootState.courses.coursesMap;
      const selectedCourses = rootState.apOnboarding.selectedCourses;

      let blockingPromises: Promise<unknown>[] = [];

      for (const courseName of selectedCourses) {
        const course = Object.values(courses).find(c => c.name === courseName);
        if (
          !course ||
          courseEnrollmentState?.following?.find(f => f.id === course.id)
        ) {
          continue;
        }
        blockingPromises.push(
          thunkApi
            .dispatch(
              enrollUserToCourse({
                course,
                isFromCoursePicker: false,
                skipRedirectToPaywall: true,
              }),
            )
            .unwrap(),
        );
        trackAnalyticsEvent(Analytics.followCourse, {
          from: BootcampEntryPoint.AP_ONBOARDING,
          course: course.name,
        });
      }

      if (rootState.userAccess.hasSATPrepAccess) {
        const SATCourse = mapSATCourseData(rootState);
        if (SATCourse) {
          const followedSATCourse = courseEnrollmentState?.following?.find(
            f => f.id === SATCourse.id,
          );

          if (!followedSATCourse) {
            blockingPromises.push(
              thunkApi
                .dispatch(
                  enrollUserToCourse({
                    course: SATCourse,
                    isFromCoursePicker: false,
                    skipRedirectToPaywall: true,
                  }),
                )
                .unwrap(),
            );
            trackAnalyticsEvent(Analytics.followCourse, {
              from: BootcampEntryPoint.AP_ONBOARDING,
              course: SATCourse.name,
            });
          }
        }
      }

      thunkApi.dispatch(setShowTutorialScreen(false));
      thunkApi.dispatch(setShowCourseListOnboarding(false));
      thunkApi.dispatch(setShowCourseListStudyTab(false));
      thunkApi.dispatch(setExamType(CommonExamType.AP));

      await Promise.all(blockingPromises);
      await thunkApi.dispatch(getUserApProgramDetails()).unwrap();

      thunkApi.dispatch(updateMixpanelUserProfile());
      if (
        props.bootcampEntryPoint === BootcampEntryPoint.UNCLAIMED_ENTITLEMENT
      ) {
        thunkApi.dispatch(
          trackEntitlementRedemption({
            from: BootcampEntryPoint.UNCLAIMED_ENTITLEMENT,
            entitlementType: {
              ap: ProgramAccessType.FULL_ACCESS,
            },
          }),
        );
      } else {
        trackAnalyticsEvent(Analytics.purchasedAPProgram, {
          from: rootState.onboarding.bootcampEntryPoint,
          canBeSkipped: CommonTrueFalse.FALSE,
        });
      }

      thunkApi.dispatch(setShowEntitlementScreen(false));
      thunkApi.dispatch(setDoOnboardingFlowAfterSignup(false));

      if (props?.redirectAfterPurchase) {
        thunkApi.dispatch(setIsOnboardingCompleted(true));
        thunkApi.dispatch(
          proceedRedirectionAfterApProgramPurchase({
            shouldShowUserNameAndPictureScreen:
              rootState.onboarding.shouldShowUsernamePictureScreen,
          }),
        );
      }
      thunkApi.dispatch(setApProgramPaywallLoading(false));
      thunkApi.dispatch(setSignUpExamType(undefined));

      return true;
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
      }
      console.log('error', e);
      return false;
    }
  },
);
