import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { handleNetworkActionErrorSilently } from '../../../App/services/utils';
import { RootState } from '../../../App/store';
import { setIsDataSubmitted } from '../../../ApResults/services/slices';
import {
  checkIfShouldSkipActionWithCoolDown,
  isDevelopmentEnv,
  updateUserProperties,
} from '../../../Common/services/utils';
import { DeviceInfo } from '../../../Common/services/utils/polyfills';
import { setSATFreePointsClaimed } from '../../../Onboarding/services/slices';
import {
  assignSATLastExamMetadata,
  setOpenSATResultsFlag,
} from '../../../SATResults/services/slices';
import { ALL_TEACHTAP_USER_FLAGS } from '../../data';
import { GetUserStatsParams, TeachTapUserFlag } from '../../entities';
import {
  getUserFlagsGraphQLCall,
  getUserStatsGraphQLCall,
  upsertUserSettingsGraphQLCall,
} from '../../graphql';

import {
  deleteProfileImageExtraReducers,
  updateUserProfileImageExtraReducers,
} from './UserProfileUpdateActions';

export const PROFILE_SLICE_NAME = 'ProfileSlice';
const USER_STATS_HISTORY_COOLDOWN = 60 * 60 * 1000; // 1 HOUR

const persistConfig = {
  key: PROFILE_SLICE_NAME,
  whitelist: [
    'totalCorrectAnswers',
    'totalAwardedPoints',
    'userStatsLastFetchedAt',
    'marketingConsent',
  ],
  storage: AsyncStorage,
} as PersistConfig<ProfileSliceState>;

export const updateEmailNotificationSettings = createAsyncThunk(
  `${PROFILE_SLICE_NAME}/updateEmailNotificationInSettings`,
  async ({ emailOptIn }: { emailOptIn: boolean }, thunkApi) => {
    try {
      await upsertUserSettingsGraphQLCall({ emailOptIn });
      thunkApi.dispatch(setSignupMarketingConsent(emailOptIn));
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);

export const getUserStats = createAsyncThunk(
  `${PROFILE_SLICE_NAME}/getUserStats`,
  async (params: GetUserStatsParams | undefined, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const state = rootState.profile;

      const shouldSkip = checkIfShouldSkipActionWithCoolDown(
        USER_STATS_HISTORY_COOLDOWN,
        state.userStatsLastFetchedAt,
        params?.enforce,
      );

      if (shouldSkip) {
        return { result: undefined, skip: true };
      }

      const result = await getUserStatsGraphQLCall();
      return { result, skip: false };
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);

export const getAndAssignUserFlags = createAsyncThunk(
  `${PROFILE_SLICE_NAME}/getAndAssignUserFlags`,
  async (_, thunkApi) => {
    try {
      const userFlagsData = await getUserFlagsGraphQLCall({
        buildNumber: isDevelopmentEnv() ? '0.00' : DeviceInfo.getVersion(),
        keys: ALL_TEACHTAP_USER_FLAGS,
      });

      if (!userFlagsData.flags.length) {
        return;
      }

      const isApResultsSubmittedFlag = userFlagsData.flags.find(
        flag => flag.key === TeachTapUserFlag.AP_RESULTS_SUBMITTED,
      );
      const isSATFreePointsClaimedFlag = userFlagsData.flags.find(
        flag => flag.key === TeachTapUserFlag.SAT_FREE_POINTS_AVAILED,
      );
      const isShowSATResultsScreenFlag = userFlagsData.flags.find(
        flag => flag.key === TeachTapUserFlag.SHOW_SAT_RESULTS_SCREEN,
      );

      thunkApi.dispatch(
        setIsDataSubmitted(isApResultsSubmittedFlag?.value ?? false),
      );
      thunkApi.dispatch(
        setSATFreePointsClaimed(isSATFreePointsClaimedFlag?.value ?? false),
      );

      thunkApi.dispatch(
        setOpenSATResultsFlag(isShowSATResultsScreenFlag?.value ?? false),
      );
      thunkApi.dispatch(
        assignSATLastExamMetadata(
          isShowSATResultsScreenFlag?.metadata as string,
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);

export type ProfileSliceState = {
  isLoading: boolean;
  marketingConsent: boolean;

  userStatsLastFetchedAt?: string;
  totalCorrectAnswers: number;
  totalAwardedPoints: number;

  isProfileImageLoading: boolean;
};

const initialState: ProfileSliceState = {
  isLoading: false,
  marketingConsent: false,
  totalCorrectAnswers: 0,
  totalAwardedPoints: 0,

  isProfileImageLoading: false,
};

const slice = createSlice({
  name: PROFILE_SLICE_NAME,
  initialState: initialState,
  reducers: {
    resetProfileState: state => {
      state.totalCorrectAnswers = 0;
      state.totalAwardedPoints = 0;
      state.userStatsLastFetchedAt = undefined;
      state.isLoading = false;
      state.marketingConsent = false;
    },
    setSignupMarketingConsent: (state, action: PayloadAction<boolean>) => {
      state.marketingConsent = action.payload;
    },
    incrementCorrectAnswers: (state, action: PayloadAction<number>) => {
      state.totalCorrectAnswers += action.payload;
    },
    setTotalAwardedPoints: (state, action: PayloadAction<number>) => {
      state.totalAwardedPoints = action.payload;
      updateUserProperties({
        points: action.payload,
      });
    },
    incrementAwardedPoints: (state, action: PayloadAction<number>) => {
      const totalPoints = state.totalAwardedPoints + action.payload;
      state.totalAwardedPoints = totalPoints;
      updateUserProperties({
        points: totalPoints,
      });
    },
  },
  extraReducers: builder => {
    updateUserProfileImageExtraReducers(builder);
    deleteProfileImageExtraReducers(builder);

    builder.addCase(getUserStats.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getUserStats.fulfilled, (state, action) => {
      state.isLoading = false;

      if (action.payload?.skip) {
        return;
      }

      const result = action.payload?.result;

      state.totalCorrectAnswers = result?.totalCorrectQuestions || 0;
      state.totalAwardedPoints = result?.totalAwardedPoints || 0;
      state.userStatsLastFetchedAt = new Date().toISOString();
    });
    builder.addCase(getUserStats.rejected, state => {
      state.isLoading = false;
    });
  },
});

export const {
  setSignupMarketingConsent,
  incrementCorrectAnswers,
  incrementAwardedPoints,
  setTotalAwardedPoints,
  resetProfileState,
} = slice.actions;

export const ProfileSlice = persistReducer(persistConfig, slice.reducer);
