import { getSATLastScoreData } from '../mappers';

export const getSATLastScoreValueByKey = (
  lastScoreKey?: string,
): number | undefined => {
  if (!lastScoreKey) {
    return undefined;
  }

  return (
    getSATLastScoreData().find(item => item.key === lastScoreKey)?.value ||
    undefined
  );
};

export const getSATLastScoreKeyByValue = (
  lastScoreValue?: number,
): string | undefined => {
  if (!lastScoreValue) {
    return undefined;
  }

  return (
    getSATLastScoreData().find(item => item.value === lastScoreValue)?.key ||
    undefined
  );
};
