import { CommonTrueFalse } from '../../../Common/entities';
import {
  MapProfileDetailsUpdateAnalyticsParams,
  MapProfileDetailsUpdateAnalyticsType,
  ProfileUpdateEntryPoints,
} from '../../entities';

export const mapProfileDetailsUpdateAnalytics = (
  params: MapProfileDetailsUpdateAnalyticsParams,
): MapProfileDetailsUpdateAnalyticsType => {
  const profileImageUpdated = params.profileImageUpdated
    ? CommonTrueFalse.TRUE
    : CommonTrueFalse.FALSE;
  const nameUpdated = params.nameUpdated
    ? CommonTrueFalse.TRUE
    : CommonTrueFalse.FALSE;
  const usernameUpdated = params.usernameUpdated
    ? CommonTrueFalse.TRUE
    : CommonTrueFalse.FALSE;
  const passwordChanged = params.passwordChanged
    ? CommonTrueFalse.TRUE
    : CommonTrueFalse.FALSE;

  return {
    from: params.from || ProfileUpdateEntryPoints.PROFILE,
    profileImageUpdated,
    nameUpdated,
    usernameUpdated,
    passwordChanged,
  };
};
