import { RootState } from '../../../App/store';
import { Course } from '../../../Common/entities';
import { mapSATCourseData } from '../../../Learn/services/mappers';
import { CourseWithEnrollmentData } from '../../entities';

export const getEnrolledCoursesData = (state: RootState): Course[] => {
  const followedCoursesPreviewData = state.courseEnrollments.following;
  if (!followedCoursesPreviewData?.length) {
    return [];
  }

  const coursesMap = state.courses.coursesMap;

  return followedCoursesPreviewData
    .map(coursePreview => {
      return coursesMap[coursePreview.id];
    })
    .filter(course => !!course);
};

export const getEnrolledCoursesWithDetails = (
  state: RootState,
): CourseWithEnrollmentData[] => {
  const getEnrolledCourses = getEnrolledCoursesData(state);
  if (!getEnrolledCourses.length) {
    return [];
  }

  const followedCoursesPreviewData = state.courseEnrollments.following;
  const results: Array<null | CourseWithEnrollmentData> =
    followedCoursesPreviewData.map(coursePreview => {
      const course = getEnrolledCourses.find(
        enrolledCourse => enrolledCourse.id === coursePreview.id,
      );
      if (!course) {
        return null;
      }

      return {
        enrolledOn: coursePreview.enrolledOn,
        goalKey: coursePreview.goalKeys,
        course,
      };
    });

  return results.filter(course =>
    Boolean(course),
  ) as CourseWithEnrollmentData[];
};

export const mapUserFollowingSATCourseStatus = (state: RootState): boolean => {
  const SATCourse = mapSATCourseData(state);
  if (!SATCourse) {
    return false;
  }

  return Boolean(
    state.courseEnrollments.following.find(f => f.id === SATCourse.id),
  );
};
