import { AppSchoolName } from '../../../App/entities';
import { RootState } from '../../../App/store';

export const mapUsername = (state: RootState): string => {
  const authUser = state.auth.authUser;

  if (!authUser?.userEnrollments?.length) {
    return '';
  }

  const teachTapEnrollment = authUser.userEnrollments.find(
    userEnrollment =>
      userEnrollment.school === AppSchoolName.TEACHTAP &&
      userEnrollment.username,
  );
  const userEnrollmentWithUsername = authUser.userEnrollments.find(
    userEnrollment => userEnrollment.username,
  );

  const selectedUsername =
    teachTapEnrollment?.username || userEnrollmentWithUsername?.username;

  return selectedUsername?.replace(/\$/g, '') || '';
};
