import moment from 'moment';
import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../../App/locale';
import { useAppSelector } from '../../../App/services/hooks';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { SATExamSummaryCountdown } from '../../../Onboarding/components/summary/SATExamSummaryCountdown';
import { mapIsPaywallFromSATFreeAccess } from '../../services/mappers';

export const BootcampPaywallDetails = (): React.ReactElement => {
  const programDetails = useAppSelector(
    state => state.onboarding.SATProgramDetails,
  );
  const isFromFreeAccess = useAppSelector(mapIsPaywallFromSATFreeAccess);

  const duration = useMemo(() => {
    return programDetails?.programDuration || 'N/A';
  }, [programDetails]);

  const deadlineToEnroll = useMemo(() => {
    return (
      programDetails?.deadlineToEnroll || locale.bootcamp_paywall.enroll_by
    );
  }, [programDetails]);

  const leftDetails = useMemo(() => {
    return {
      title: isFromFreeAccess
        ? locale.bootcamp_paywall.bootcamp_ends_on
        : locale.bootcamp_paywall.duration_label,
      description:
        isFromFreeAccess && programDetails?.examDate
          ? moment(programDetails.examDate, 'YYYY-MM-DD').format('MMMM DD')
          : duration,
    };
  }, [programDetails, isFromFreeAccess, duration]);

  const rightDetails = useMemo(() => {
    return {
      title: isFromFreeAccess
        ? locale.bootcamp_paywall.time_till_test_day
        : locale.bootcamp_paywall.enroll_label,
    };
  }, [isFromFreeAccess]);

  return (
    <View style={styles.detailsSection}>
      <View style={styles.detailsBox}>
        <Text style={styles.detailsTitle}>{leftDetails.title}</Text>
        <Text style={styles.detailsText}>{leftDetails.description}</Text>
      </View>

      <View style={styles.detailsBox}>
        <Text style={styles.detailsTitle}>{rightDetails.title}</Text>

        {isFromFreeAccess ? (
          <SATExamSummaryCountdown
            isFromOnboarding={false}
            showCountDownOnly
            textStyle={styles.detailsText}
          />
        ) : (
          <Text style={styles.detailsText}>{deadlineToEnroll}</Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  detailsSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: Sizes.semiMedium,
  },
  detailsBox: {
    backgroundColor: Colors.white10,
    padding: 10,
    borderRadius: 10,
    flex: 1,
    rowGap: 2,
  },
  detailsTitle: {
    ...Fonts.small,
    color: Colors.white60,
  },
  detailsText: {
    ...Fonts.semiLargeBold,
  },
});
