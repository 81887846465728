import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { getUserLocalTimeStamp } from '../../../../Common/services/utils';
import {
  GetSATProgramDetailsParams,
  GetSATProgramDetailsRequest,
} from '../../../entities';
import { getSATProgramDetailsGraphQLCall } from '../../../graphql';
import { getSATLastScoreData } from '../../mappers';
import {
  checkIfSATInputValid,
  getSATLastScoreKeyByValue,
  getSATLastScoreValueByKey,
} from '../../utils';
import {
  ONBOARDING_SLICE_NAME,
  OnboardingSliceState,
} from '../OnboardingSlice';

import { setExamLastScoreKey } from './SetExamLastScoreKeyAction';

export const getSATProgramDetails = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/getSATProgramDetails`,
  async (props: GetSATProgramDetailsParams | undefined, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const state = rootState.onboarding;
      const examLastScore = getSATLastScoreValueByKey(state.examLastScoreKey);

      const params = {
        examLastScore: props?.examLastScore || examLastScore,
        examTargetScore: props?.examTargetScore || state.examTargetScore,
        examDate: props?.examDate || state.examDate,
      };

      const isRequestDataValid = checkIfSATInputValid(params);
      if (!isRequestDataValid) {
        return { skip: true, value: undefined };
      }

      const smallestCurrentScore = getSATLastScoreData()[0].value;

      const requestData: GetSATProgramDetailsRequest = {
        examDate: params.examDate || '',
        currentScore: params.examLastScore || smallestCurrentScore,
        targetScore: params.examTargetScore || 0,
        userLocalTimestamp: getUserLocalTimeStamp(),
      };

      const response = await getSATProgramDetailsGraphQLCall(requestData);
      const currentScoreKey = getSATLastScoreKeyByValue(
        requestData.currentScore,
      );

      if (!examLastScore && !props?.skipAutoAssign && currentScoreKey) {
        thunkApi.dispatch(setExamLastScoreKey(currentScoreKey));
      }

      return {
        skip: false,
        value: {
          ...response,
          ...requestData,
        },
      };
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
      }

      return {
        skip: true,
        value: undefined,
      };
    }
  },
);

export const getSATProgramDetailsExtraReducers = (
  builder: ActionReducerMapBuilder<OnboardingSliceState>,
): void => {
  builder.addCase(getSATProgramDetails.pending, (state, action) => {
    state.loadingSATProgramDetails = true;

    if (!action.meta.arg?.skipResetOnPending) {
      state.SATProgramDetails = undefined;
    }
  });
  builder.addCase(getSATProgramDetails.rejected, state => {
    state.loadingSATProgramDetails = false;
  });
  builder.addCase(getSATProgramDetails.fulfilled, (state, action) => {
    state.loadingSATProgramDetails = false;

    if (action.payload?.skip || !action.payload?.value) {
      return;
    }

    state.SATProgramDetails = {
      ...action.payload.value,
      lastMathScore: state.lastSATMathScore,
      lastReadingWritingScore: state.lastSATReadingWritingScore,
    };
  });
};
