import React, { useCallback, useMemo } from 'react';
import { Alert, StyleSheet, Text, View } from 'react-native';
import FastImage from 'react-native-fast-image';

import locale from '../../../App/locale';
import { useAppNavigation, useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import {
  CommonBackButton,
  CommonCloseButton,
} from '../../../Common/components';
import { useBottomTabNavigator } from '../../../Common/services/hooks';
import { formatNumber } from '../../../Common/services/utils';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { MINIMUM_FREE_POINTS_SCORE_FOR_PAYWALL } from '../../../Onboarding/data';
import { useBootcampCoursePicker } from '../../../Onboarding/services/hooks';
import { mapIsUserEligibleForSatBootcampDiscount } from '../../../Onboarding/services/mappers';
import {
  rejectSATBootcampUpsellFlow,
  setBootcampEntryPoint,
} from '../../../Onboarding/services/slices';
import {
  mapSatBootcampBoostedScore,
  mapSatBootcampFreePoints,
} from '../../../SAT/services/mappers';
import { useBootcampPaywallData } from '../../services/hooks';
import { mapIsPaywallFromSATFreeAccess } from '../../services/mappers';
import { skipAndTrackSatBootcampPaywall } from '../../services/slices';
import { CompetitorLogoIcons } from '../../services/utils/PaywallUtils';

export const BootcampPaywallHeader = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const bottomTabNavigator = useBottomTabNavigator();
  const navigation = useAppNavigation();

  const programDetails = useAppSelector(
    state => state.onboarding.SATProgramDetails,
  );
  const fromSignupFlow = useAppSelector(
    state => state.onboarding.doOnboardingFlowAfterSignup,
  );

  const shouldUseDiscount = useAppSelector(state =>
    mapIsUserEligibleForSatBootcampDiscount(state),
  );

  const { handleCloseButtonPress, isBootcampFromCoursePicker } =
    useBootcampCoursePicker();

  const { shouldAllowSATFreeAccess } = useBootcampPaywallData();
  const isFromFreeAccess = useAppSelector(mapIsPaywallFromSATFreeAccess);
  const claimedFreePoints = useAppSelector(mapSatBootcampFreePoints);
  const boostedScore = useAppSelector(mapSatBootcampBoostedScore);

  const isLoading = useAppSelector(
    state =>
      state.purchases.isLoading ||
      state.bootcampPaywall.isLoading ||
      state.onboarding.isLoading,
    (prev, next) => prev === next,
  );

  const headerButtonView = useMemo(() => {
    return {
      freeAccess: shouldAllowSATFreeAccess || isFromFreeAccess,
      exitFlow:
        !fromSignupFlow && !shouldAllowSATFreeAccess && !isFromFreeAccess,
    };
  }, [fromSignupFlow, shouldAllowSATFreeAccess, isFromFreeAccess]);

  const content = useMemo(() => {
    const halfFreePoints = Math.round(claimedFreePoints / 2);

    let description = locale.bootcamp_paywall.description;
    if (
      isFromFreeAccess &&
      boostedScore >= halfFreePoints &&
      boostedScore >= MINIMUM_FREE_POINTS_SCORE_FOR_PAYWALL
    ) {
      description = locale.bootcamp_paywall.description_boost_points.replace(
        '{BOOSTED_POINTS}',
        formatNumber(boostedScore),
      );
    }

    let title = programDetails?.programName || 'N/A';
    if (isFromFreeAccess) {
      title = locale.sat.digital_bootcamp;
    }

    return {
      title,
      description,
      headline: locale.bootcamp_paywall.headline.replace(
        '${SCORE}',
        programDetails?.targetScore?.toString() ?? 'N',
      ),
      duration: programDetails?.programDuration || 'N/A',
      dedication: programDetails?.studyCommitmentHours.toString() || 'N',
    };
  }, [programDetails, isFromFreeAccess, boostedScore, claimedFreePoints]);

  const handleCloseButton = useCallback(() => {
    Alert.alert(
      locale.bootcamp_paywall.miss_out_heading,
      locale.bootcamp_paywall.miss_out_description,
      [
        {
          text: locale.bootcamp_paywall.go_back,
          style: 'cancel',
        },
        {
          text: locale.bootcamp_paywall.skip_offer,
          style: 'destructive',
          onPress: () => {
            if (shouldUseDiscount) {
              dispatch(rejectSATBootcampUpsellFlow());
            }
            bottomTabNavigator.navigate();
          },
        },
      ],
    );
  }, [bottomTabNavigator, dispatch, shouldUseDiscount]);

  const onCloseButtonPress = useCallback(() => {
    if (isFromFreeAccess) {
      dispatch(setBootcampEntryPoint(undefined));
      navigation.goBack();
      return;
    }

    if (shouldAllowSATFreeAccess) {
      dispatch(skipAndTrackSatBootcampPaywall());
      return;
    }

    if (isBootcampFromCoursePicker) {
      handleCloseButtonPress();
    } else {
      handleCloseButton();
    }
  }, [
    dispatch,
    handleCloseButton,
    handleCloseButtonPress,
    isBootcampFromCoursePicker,
    shouldAllowSATFreeAccess,
    isFromFreeAccess,
    navigation,
  ]);

  return (
    <View style={styles.header}>
      {headerButtonView.exitFlow && (
        <CommonBackButton
          onPress={navigation.goBack}
          containerStyle={styles.backButtonContainer}
        />
      )}

      {headerButtonView.freeAccess && (
        <CommonCloseButton
          onPress={onCloseButtonPress}
          size={Sizes.medium}
          disabled={isLoading}
          mainContainerStyle={styles.freeAccessButtonContainer}
        />
      )}

      <FastImage
        style={styles.logo}
        source={{
          uri: CompetitorLogoIcons.thumbnail,
        }}
      />
      <Text style={styles.title}>{content.title}</Text>
      <Text style={styles.mainHeading}>{content.headline}</Text>
      <Text style={styles.subheading}>{content.description}</Text>

      {headerButtonView.exitFlow && (
        <CommonCloseButton
          onPress={onCloseButtonPress}
          size={Sizes.medium}
          disabled={isLoading}
          mainContainerStyle={styles.closeButtonContainer}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    alignItems: 'center',
  },
  logo: {
    width: 40,
    height: 40,
  },
  title: {
    ...Fonts.small,
    color: Colors.emeraldGreen,
    marginTop: 10,
  },
  mainHeading: {
    ...Fonts.xxxlargeBold,
    marginTop: 2,
  },
  subheading: {
    ...Fonts.semiMedium,
    textAlign: 'center',
    marginTop: 2,
    color: Colors.white50,
  },

  backButtonContainer: {
    zIndex: 30,
    position: 'absolute',
    left: 0,
    top: Sizes.small,
  },

  freeAccessButtonContainer: {
    zIndex: 30,
    position: 'absolute',
    left: 0,
    top: Sizes.small,
    width: Sizes.xlarge,
  },

  closeButtonContainer: {
    right: 0,
    top: Sizes.small,
  },
});
