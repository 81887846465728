import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { CommonExamType } from '../../../Common/entities';
import { SAT_UNIT_CATEGORY } from '../../../Learn/entities';
import {
  BootcampEntryPoint,
  SATBootcampProgramDetails,
  SATClaimedAccessType,
  SATConfigsType,
  SetExamDatesPayload,
} from '../../entities';

import {
  getSATProgramDetailsExtraReducers,
  proceedSATBootcampFlowAfterPurchaseExtraReducers,
  setAndCalculateSATCategoryLastScoreExtraReducers,
  setExamLastScoreExtraReducers,
  submitSATCategoryScoreExtraReducers,
} from './OnboardingSliceActions';

const ONBOARDING_SLICE_PERSIST_KEY = 'Onboarding';
export const ONBOARDING_SLICE_NAME = 'OnboardingSlice';

export type OnboardingSliceState = {
  isLoading: boolean;
  introVideoCompleted: boolean;
  examType?: CommonExamType;
  signUpExamType?: CommonExamType;

  examDate?: string;
  examLastScoreKey?: string;
  examTargetScore?: number;

  lastSelectedSATUnitCategory?: SAT_UNIT_CATEGORY;
  lastSATMathScore?: number;
  lastSATReadingWritingScore?: number;

  SATConfigs?: SATConfigsType;

  loadingSATProgramDetails: boolean;
  SATProgramDetails?: SATBootcampProgramDetails;

  // Flag to indicate if the onboarding process is completed.
  // On skipping the onboarding process, this flag will be set to true.
  isOnboardingCompleted: boolean;

  // Flag to indicate if the user data is recorded to DB.
  isDataRecorded: boolean;

  // Flag to indicate if the notification request screen should be shown.
  showNotificationRequestScreen: boolean;

  // Flag to indicate if user should be shown the entitlement screen.
  showEntitlementScreen: boolean;
  hasEntitlementScreenVisible: boolean;

  // Flag to indicate redirection after signup success
  doOnboardingFlowAfterSignup: boolean;

  // Flags to handle flow from for Upsell
  shouldOpenSatBootcampFlowForUpsell: boolean;
  shouldUseSatBootcampDiscount: boolean;

  shouldPreventScreenListenerReset: boolean;

  bootcampEntryPoint?: BootcampEntryPoint;

  // Flag to indicate if the username and picture screen should be shown.
  shouldShowUsernamePictureScreen: boolean;

  SATClaimedAccessType?: SATClaimedAccessType;
  SATFreePointsClaimed: boolean;
};

export const OnboardingSliceInitialState: OnboardingSliceState = {
  isLoading: false,
  introVideoCompleted: false,
  isOnboardingCompleted: false,
  examType: undefined,
  isDataRecorded: false,

  examDate: undefined,
  examLastScoreKey: undefined,
  examTargetScore: undefined,

  lastSelectedSATUnitCategory: undefined,
  lastSATMathScore: undefined,
  lastSATReadingWritingScore: undefined,

  loadingSATProgramDetails: false,
  SATProgramDetails: undefined,

  showNotificationRequestScreen: false,
  showEntitlementScreen: false,
  hasEntitlementScreenVisible: false,

  shouldOpenSatBootcampFlowForUpsell: false,
  shouldUseSatBootcampDiscount: false,
  shouldPreventScreenListenerReset: false,
  doOnboardingFlowAfterSignup: false,

  shouldShowUsernamePictureScreen: false,
  SATClaimedAccessType: undefined,
  SATFreePointsClaimed: false,
};

const persistConfig = {
  key: ONBOARDING_SLICE_PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: [
    'introVideoCompleted',
    'examType',
    'signUpExamType',
    'isOnboardingCompleted',
    'isDataRecorded',
    'examDate',
    'examLastScoreKey',
    'examTargetScore',
    'SATConfigs',
    'SATProgramDetails',

    'showEntitlementScreen',

    'shouldOpenSatBootcampFlowForUpsell',
    'shouldUseSatBootcampDiscount',
    'showNotificationRequestScreen',

    'bootcampEntryPoint',
    'doOnboardingFlowAfterSignup',
    'hasEntitlementScreenVisible',
    'shouldShowUsernamePictureScreen',

    'lastSelectedSATUnitCategory',
    'lastSATMathScore',
    'lastSATReadingWritingScore',

    'SATClaimedAccessType',
    'SATFreePointsClaimed',
  ],
  blacklist: ['isLoading', 'setShouldPreventScreenListenerReset'],
} as PersistConfig<OnboardingSliceState>;

const onboardingSlice = createSlice({
  name: ONBOARDING_SLICE_NAME,
  initialState: OnboardingSliceInitialState,
  extraReducers: builder => {
    proceedSATBootcampFlowAfterPurchaseExtraReducers(builder);
    getSATProgramDetailsExtraReducers(builder);
    setAndCalculateSATCategoryLastScoreExtraReducers(builder);
    setExamLastScoreExtraReducers(builder);
    submitSATCategoryScoreExtraReducers(builder);
  },
  reducers: {
    resetOnboardingState: state => {
      state.examType = undefined;
      state.signUpExamType = undefined;
      state.isDataRecorded = false;
      state.isOnboardingCompleted = false;

      state.examDate = undefined;
      state.examLastScoreKey = undefined;
      state.examTargetScore = undefined;
      state.SATProgramDetails = undefined;

      state.shouldOpenSatBootcampFlowForUpsell = false;
      state.shouldUseSatBootcampDiscount = false;
      state.bootcampEntryPoint = undefined;
      state.shouldPreventScreenListenerReset = false;

      state.showEntitlementScreen = false;
      state.hasEntitlementScreenVisible = false;
      state.doOnboardingFlowAfterSignup = false;
      state.shouldShowUsernamePictureScreen = false;

      state.lastSelectedSATUnitCategory = undefined;
      state.lastSATMathScore = undefined;
      state.lastSATReadingWritingScore = undefined;

      state.SATClaimedAccessType = undefined;
      state.SATFreePointsClaimed = false;
    },
    resetExamInputData: state => {
      state.examDate = undefined;
      state.examLastScoreKey = undefined;
      state.examTargetScore = undefined;
      state.lastSelectedSATUnitCategory = undefined;
      state.lastSATMathScore = undefined;
      state.lastSATReadingWritingScore = undefined;
    },
    resetSATProgramDetails: state => {
      state.SATProgramDetails = undefined;
    },
    setIntroVideoCompleted: (state, action: PayloadAction<boolean>) => {
      state.introVideoCompleted = action.payload;
    },
    setExamType: (state, action: PayloadAction<CommonExamType | undefined>) => {
      state.examType = action.payload;
    },
    setIsOnboardingCompleted: (state, action: PayloadAction<boolean>) => {
      state.isOnboardingCompleted = action.payload;
    },
    setIsDataRecorded: (state, action: PayloadAction<boolean>) => {
      state.isDataRecorded = action.payload;
    },
    setShouldPreventScreenListenerReset: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.shouldPreventScreenListenerReset = action.payload;
    },

    setExamDate: (state, action: PayloadAction<string>) => {
      state.examDate = action.payload;
    },
    setExamTargetScore: (state, action: PayloadAction<number>) => {
      state.examTargetScore = action.payload;
    },
    setShowNotificationRequestScreen: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showNotificationRequestScreen = action.payload;
    },
    setShowEntitlementScreen: (state, action: PayloadAction<boolean>) => {
      state.showEntitlementScreen = action.payload;
    },

    setSATConfigs: (state, action: SetExamDatesPayload) => {
      state.SATConfigs = {
        examDates: action.payload.examDates,
        minimumScore: action.payload.minimumScore,
        competitors: action.payload.competitors,
        testimonials: action.payload.testimonials,
        freePointsOffer: action.payload.freePointsOffer,
      };
    },

    setShouldOpenSatBootcampFlowForUpsell: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.shouldOpenSatBootcampFlowForUpsell = action.payload;
    },
    setShouldUseSatBootcampDiscount: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.shouldUseSatBootcampDiscount = action.payload;
    },

    setBootcampEntryPoint: (
      state,
      action: PayloadAction<BootcampEntryPoint | undefined>,
    ) => {
      state.bootcampEntryPoint = action.payload;
    },
    setDoOnboardingFlowAfterSignup: (state, action: PayloadAction<boolean>) => {
      state.doOnboardingFlowAfterSignup = action.payload;
    },
    setHasEntitlementScreenVisible: (state, action: PayloadAction<boolean>) => {
      state.hasEntitlementScreenVisible = action.payload;
    },
    setSignUpExamType: (
      state,
      action: PayloadAction<CommonExamType | undefined>,
    ) => {
      state.signUpExamType = action.payload;
    },
    setShouldShowUsernamePictureScreen: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.shouldShowUsernamePictureScreen = action.payload;
    },
    setLastSelectedSATUnitCategory: (
      state,
      action: PayloadAction<SAT_UNIT_CATEGORY | undefined>,
    ) => {
      state.lastSelectedSATUnitCategory = action.payload;
    },
    setSATClaimedAccessType: (
      state,
      action: PayloadAction<SATClaimedAccessType | undefined>,
    ) => {
      state.SATClaimedAccessType = action.payload;
    },
    setSATFreePointsClaimed: (state, action: PayloadAction<boolean>) => {
      state.SATFreePointsClaimed = action.payload;
    },
  },
});

export const {
  resetOnboardingState,
  setIntroVideoCompleted,
  setExamType,
  setSignUpExamType,
  setIsOnboardingCompleted,
  setIsDataRecorded,

  setExamDate,
  setExamTargetScore,
  setSATConfigs,

  setShowNotificationRequestScreen,
  setShowEntitlementScreen,
  setShouldUseSatBootcampDiscount,
  setShouldOpenSatBootcampFlowForUpsell,
  setBootcampEntryPoint,
  resetExamInputData,
  resetSATProgramDetails,
  setShouldPreventScreenListenerReset,
  setDoOnboardingFlowAfterSignup,
  setHasEntitlementScreenVisible,
  setShouldShowUsernamePictureScreen,

  setLastSelectedSATUnitCategory,
  setSATClaimedAccessType,
  setSATFreePointsClaimed,
} = onboardingSlice.actions;

export const OnboardingSlice = persistReducer(
  persistConfig,
  onboardingSlice.reducer,
);
