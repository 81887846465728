import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import {
  getTeachTapUserSettingsExtraReducers,
  loadAppExtraReducers,
} from './LifecycleSliceActions';

export enum LoadingItem {
  GetAllCourses = 'GET_ALL_COURSES',
  GetAllCoursesProgress = 'GET_ALL_COURSES_PROGRESS',
  RetrieveBookmarks = 'RETRIEVE_BOOKMARKS',
  GetUserProfile = 'GET_USER_PROFILE',
}

const PERSIST_KEY = 'lifecyle';
export const LIFECYCLE_SLICE_NAME = 'LifecycleSlice';

export type LifecycleSliceState = {
  appLoading: boolean;
  loadingItemsTimestamps: Record<LoadingItem, number>;
  deviceToken?: string;
  userGoal: Record<string, string>;
  isAppLoaded: boolean;
  isAppBusyLoading: boolean;

  // ISO string
  userSettingsCoolDown?: string;
  isAppInstalledEventFired: boolean;
};

const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: ['userGoal', 'userSettingsCoolDown', 'isAppInstalledEventFired'],
  blacklist: ['isAppLoaded'],
} as PersistConfig<LifecycleSliceState>;

const initialState: LifecycleSliceState = {
  appLoading: true,
  deviceToken: undefined,
  loadingItemsTimestamps: {
    [LoadingItem.GetAllCourses]: 0,
    [LoadingItem.GetAllCoursesProgress]: 0,
    [LoadingItem.RetrieveBookmarks]: 0,
    [LoadingItem.GetUserProfile]: 0,
  },
  userGoal: {},
  isAppLoaded: false,
  isAppBusyLoading: false,
  isAppInstalledEventFired: false,
};

const slice = createSlice({
  name: LIFECYCLE_SLICE_NAME,
  initialState: initialState,
  reducers: {
    resetLifeCycleState: state => {
      // never reset device token within this action, we'll need to handle it after unregister-call action
      state.userSettingsCoolDown = undefined;
    },
    setDeviceToken: (state, action: { payload: string }) => {
      state.deviceToken = action.payload;
    },
    resetDeviceToken: state => {
      state.deviceToken = undefined;
    },
    countLoadingItem: (state, action: { payload: LoadingItem }) => {
      const loadingItem = action.payload;
      state.loadingItemsTimestamps[loadingItem] = Date.now();
    },
    setUserSettingsCoolDown: (state, action: { payload: string }) => {
      state.userSettingsCoolDown = action.payload;
    },
    setIsAppBusyLoading: (state, action: { payload: boolean }) => {
      state.isAppBusyLoading = action.payload;
    },
    setIsAppInstalledEventFired: (state, action: { payload: boolean }) => {
      state.isAppInstalledEventFired = action.payload;
    },
  },
  extraReducers: builder => {
    loadAppExtraReducers(builder);
    getTeachTapUserSettingsExtraReducers(builder);
  },
});

export const {
  countLoadingItem,
  setDeviceToken,
  resetDeviceToken,
  setUserSettingsCoolDown,
  resetLifeCycleState,
  setIsAppBusyLoading,
  setIsAppInstalledEventFired,
} = slice.actions;

export const LifecycleSlice = persistReducer(persistConfig, slice.reducer);
