import * as Sentry from '@sentry/react-native';
import { AxiosError } from 'axios';
import { showMessage } from 'react-native-flash-message';

import { mapAxiosError } from '../../../Common/services/mappers';
import { showErrorMessage } from '../../../Common/services/utils';
import locale from '../../locale';
import store, { RootState } from '../../store';

const TIME_TO_SHOW_ERROR = 10000;

// Duplicated these fields with the fields in reducer because it is necessary to get rid of circular dependencies
let showServerErrors = false;
let stickyErrorMessages = false;

export type ErrorMessageType = { message: string };
export type NetworkError = AxiosError | Error | ErrorMessageType;

const isAxiosError = (error: NetworkError): error is AxiosError => {
  return (error as AxiosError).isAxiosError;
};

export const setUtilsShowServerErrors = (value: boolean): void => {
  showServerErrors = value;
};

export const setUtilsStickyErrorMessages = (value: boolean): void => {
  stickyErrorMessages = value;
};

export const handleNetworkActionErrorSilently = (error: NetworkError): void => {
  const extraPayload = (error as any).extraPayload;

  if (extraPayload) {
    Sentry.captureException(error, {
      extra: extraPayload,
    });
  } else {
    Sentry.captureException(error);
  }

  if (!isAxiosError(error)) {
    console.log(error);
  } else {
    console.log(mapAxiosError(error));
  }
};

export const handleNetworkActionError = (error: NetworkError): void => {
  console.log(error);

  const extraPayload = (error as any).extraPayload;

  if (extraPayload) {
    Sentry.captureException(error, {
      extra: extraPayload,
    });
  } else {
    Sentry.captureException(error);
  }

  const state = store.getState().network;
  if (state.isOnline) {
    if (!isAxiosError(error)) {
      showErrorMessage({
        message: locale.errors.something_went_wrong,
        description: showServerErrors ? JSON.stringify(error, null, 2) : '',
        duration: showServerErrors ? 5000 : 2000,
        autoHide: !stickyErrorMessages,
      });
    } else {
      showErrorMessage({
        message: locale.errors.load_content_error,
        description: showServerErrors
          ? JSON.stringify(mapAxiosError(error), null, 2)
          : '',
        duration: showServerErrors ? 5000 : 2000,
        autoHide: !stickyErrorMessages,
      });
    }
  } else {
    showMessage({
      message: locale.errors.no_internet_connection,
      type: 'danger',
    });
  }
};

let lastFeedErrorTime = 0;

export const resetFeedErrorTime = (): void => {
  lastFeedErrorTime = 0;
};

export const handleFeedError = (error: NetworkError): void => {
  if (lastFeedErrorTime === 0) {
    lastFeedErrorTime = Date.now();
  } else if (
    lastFeedErrorTime > 0 &&
    Date.now() - lastFeedErrorTime > TIME_TO_SHOW_ERROR
  ) {
    handleNetworkActionError(error);
    lastFeedErrorTime = -1;
  }
};

export const handlePaywallErrorSilently = (error: Error): void => {
  Sentry.captureException(error);
};

export const getRootState = (): RootState => {
  return store.getState() as RootState;
};

export const getAppDispatch = (): typeof store.dispatch => {
  return store.dispatch;
};

export const getIfAllPersistSlicesRehydrated = (state: RootState): boolean => {
  const persistSlices = [
    state.aiFigure,
    state.questions,
    state.timer,
    state.settings,
    state.courseEnrollments,
    state.onboarding,
    state.auth,
    state.feedback,
    state.lifecycle,
    state.courses,
    state.messages,
    state.purchases,
    state.tutorial,
    state.notification,
    state.raiseHand,
    state.SAT,
    state.profile,
    state.bootcampPaywall,
    state.apResults,
    state.auth,
    state.apOnboarding,
    state.apProgramPaywall,
    state.userAccess,
    state.leaderboard,
  ];

  for (const slice of persistSlices) {
    if (!slice._persist.rehydrated) {
      return false;
    }
  }

  return true;
};
