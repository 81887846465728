import { SAT_UNIT_CATEGORY } from '../../../Learn/entities';
import { SAT_MAX_TOTAL_SCORE } from '../../../SAT/data';
import {
  SatScoreDetailsRangeType,
  SatScoreUnitCategoryScoreType,
} from '../../entities';

export const mapSatScoreDetailsRange = (
  lastScore?: number,
): SatScoreDetailsRangeType => {
  if (!lastScore || lastScore < 1000) {
    return {
      max: 450,
      min: 450,
    };
  }

  const defaultRwAndMathScore = SAT_MAX_TOTAL_SCORE / 2;

  const maxSectionScore = Math.min(
    defaultRwAndMathScore,
    SAT_MAX_TOTAL_SCORE - 400,
  );
  const minSectionScore = Math.max(lastScore - maxSectionScore, 400);

  return {
    max: maxSectionScore,
    min: minSectionScore,
  };
};

export const mapSatScoreEquallyDividedScore = (
  lastScore: number,
): SatScoreUnitCategoryScoreType => {
  if (!lastScore || lastScore < 1000) {
    return {
      math: 450,
      readingWriting: 450,
    };
  }

  const equallyDividedNumber = lastScore / 2;

  if (equallyDividedNumber % 10 === 0) {
    return {
      math: equallyDividedNumber,
      readingWriting: equallyDividedNumber,
    };
  }

  return {
    math: Math.floor(equallyDividedNumber / 10) * 10,
    readingWriting: Math.ceil(equallyDividedNumber / 10) * 10,
  };
};

export const mapSatScoreAutoSelection = (
  lastScore: number,
  lastSelectedCategory?: SAT_UNIT_CATEGORY,
  lastSelectedScore?: number,
): SatScoreUnitCategoryScoreType => {
  const { min, max } = mapSatScoreDetailsRange(lastScore);
  const equallyDividedScore = mapSatScoreEquallyDividedScore(lastScore);

  if (!lastSelectedCategory || !lastSelectedScore) {
    return {
      math: equallyDividedScore.math,
      readingWriting: equallyDividedScore.readingWriting,
      resetLastSelectedCategory: true,
    };
  }

  if (lastSelectedScore < min || lastSelectedScore > max) {
    return {
      math: equallyDividedScore.math,
      readingWriting: equallyDividedScore.readingWriting,
      resetLastSelectedCategory: true,
    };
  }

  const calculatedLastSelectedScore = lastScore - lastSelectedScore;
  if (lastSelectedScore && lastSelectedCategory) {
    if (
      calculatedLastSelectedScore < min ||
      calculatedLastSelectedScore > max
    ) {
      return {
        math: equallyDividedScore.math,
        readingWriting: equallyDividedScore.readingWriting,
        resetLastSelectedCategory: true,
      };
    }
  }

  if (lastSelectedCategory === SAT_UNIT_CATEGORY.MATH && lastSelectedScore) {
    return {
      math: lastSelectedScore,
      readingWriting: calculatedLastSelectedScore,
      resetLastSelectedCategory: false,
    };
  }

  if (lastSelectedCategory === SAT_UNIT_CATEGORY.READING && lastSelectedScore) {
    return {
      math: calculatedLastSelectedScore,
      readingWriting: lastSelectedScore,
      resetLastSelectedCategory: false,
    };
  }

  return {
    math: equallyDividedScore.math,
    readingWriting: equallyDividedScore.readingWriting,
    resetLastSelectedCategory: true,
  };
};

export const mapSatUnitCategoryScoreValues = (lastScore?: number): number[] => {
  if (!lastScore) {
    return [];
  }

  const { min, max } = mapSatScoreDetailsRange(lastScore);

  const values: number[] = [];
  for (let i = min; i <= max; i += 10) {
    values.push(i);
  }

  return values;
};
