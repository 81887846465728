import React, { useMemo } from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';

import { Colors, Fonts, Sizes } from '../services/utils/AppConstants';

import CircularProgress from './common/CircularProgress';

type Props = {
  percentage: number;
  backgroundColor?: string;
  strokeWidth?: number;
  strokeColor?: string;
  radius?: number;
  boostedScore: number;
  boostedProgress: number;
};

export const CommonProgressIndicator = (props: Props): React.ReactElement => {
  const {
    backgroundColor,
    strokeWidth,
    radius,
    strokeColor,
    percentage,
    boostedScore,
    boostedProgress,
  } = props;
  const managedPercentage = useMemo(() => {
    if (isNaN(percentage)) {
      return 0;
    }

    return Math.min(100, Math.max(0, percentage));
  }, [percentage]);

  const managedBoostedProgress = useMemo(() => {
    if (isNaN(boostedProgress)) {
      return 0;
    }

    return Math.min(100, Math.max(0, boostedProgress));
  }, [boostedProgress]);

  const viewStyle = useMemo<ViewStyle>(() => {
    const diameter = radius ? radius * 2 : Sizes.xlarge;
    const selectedStrokeWidth = strokeWidth ? strokeWidth : Sizes.small;
    const halfStrokeWidth = selectedStrokeWidth / 2;

    return {
      backgroundColor: backgroundColor ?? Colors.emeraldGreen20,
      borderRadius: diameter,
      height: diameter - Sizes.xsmall - halfStrokeWidth,
      width: diameter - Sizes.xsmall - halfStrokeWidth,
    };
  }, [backgroundColor, radius, strokeWidth]);

  const shouldShowBoostedText = useMemo(() => {
    return boostedScore !== undefined && boostedScore > 0;
  }, [boostedScore]);

  return (
    <CircularProgress
      percentage={managedPercentage}
      boostedProgress={managedBoostedProgress}
      radius={radius ?? Sizes.xlarge / 2}
      color={strokeColor ?? Colors.emeraldGreen}
      strokeWidth={strokeWidth ?? Sizes.small}>
      <View style={[styles.circle, viewStyle]}>
        {shouldShowBoostedText && (
          <Text style={styles.boostedText}>{`+${boostedScore}`}</Text>
        )}
      </View>
    </CircularProgress>
  );
};

const styles = StyleSheet.create({
  circle: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#1D4349',
  },
  boostedText: {
    ...Fonts.xsmall500,
    color: Colors.sliceLightGreen,
  },
});
