import { createAsyncThunk } from '@reduxjs/toolkit';

import { upsertUserSettingsGraphQLCall } from '../../../../Profile/graphql';
import { mapSignUpDataToUserSettings } from '../../../../Profile/services/mappers';
import { UpsertTeachTapUserSettingsParams } from '../../../entities';
import { RootState } from '../../../store';
import { handleNetworkActionErrorSilently } from '../../utils';
import {
  LIFECYCLE_SLICE_NAME,
  setUserSettingsCoolDown,
} from '../LifecycleSlice';

export const upsertTeachTapUserSettings = createAsyncThunk(
  `${LIFECYCLE_SLICE_NAME}/upsertTeachTapUserSettings`,
  async (params: UpsertTeachTapUserSettingsParams | undefined, thunkApi) => {
    try {
      // If this is the first time user has set a goal and they have no enrolled courses,
      // show the onboarding.
      const rootState = thunkApi.getState() as RootState;
      const onboardingState = rootState.onboarding;
      const signupState = rootState.signup;
      const profileState = rootState.profile;

      const examType = rootState.onboarding.examType;
      if (!examType) {
        return;
      }

      await upsertUserSettingsGraphQLCall(
        mapSignUpDataToUserSettings({
          examType: examType,
          emailOptIn: profileState.marketingConsent,
          SATProgramDetails: onboardingState.SATProgramDetails,
          studentGrade: params?.noStudentGrade ? undefined : signupState.grade,
        }),
      );
      setUserSettingsCoolDown(new Date().toISOString());
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);
