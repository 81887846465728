import { RootState } from '../../../App/store';
import { ProgramAccessType } from '../../../Common/entities';
import { getSATCourse } from '../../../Learn/services/utils';
import { mapSatBootcampEntitlementStatus } from '../../../Onboarding/services/mappers';
import { SATLockOverlay } from '../../entities';

import { mapSATHistorySummary } from './SATStateMapper';

export const mapSatBootcampFullAccessStatus = (state: RootState): boolean => {
  const satBootcampEntitlementStatus = mapSatBootcampEntitlementStatus(state);

  return (
    !satBootcampEntitlementStatus &&
    state.SAT.bootcamp?.accessDetails?.accessType ===
      ProgramAccessType.FULL_ACCESS
  );
};

export const mapSatBootcampFreeTrialStatus = (state: RootState): boolean => {
  const bootcamp = state.SAT.bootcamp;
  return bootcamp?.accessDetails?.accessType === ProgramAccessType.TRIAL;
};

export const mapSatBootcampFreePointStatus = (state: RootState): boolean => {
  const bootcamp = state.SAT.bootcamp;
  return (
    Number(bootcamp?.details?.freePointsOffered ?? 0) > 0 &&
    bootcamp?.accessDetails?.accessType === ProgramAccessType.FREE_POINTS
  );
};

export const mapSatBootcampFreePoints = (state: RootState): number => {
  return state.SAT.bootcamp?.details?.freePointsOffered ?? 0;
};

export const mapSatBootcampScoreAchievedFreePointsStatus = (
  state: RootState,
): boolean => {
  const freePoints = mapSatBootcampFreePoints(state);
  const hasFreePointsProgram = mapSatBootcampFreePointStatus(state);
  const currentScore = state.SAT.bootcamp?.details?.currentScore ?? 0;
  if (!freePoints || !currentScore || !hasFreePointsProgram) {
    return false;
  }

  const satCourse = getSATCourse();
  if (!satCourse) {
    return false;
  }

  const { totalScore } = mapSATHistorySummary(
    state.testPrep.SATHistory,
    state.testPrep.SATUserPercentile[satCourse.id],
    satCourse.units,
  );

  return totalScore >= currentScore + freePoints;
};

export const mapSatLockOverlayStatus = (
  state: RootState,
): undefined | SATLockOverlay => {
  const satCourse = getSATCourse();
  if (!satCourse) {
    return undefined;
  }

  const { totalScore } = mapSATHistorySummary(
    state.testPrep.SATHistory,
    state.testPrep.SATUserPercentile[satCourse.id],
    satCourse.units,
  );
  const status = mapSatBootcampScoreAchievedFreePointsStatus(state);

  if (!status || !totalScore) {
    return undefined;
  }

  const targetScore = state.SAT?.bootcamp?.details?.targetScore || 0;
  const freePointsOffered = state.SAT.bootcamp?.details?.freePointsOffered || 0;

  if (totalScore >= targetScore) {
    return SATLockOverlay.TARGET_ACHIEVED;
  }

  if (totalScore + freePointsOffered >= targetScore) {
    return SATLockOverlay.TARGET_WITHIN_FREE_POINTS_REACH;
  }

  return SATLockOverlay.TARGET_OUTSIDE_FREE_POINTS_REACH;
};

export const mapSatBootcampPointsUntilTargetScore = (
  state: RootState,
): number => {
  const satCourse = getSATCourse();
  if (!satCourse) {
    return 0;
  }

  const { totalScore } = mapSATHistorySummary(
    state.testPrep.SATHistory,
    state.testPrep.SATUserPercentile[satCourse.id],
    satCourse.units,
  );

  const targetScore = state.SAT?.bootcamp?.details?.targetScore || 0;
  return targetScore - totalScore;
};

export const mapSatBootcampBoostedScore = (state: RootState): number => {
  const satCourse = getSATCourse();
  if (!satCourse) {
    return 0;
  }

  const { totalScore } = mapSATHistorySummary(
    state.testPrep.SATHistory,
    state.testPrep.SATUserPercentile[satCourse.id],
    satCourse.units,
  );
  const currentScore = state.SAT?.bootcamp?.details?.currentScore || 0;

  return totalScore - currentScore;
};
