import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  setApExamsConfig,
  setApUpcomingCoursesConfig,
} from '../../../../ApResults/services/slices';
import { getTeachTapConfigurationsGraphQLCall } from '../../../../Common/graphql';
import { mapRawAppConfigsData } from '../../../../Common/services/mappers';
import {
  setApExamTestimonials,
  setSATConfigs,
} from '../../../../Onboarding/services/slices';
import { setNextLastApExamDate } from '../../../../Paywall/services/slices';
import { handleNetworkActionErrorSilently } from '../../utils';
import { LIFECYCLE_SLICE_NAME } from '../LifecycleSlice';

export const getAppConfigs = createAsyncThunk(
  `${LIFECYCLE_SLICE_NAME}/getAppConfigs`,
  async (_, thunkApi) => {
    try {
      const appConfigsRaw = await getTeachTapConfigurationsGraphQLCall();

      const { apExams, satBootcamp, apExamsTestimonials, apUpcomingCourses } =
        mapRawAppConfigsData(appConfigsRaw.configurations);

      thunkApi.dispatch(
        setSATConfigs({
          examDates: satBootcamp?.exam?.SAT?.examDates || [],
          minimumScore: satBootcamp?.exam?.SAT?.minimumScore || 0,
          freePointsOffer: satBootcamp?.exam?.SAT?.freePointsOffer || 0,
          competitors: satBootcamp?.bootcamp?.competitors || [],
          testimonials: satBootcamp?.bootcamp?.testimonials || [],
        }),
      );

      thunkApi.dispatch(setApExamsConfig(apExams));
      thunkApi.dispatch(setNextLastApExamDate(apExams));

      thunkApi.dispatch(setApUpcomingCoursesConfig(apUpcomingCourses));
      thunkApi.dispatch(setApExamTestimonials(apExamsTestimonials));
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);
