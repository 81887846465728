import { createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { ProgramAccessType } from '../../../../Common/entities';
import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { PurchaseType } from '../../../../Paywall/entities';
import { EntitlementAnalyticsType } from '../../../../SAT/entities';
import { mapProgramEntitlementStatus } from '../../mappers';
import { mapEntitlementPaymentSource } from '../../mappers';
import {
  ONBOARDING_SLICE_NAME,
  setHasEntitlementScreenVisible,
} from '../OnboardingSlice';

export const trackEntitlementVisibility = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/trackEntitlementVisibility`,
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(setHasEntitlementScreenVisible(true));

      const rootState = thunkApi.getState() as RootState;
      const entitlementType = mapProgramEntitlementStatus(rootState);
      const state = rootState.onboarding;
      const paymentSource = mapEntitlementPaymentSource(
        rootState,
        entitlementType.ap ? true : false,
      );

      if (entitlementType.sat) {
        trackAnalyticsEvent(Analytics.entitlementConfirmationShown, {
          from: state.bootcampEntryPoint,
          purchaseType: PurchaseType.SAT_BOOTCAMP,
          entitlementType:
            entitlementType.sat === ProgramAccessType.FULL_ACCESS
              ? EntitlementAnalyticsType.PURCHASE
              : EntitlementAnalyticsType.FREE_TRIAL,
          entitlementSource: paymentSource,
        });
      } else if (entitlementType.ap) {
        trackAnalyticsEvent(Analytics.entitlementConfirmationShown, {
          from: state.bootcampEntryPoint,
          purchaseType: PurchaseType.AP_PROGRAM,
          entitlementType: EntitlementAnalyticsType.PURCHASE,
          entitlementSource: paymentSource,
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);
