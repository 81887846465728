import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Image,
  ImageSourcePropType,
  LayoutChangeEvent,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';

import Assets from '../../../App/assets';
import { useSafeAreaCustomInsets } from '../../services/hooks';
import { Colors, Sizes } from '../../services/utils/AppConstants';
import { LinearGradient } from '../../services/utils/polyfills';

type Props = {
  children: React.ReactElement;
  bottomElement: React.ReactElement;
};

export const ExamResultsScreenContainer = (
  props: Props,
): React.ReactElement => {
  const { safeAreaBottom, safeAreaTop } = useSafeAreaCustomInsets();
  const [contentHeight, setContentHeight] = useState(0);
  const [layoutHeight, setLayoutHeight] = useState(0);

  const [isExpandable, setIsExpandable] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const containerStyle = useMemo(() => {
    return [
      styles.mainContainer,
      {
        paddingBottom: safeAreaBottom + Sizes.semiMedium,
        paddingTop: safeAreaTop + Sizes.small,
      },
    ];
  }, [safeAreaBottom, safeAreaTop]);

  const handleCheckIfExpandable = useCallback(() => {
    if (!contentHeight || !layoutHeight || isExpandable) {
      return;
    }

    if (contentHeight > layoutHeight) {
      setIsExpandable(true);
    }
  }, [contentHeight, layoutHeight, isExpandable]);

  const handleSetLayoutHeight = useCallback(
    (e: LayoutChangeEvent) => setLayoutHeight(e.nativeEvent.layout.height),
    [],
  );
  const handleSetContentHeight = useCallback(
    (_w: number, h: number) => setContentHeight(h),
    [],
  );

  const handleExpanded = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const shouldShowExpandUI = useMemo(() => {
    return isExpandable && !isExpanded;
  }, [isExpandable, isExpanded]);

  const expandContainerStyle = useMemo(() => {
    return [styles.expandContainer, { top: layoutHeight - 48 }];
  }, [layoutHeight]);

  useEffect(() => {
    handleCheckIfExpandable();
  }, [handleCheckIfExpandable]);

  return (
    <View style={containerStyle}>
      <SafeAreaView style={styles.safeAreaContainer}>
        <ScrollView
          style={styles.scrollViewContainer}
          showsVerticalScrollIndicator={false}
          bounces={false}
          onScroll={handleExpanded}
          scrollEventThrottle={8}
          onLayout={handleSetLayoutHeight}
          onContentSizeChange={handleSetContentHeight}>
          {props.children}

          {shouldShowExpandUI && (
            <View style={expandContainerStyle}>
              <LinearGradient
                colors={Colors.prussianBlueFromTransparent}
                style={[styles.fadeOutEffect]}
                start={{ x: 0, y: 0 }}
                end={{ x: 0, y: 0.8 }}
              />

              <View style={[styles.expandGif]}>
                <Image
                  style={styles.gif}
                  resizeMode={'cover'}
                  source={
                    Assets.test.expandQuestionGif.path as ImageSourcePropType
                  }
                />
              </View>
            </View>
          )}
        </ScrollView>

        <View>{props.bottomElement}</View>
      </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.prussianBlue,
    paddingHorizontal: Sizes.medium,
  },

  safeAreaContainer: {
    flex: 1,
  },

  scrollViewContainer: {
    flex: 1,
  },

  expandContainer: {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: 48,
  },
  fadeOutEffect: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 48,
  },

  expandGif: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    paddingBottom: 5,
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    transform: [{ translateX: -30 }],
    overflow: 'hidden',
  },
  gif: {
    width: Sizes.normalizeIP14PM(120),
    height: Sizes.normalizeIP14PM(60),
    opacity: 0.6,
  },
});
