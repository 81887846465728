import { createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import {
  CommonPeriodType,
  PaywallCourseName,
  ProgramAccessType,
} from '../../../../Common/entities';
import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import {
  PurchaseType,
  RegisterPurchaseSource,
} from '../../../../Paywall/entities';
import { EntitlementAnalyticsType } from '../../../../SAT/entities';
import { TrackEntitlementRedemptionParams } from '../../../entities';
import { mapEntitlementPaymentSource } from '../../mappers';
import { ONBOARDING_SLICE_NAME } from '../OnboardingSlice';

export const trackEntitlementRedemption = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/trackEntitlementRedemption`,
  async (params: TrackEntitlementRedemptionParams, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const paymentSource = mapEntitlementPaymentSource(
        rootState,
        params.entitlementType.ap ? true : false,
      );

      if (params.entitlementType.sat) {
        const bootcamp = rootState.SAT.bootcamp;

        const durationWord =
          bootcamp?.details?.durationPeriod === CommonPeriodType.WEEK
            ? 'Week'
            : 'Day';
        const bootcampName = `${bootcamp?.details?.durationLength}-${durationWord} ${bootcamp?.details?.programName}`;

        trackAnalyticsEvent(Analytics.entitlementRedeemed, {
          from: params.from,
          purchaseType: PurchaseType.SAT_BOOTCAMP,
          course: bootcampName,
          entitlementType:
            params.entitlementType.sat === ProgramAccessType.FULL_ACCESS
              ? EntitlementAnalyticsType.PURCHASE
              : EntitlementAnalyticsType.FREE_TRIAL,
          entitlementSource: paymentSource,
        });
        if (paymentSource === RegisterPurchaseSource.PURCHASED) {
          trackAnalyticsEvent(Analytics.redeemedSATBootcampPurchase);
        }
      } else if (params.entitlementType.ap) {
        trackAnalyticsEvent(Analytics.entitlementRedeemed, {
          course: PaywallCourseName.AP_PROGRAM,
          from: params.from,
          purchaseType: PurchaseType.AP_PROGRAM,
          entitlementType: EntitlementAnalyticsType.PURCHASE,
          entitlementSource: paymentSource,
        });
        if (paymentSource === RegisterPurchaseSource.PURCHASED) {
          trackAnalyticsEvent(Analytics.redeemedApProgramPurchase);
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);
