export enum SATResultsCongratulationsMessageType {
  ACHIEVED_TARGET = 'ACHIEVED_TARGET',
  ABOVE_TARGET = 'ABOVE_TARGET',
  ABOVE_LAST_SCORE = 'ABOVE_LAST_SCORE',
  BELOW_LAST_SCORE = 'BELOW_LAST_SCORE',
  NONE = 'NONE',
}

export type IsShowSATResultsScreenFlagMetadata = {
  examDate: string;
  targetScore: number;
  currentScore: number;
};

export type CheckIfSATResultsRetakeInputValidProps = {
  examTargetScore?: number;
  examDate?: string;
};

export type GetSATResultsRetakeTargetScoreErrorProps = {
  examTargetScore?: number;
};
