import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { Asset } from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import { Colors, Fonts, Sizes } from '../../services/utils/AppConstants';

type Props = {
  image: Asset;
  imageHeight: number;
  title: string;
  description: string;
};

export const ExamResultsLandingItem = (props: Props): React.ReactElement => {
  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <AssetImage asset={props.image} height={props.imageHeight} />
      </View>

      <Text style={styles.title}>{props.title}</Text>
      <Text style={styles.description}>{props.description}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: Sizes.semiMedium,
    backgroundColor: Colors.white10,
    borderWidth: 1,
    borderRadius: Sizes.semiMedium,
    borderColor: Colors.white12,
  },

  imageContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  title: {
    ...Fonts.semiLargeBold,
    textAlign: 'center',
    marginTop: Sizes.small,
  },
  description: {
    ...Fonts.semiMedium,
    color: Colors.white60,
    textAlign: 'center',
    marginTop: Sizes.xsmall,
  },
});
