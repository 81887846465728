export enum AppNavigatorTarget {
  WAIT_APP_LOAD = 'WAIT_APP_LOAD',
  ONBOARDING_AUTH = 'ONBOARDING_AUTH',
  BOOTCAMP_ENTITLEMENT_AFTER_SIGNUP = 'BOOTCAMP_ENTITLEMENT_AFTER_SIGNUP',
  NOTIFICATION_REQUEST = 'NOTIFICATION_REQUEST',
  ONBOARDING_FLOW_AFTER_SIGNUP = 'ONBOARDING_FLOW_AFTER_SIGNUP',
  USERNAME_PICTURE_SCREEN = 'USERNAME_PICTURE_SCREEN',
  SAT_BOOTCAMP_PAYWALL = 'SAT_BOOTCAMP_PAYWALL',
  SAT_BOOTCAMP_FREE_ACCESS = 'SAT_BOOTCAMP_FREE_ACCESS',
  SAT_BOOTCAMP_CATEGORY_SCORE_INPUT = 'SAT_BOOTCAMP_CATEGORY_SCORE_INPUT',
  SAT_RESULTS_LANDING_SCREEN = 'SAT_RESULTS_LANDING_SCREEN',
}
