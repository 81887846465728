import { ProgramType } from '../../Common/entities';
import { Analytics } from '../../Common/services/utils/AppConstants';

import { SATBootcampProgramDetails } from './OnboardingTypes';

export type ExamDateItem = {
  date: Date;
  dateString: string;
  label: string;
};

export type SATInputSummary = {
  examDate?: string;
  lastScore?: number;
  targetScore?: number;
  lastReadingWritingScore?: number;
  lastMathScore?: number;
};

export enum SATLastScoreKey {
  LESS_THAN_MINIMUM = 'LESS_THAN_MINIMUM',
  NOT_TAKEN = 'NOT_TAKEN',
}

export type SATLastScoreType = {
  label: string;
  key: SATLastScoreKey | string;
  value: number;
};

export type AnalyticsNameBasedOnExamType = {
  view?: Analytics;
  learnMore?: Analytics;
};

export type SatBootcampSubmitDataParams = {
  SATProgramDetails?: SATBootcampProgramDetails;
  optedForAddon?: boolean;
  mathScore?: number;
  readingWritingScore?: number;
  hasTakenTest: boolean;
  freePointsOffered?: number;
};

export type SATBootcampMetadata = {
  examDate?: string;
  currentScore: number | null;
  targetScore?: number;
  programType?: ProgramType;

  // ISO string
  enrolledAt?: string;
  optedForAddon: boolean;

  hasTakenTest: boolean;
  mathScore: number | null;
  readingWritingScore: number | null;

  freePointsOffered: number | null;
};
