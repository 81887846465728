import React, { useCallback, useMemo } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import locale from '../../../App/locale';
import { useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import {
  PaywallEntryPoint,
  PaywallPurchaseType,
} from '../../../Common/entities';
import {
  formatLocalizedPrice,
  getBoolAnalyticsText,
  trackAnalyticsEvent,
} from '../../../Common/services/utils';
import {
  Analytics,
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { setShouldPreventScreenListenerReset } from '../../../Onboarding/services/slices';
import { useBootcampPaywallData } from '../../services/hooks';
import {
  purchaseBootcamp,
  setBootcampPaywallLoading,
} from '../../services/slices';
import { DEFAULT_BOOTCAMP_PRODUCT_NAME } from '../../services/utils/PaywallUtils';

type Props = {
  entryPoint: PaywallEntryPoint;
};

export const BootcampPaywallButton = (props: Props): React.ReactElement => {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(
    state =>
      state.purchases.isLoading ||
      state.bootcampPaywall.isLoading ||
      state.onboarding.isLoading,
    (prev, next) => prev === next,
  );

  const {
    programDetails,
    userProductName,
    userProductDataDiscount,
    finalPricingWithAddOn,
    shouldUseApAddonProduct,
    shouldAllowSATFreeAccess,
  } = useBootcampPaywallData();

  const handlePurchaseButtonPress = useCallback(async () => {
    trackAnalyticsEvent(Analytics.purchaseButtonClicked, {
      course: programDetails?.programName ?? DEFAULT_BOOTCAMP_PRODUCT_NAME,
      from: props.entryPoint,
      purchaseType: shouldUseApAddonProduct
        ? PaywallPurchaseType.SAT_BOOTCAMP_PLUS_AP_PROGRAM
        : PaywallPurchaseType.SAT_BOOTCAMP,
      examDate: programDetails?.examDate,
      canBeSkipped: getBoolAnalyticsText(shouldAllowSATFreeAccess),
    });

    dispatch(setShouldPreventScreenListenerReset(true));
    dispatch(setBootcampPaywallLoading(true));

    dispatch(
      purchaseBootcamp({
        programName:
          programDetails?.programName ?? DEFAULT_BOOTCAMP_PRODUCT_NAME,
        item: userProductName,
        entryPoint: props.entryPoint,
      }),
    )
      .unwrap()
      .catch(() => {
        dispatch(setShouldPreventScreenListenerReset(false));
        dispatch(setBootcampPaywallLoading(false));
      });
  }, [
    shouldUseApAddonProduct,
    programDetails?.programName,
    programDetails?.examDate,
    props.entryPoint,
    dispatch,
    userProductName,
    shouldAllowSATFreeAccess,
  ]);

  const discountPriceString = useMemo(() => {
    const localizedPriced =
      finalPricingWithAddOn?.localizedPrice ||
      userProductDataDiscount?.localizedPrice;

    return localizedPriced ? formatLocalizedPrice(localizedPriced) : 'N';
  }, [
    finalPricingWithAddOn?.localizedPrice,
    userProductDataDiscount?.localizedPrice,
  ]);

  return (
    <View style={styles.callToActionSection}>
      <TouchableOpacity
        style={styles.enrollButton}
        disabled={isLoading}
        onPress={handlePurchaseButtonPress}>
        {isLoading && <ActivityIndicator color="white" />}

        {!isLoading && (
          <Text style={styles.enrollButtonText}>
            {locale.bootcamp_paywall.enroll_now_for}&nbsp;
            <Text style={styles.enrollButtonTextBold}>
              &nbsp;{discountPriceString}
            </Text>
          </Text>
        )}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  callToActionSection: {
    alignItems: 'center',
  },
  enrollButton: {
    backgroundColor: Colors.buttonGreen,
    borderRadius: 12,
    width: '100%',
    paddingHorizontal: Sizes.small,
    paddingVertical: Sizes.medium,
    alignItems: 'center',
    justifyContent: 'center',
  },
  enrollButtonText: {
    ...Fonts.large,
    textAlign: 'center',
  },
  enrollButtonTextStrikethrough: {
    ...Fonts.large,
    textDecorationLine: 'line-through',
  },
  enrollButtonTextBold: {
    ...Fonts.largeBold,
  },
});
