import { getRootState } from '../../../App/services/utils';
import { RootState } from '../../../App/store';
import { CommonExamType } from '../../../Common/entities';
import { SAT_MAX_TOTAL_SCORE } from '../../../SAT/data';
import { mapSatBootcampFullAccessStatus } from '../../../SAT/services/mappers';
import { DEFAULT_SAT_MINIMUM_SCORE } from '../../data';
import {
  ExamDateItem,
  SATBootcampMetadata,
  SatBootcampSubmitDataParams,
  SATInputSummary,
  SATLastScoreType,
} from '../../entities';
import { OnboardingSliceState } from '../slices';
import { checkIfSATInputValid, getSATLastScoreValueByKey } from '../utils';

import {
  getFutureExamDatesWithLimit,
  mapSATLastScores,
  mapSATTargetScores,
} from './OnboardingStateMapper';

const EXAM_DATES_DISPLAY_LIMIT = 7;

export const shouldShowOnboarding = (
  state: OnboardingSliceState,
  isLoggedIn: boolean,
): boolean => {
  const shouldRedirect =
    state.shouldOpenSatBootcampFlowForUpsell ||
    state.doOnboardingFlowAfterSignup;

  if (shouldRedirect && isLoggedIn) {
    return true;
  }

  return false;
};

export const getSATExamDatesToDisplay = (
  state: OnboardingSliceState,
): ExamDateItem[] => {
  return getFutureExamDatesWithLimit(
    state?.SATConfigs?.examDates || [],
    EXAM_DATES_DISPLAY_LIMIT,
  );
};

export const getSATLastScoreData = (): SATLastScoreType[] => {
  return mapSATLastScores(getRootState());
};

export const getSATTargetScoreData = (
  state: OnboardingSliceState,
): number[] => {
  return mapSATTargetScores(
    state?.SATConfigs?.minimumScore || DEFAULT_SAT_MINIMUM_SCORE,
    getSATLastScoreValueByKey(state.examLastScoreKey),
  );
};

export const isAllSatExamInputFilled = (
  state: OnboardingSliceState,
): boolean => {
  return checkIfSATInputValid({
    examLastScore: getSATLastScoreValueByKey(state.examLastScoreKey),
    examTargetScore: state.examTargetScore,
    examDate: state.examDate,
  });
};

export const getSATInputSummary = (
  state: OnboardingSliceState,
): SATInputSummary => {
  const smallestLastScore = getSATLastScoreData()[0].value;

  return {
    examDate: state.examDate,
    lastScore:
      getSATLastScoreValueByKey(state.examLastScoreKey) || smallestLastScore,
    targetScore: state.examTargetScore,
    lastReadingWritingScore: state.lastSATReadingWritingScore,
    lastMathScore: state.lastSATMathScore,
  };
};

export const isSatBootcampSummaryReady = (
  state: OnboardingSliceState,
): boolean => {
  return Boolean(state.SATProgramDetails && !state.loadingSATProgramDetails);
};

export const isComingFromBootcampFlow = (
  state: OnboardingSliceState,
): boolean => Boolean(state.examType);

export const isComingFromSATBootcampFlow = (
  state: OnboardingSliceState,
): boolean => state.examType === CommonExamType.SAT;

export const isComingFromAPProgramFlow = (
  state: OnboardingSliceState,
): boolean => state.examType === CommonExamType.AP;

export const mapSatBootcampSubmitData = (
  props: SatBootcampSubmitDataParams,
): SATBootcampMetadata => {
  const { SATProgramDetails, mathScore, readingWritingScore, hasTakenTest } =
    props;

  const currentScoreValue =
    hasTakenTest && SATProgramDetails?.currentScore
      ? SATProgramDetails.currentScore
      : null;
  const mathScoreValue = hasTakenTest && mathScore ? mathScore : null;
  const readingWritingScoreValue =
    hasTakenTest && readingWritingScore ? readingWritingScore : null;

  return {
    examDate: SATProgramDetails?.examDate,
    currentScore: currentScoreValue,
    targetScore: SATProgramDetails?.targetScore,
    programType: SATProgramDetails?.programType,
    enrolledAt: SATProgramDetails?.enrolledAt,
    optedForAddon: props.optedForAddon || false,
    hasTakenTest,
    mathScore: mathScoreValue,
    readingWritingScore: readingWritingScoreValue,
    freePointsOffered: props.freePointsOffered || null,
  };
};

export const mapIsSatSelectedScoreAcceptableForFreeAccess = (
  state: RootState,
): boolean => {
  const freePointsOffer = state.onboarding.SATConfigs?.freePointsOffer || 0;
  if (!freePointsOffer) {
    return false;
  }

  const lastScore =
    getSATLastScoreValueByKey(state.onboarding.examLastScoreKey) || 0;

  return SAT_MAX_TOTAL_SCORE - lastScore > freePointsOffer;
};

export const mapShouldAllowFreeAccessBootcampStatus = (
  state: RootState,
): boolean => {
  const hasSatBootcampFullAccess = mapSatBootcampFullAccessStatus(state);
  const isUserLastScoreAcceptableForFreeAccess =
    mapIsSatSelectedScoreAcceptableForFreeAccess(state);

  return (
    !hasSatBootcampFullAccess &&
    isUserLastScoreAcceptableForFreeAccess &&
    !state.onboarding.SATFreePointsClaimed
  );
};
