import { Course, Unit } from '../../Common/entities';
import { SAT_UNIT_CATEGORY } from '../../Learn/entities';
import { RaiseHandScreenTypes } from '../../RaiseHand/entities';
import { SATStimulus, SATStimulusImage } from '../../SAT/entities';

export type TestPrepData = {
  id: string;
  course: Course;
  unit: Unit;
  questions: TestPrepQuestion[];
  grade?: string;
  pointsAwarded?: number;
  externalUrl?: string;
  timeRemaining: number;
  maxScore?: number;
  minScore?: number;
  percentileString?: string;
  bestScore?: number;
  endedDueToTimeout?: boolean;
  highestSeenQuestionIndex?: number;
  currentQuestionIndex?: number;
  testNumber?: number;
  resultsSeen?: boolean;
};

export type TestPrepOption = {
  id: string;
  answer: string;
  correct: boolean;
};

export type QuestionTutorHelp = {
  name: string;
  avatarURL: string;
  videoURL?: string;
  subtitleURL?: string;
  title?: string;
};

export type TestPrepQuestion = {
  id: string;
  difficulty: number;
  question: string;
  options: TestPrepOption[];
  chosenOption?: TestPrepOption;
  tutor?: QuestionTutorHelp;
  generatedContentId: string;
  levelId: string;
  renderer?: string;
  stimulus?: SATStimulus;
  stimulusImage?: SATStimulusImage;
  initialTutorMessage?: string;
  contentTypeId?: string;
};

export type SubmitTestQuestionAnswerParams = {
  testQuestion: TestPrepQuestion;
  option: TestPrepOption;
};

export type StartNewTestParams = {
  course: Course;
  unit: Unit;
  testType: TestType;
};

export type GetGeneratorExternalUrlParams = {
  courseId: string;
  unitId: string;
  shouldAssign: boolean;
};

export type GetTestPrepHistoryParams = {
  course: Course;
  enforce?: boolean;
};

export type TestAttempt = {
  grade: string;
  submittedAt: string;
};

export type UserEmailAttributes = {
  name: string;
  value: string;
};

export enum UserEmailType {
  ShareFrqLink = 'ShareFrqLink',
}

export type TrackTutorPanelPressAction = {
  from: RaiseHandScreenTypes;
  generatedContentId: string;
  courseId: string;
  unitId: string;
  courseName: string;
  unitNumber: number;
  contentType: string;
};

export enum TestType {
  AP_TEST = 'AP',
  SAT_TEST = 'SAT',
}

export enum TestScopeType {
  TOTAL = 'Total',
  MATH = SAT_UNIT_CATEGORY.MATH,
  READING = SAT_UNIT_CATEGORY.READING,
}

export enum TestPrepQuestionDuration {
  SAT_READING = 71, // 1 minute and 11 seconds
  SAT_MATH = 95, // 1 minute and 35 seconds
  DEFAULT = 60, // 1 minute
}

export type TestInfo = {
  attempts: string[];
  highestScore: string;
  progress: number;
};

export enum TestPrepEntryPoint {
  NAVBAR = 'navBar',
  APP_OPEN = 'appOpen',
  PUSH_NOTIFICATION = 'pushNotification',
  PROFILE = 'profile',
  COURSE_PICKER = 'coursePicker',
  SAT_FREE_ACCESS_CLAIMED = 'SATFreeAccessClaimed',
}

export enum TestPrepQuestionEntryPoint {
  PREVIOUS_QUESTION = 'previousQuestion',
  NEXT_QUESTION = 'nextQuestion',
  TOP_NAVIGATION = 'topNavigation',
  TEST_COMPLETION_REVIEW = 'testCompletionReview',
  SKIPPED_QUESTION_REVIEW = 'skippedQuestionReview',
  UNIT_TEST_START = 'unitTestStart',
}

export enum TestPrepQuestionStatus {
  ANSWERED_CORRECTLY = 'answeredCorrectly',
  ANSWERED_INCORRECTLY = 'answeredIncorrectly',
  SKIPPED = 'skipped',
  UNANSWERED = 'unanswered',
}

export const SUPPORTED_SCREEN_HEIGHTS = [568, 667, 812];

export type AttemptsHistory = {
  recentAttempts: TestAttempt[];
  totalAttempts: number;
};
