import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { useAppSelector } from '../../App/services/hooks';
import { Course } from '../../Common/entities';
import { useSafeAreaCustomInsets } from '../../Common/services/hooks';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { CourseFeedHeader } from '../../Learn/components/study/CourseFeedHeader';
import { renderSATExamSummaryCountdown } from '../../Onboarding/components/summary/SATExamSummaryCountdown';
import { SATHistoryDataSummary } from '../entities';
import {
  mapSatBootcampFreePointStatus,
  mapSatBootcampScoreAchievedFreePointsStatus,
} from '../services/mappers';

import { SATProgramExamDate } from './SATProgramExamDate';
import { SATStatsSummary } from './SATStatsSummary';
import { SATUnlockImprovement } from './SATUnlockImprovement';

type Props = {
  selectedCourse: Course;
  historyDataSummary?: SATHistoryDataSummary;
  title: string;
  description: string;
};

export const SATTestPrepFeedHeader = ({
  selectedCourse,
  historyDataSummary,
  title,
  description,
}: Props): React.ReactElement => {
  const bootcampDetails = useAppSelector(state => state.SAT.bootcamp);
  const isClaimedFreePoints = useAppSelector(mapSatBootcampFreePointStatus);
  const isSatFreePointsAchieved = useAppSelector(
    mapSatBootcampScoreAchievedFreePointsStatus,
  );

  const { safeAreaTop } = useSafeAreaCustomInsets();

  const contentContainerStyle = useMemo(() => {
    return [styles.contentContainer, { paddingTop: safeAreaTop }];
  }, [safeAreaTop]);

  return (
    <View>
      <View style={styles.topHeaderFiller} />

      <View style={contentContainerStyle}>
        <CourseFeedHeader
          course={selectedCourse}
          hideFollowingText
          isFromTestPrep
        />

        {!isClaimedFreePoints ? (
          <View style={styles.examDateContainer}>
            <SATProgramExamDate />
          </View>
        ) : (
          <View style={styles.examCountdownContainer}>
            {renderSATExamSummaryCountdown({ isFromOnboarding: false })}
          </View>
        )}

        {isClaimedFreePoints && !isSatFreePointsAchieved && (
          <View style={styles.statsContainer}>
            <SATUnlockImprovement />
          </View>
        )}

        <View style={styles.statsContainer}>
          <SATStatsSummary
            historyDataSummary={historyDataSummary}
            readingWritingLastExamScore={
              bootcampDetails?.details?.readingWritingScore ?? 0
            }
            mathLastExamScore={bootcampDetails?.details?.mathScore ?? 0}
            totalLastExamScore={bootcampDetails?.details?.currentScore ?? 0}
          />
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  startContainer: {
    marginTop: Sizes.medium,
    paddingHorizontal: Sizes.small,
    paddingTop: Sizes.medium,
  },
  examDateContainer: {
    marginTop: Sizes.semiMedium,
  },
  examCountdownContainer: {
    marginTop: Sizes.semiMedium,
    alignSelf: 'center',
  },
  statsContainer: {
    marginTop: Sizes.medium,
  },
  topHeaderFiller: {
    position: 'absolute',
    top: -300,
    left: 0,
    width: '100%',
    height: 300,
    backgroundColor: Colors.black80,
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: Sizes.medium,
  },
  chartContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: Sizes.medium,
    padding: Sizes.semiMedium,
    paddingBottom: Sizes.small,
  },
  chartTitle: {
    ...Fonts.small,
    textAlign: 'center',
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginRight: Sizes.semiMedium,
    marginTop: Sizes.xsmall,
  },
  legendIcon: {
    marginTop: 3,
    width: 10,
    height: 10,
    borderRadius: 5,
    marginRight: 5,
  },
  chartWithLegendContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: Sizes.medium,
    paddingTop: Sizes.medium,
  },
  legendContainer: {
    justifyContent: 'flex-start',
    marginLeft: Sizes.medium,
    marginRight: Sizes.medium,
  },
  legendText: {
    ...Fonts.small,
    color: Colors.white80,
    maxWidth: '100%',
  },
});
