import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import locale from '../../App/locale';
import { useAppNavigation, useAppSelector } from '../../App/services/hooks';
import { useAppDispatch } from '../../App/store';
import { PaywallEntryPoint } from '../../Common/entities';
import {
  Colors,
  Fonts,
  MainStack,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { mapSatBootcampFreeTrialStatus } from '../../SAT/services/mappers';
import { setSATFreeTrialEnded } from '../../SAT/services/slices';
import { formatFreeTrialTimeRemaining } from '../services/utils/FreeTrialUtils';

export const SATBootcampFreeTrialCountdown = (): React.ReactElement | null => {
  const navigation = useAppNavigation();
  const dispatch = useAppDispatch();

  const bootcampExpirationDate = useAppSelector(
    state => state.SAT.bootcamp?.accessDetails?.expiresAt,
  );
  const bootcampExpirationTime = useAppSelector(state =>
    state.SAT.bootcamp?.accessDetails?.expiresAt
      ? Date.parse(state.SAT.bootcamp?.accessDetails?.expiresAt)
      : Date.now(),
  );
  const isTrial = useAppSelector(mapSatBootcampFreeTrialStatus);

  const [formattedTimeRemaining, setFormattedTimeRemaining] = useState(
    formatFreeTrialTimeRemaining(bootcampExpirationTime),
  );

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeRemaining = formatFreeTrialTimeRemaining(
        bootcampExpirationTime,
      );
      setFormattedTimeRemaining(newTimeRemaining);
      if (newTimeRemaining === '00:00:00' && isTrial) {
        clearInterval(timer);
        dispatch(setSATFreeTrialEnded(true));
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [bootcampExpirationTime, dispatch, isTrial]);

  const navigateToPaywall = () => {
    navigation.navigate(MainStack.BOOTCAMP_PAYWALL_SCREEN, {
      entryPoint: PaywallEntryPoint.FREE_TRIAL_COUNTDOWN,
    });
  };

  const isExpired = useMemo(() => {
    return moment(bootcampExpirationDate).isBefore(moment());
  }, [bootcampExpirationDate]);

  if (!bootcampExpirationDate || isExpired || !isTrial) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Text style={styles.label}>
        {locale.freeTrial.free_trial_ends}
        <Text style={styles.highlightedLabel}>
          {'  '}
          {formattedTimeRemaining}
        </Text>
      </Text>

      <TouchableOpacity
        style={styles.commitmentContainer}
        onPress={navigateToPaywall}>
        <Text style={styles.highlightedLabel}>{locale.common.upgrade}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 35,
    paddingHorizontal: Sizes.medium,
    paddingVertical: Sizes.small,
    backgroundColor: Colors.swamp3B,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  label: {
    ...Fonts.small,
    color: Colors.white90,
  },

  highlightedLabel: {
    ...Fonts.smallBold,
  },

  commitmentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: Sizes.small,
    marginLeft: Sizes.small,
  },

  commitmentTextContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  commitmentHours: {
    ...Fonts.mediumBold,
    color: 'white',
  },
  commitmentTargetHours: {
    ...Fonts.small,
    color: 'white',
  },
});
