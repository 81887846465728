import { useSelector } from 'react-redux';

import { RootState } from '../../store';

const commonStoreComparatorUtil = <T>(prev: T, next: T): boolean => {
  if (
    typeof prev === 'string' ||
    typeof prev === 'number' ||
    typeof prev === 'boolean'
  ) {
    return prev === next;
  }

  if (prev === null || next === null) {
    return prev === next;
  }

  if (prev === undefined || next === undefined) {
    return prev === next;
  }

  return JSON.stringify(prev) === JSON.stringify(next);
};

export const useAppSelector = <T>(
  fn: (state: RootState) => T,
  comparator: (a: T, b: T) => boolean = commonStoreComparatorUtil,
): T => useSelector(fn, comparator);
