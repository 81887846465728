import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { RootNavigatorRef } from '../../../../App/navigation/RootNavigator';
import { RootState } from '../../../../App/store';
import { checkIfShouldSkipActionWithCoolDown } from '../../../../Common/services/utils';
import { MainStack } from '../../../../Common/services/utils/AppConstants';
import { getPaywallEntryPointFromBootcamp } from '../../../../CourseEnrollment/services/utils';
import {
  getSATProgramDetails,
  setBootcampEntryPoint,
} from '../../../../Onboarding/services/slices';
import { SyncSATBootcampAndNavigateToPaywallParams } from '../../../entities';
import { SAT_SLICE_NAME, SATSliceState } from '../SATSlice';

const COOLDOWN_TIME_MS = 15 * 60 * 1000; // 15 minutes

export const syncSATBootcampAndNavigateToPaywall = createAsyncThunk(
  `${SAT_SLICE_NAME}/syncSATBootcampAndNavigateToPaywall`,
  async (params: SyncSATBootcampAndNavigateToPaywallParams, thunkAPI) => {
    const { bootcampEntryPoint } = params;

    const navigation = RootNavigatorRef.current;
    const state = thunkAPI.getState() as RootState;

    let skip = true;
    if (!navigation?.isReady() || !state.SAT.bootcamp?.details) {
      return { skip };
    }

    thunkAPI.dispatch(setBootcampEntryPoint(bootcampEntryPoint));

    if (
      !checkIfShouldSkipActionWithCoolDown(
        COOLDOWN_TIME_MS,
        state.SAT.lastSATProgramSyncTime,
      )
    ) {
      skip = false;
      await thunkAPI
        .dispatch(
          getSATProgramDetails({
            examDate: state.SAT.bootcamp?.details?.examDate,
            examLastScore: state.SAT.bootcamp?.details?.currentScore,
            examTargetScore: state.SAT.bootcamp?.details?.targetScore,
            skipResetOnPending: true,
          }),
        )
        .unwrap();
    }

    navigation?.navigate(MainStack.BOOTCAMP_PAYWALL_SCREEN, {
      entryPoint: getPaywallEntryPointFromBootcamp(bootcampEntryPoint),
    });
    return { skip };
  },
);

export const syncSATBootcampAndNavigateToPaywallExtraReducers = (
  builder: ActionReducerMapBuilder<SATSliceState>,
): void => {
  builder.addCase(syncSATBootcampAndNavigateToPaywall.pending, state => {
    state.isLoading = true;
  });

  builder.addCase(syncSATBootcampAndNavigateToPaywall.rejected, state => {
    state.isLoading = false;
  });

  builder.addCase(
    syncSATBootcampAndNavigateToPaywall.fulfilled,
    (state, payload) => {
      const { skip } = payload.payload;
      if (!skip) {
        state.lastSATProgramSyncTime = new Date().getTime();
      }

      state.isLoading = false;
    },
  );
};
