import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootNavigatorRef } from '../../../../App/navigation/RootNavigator';
import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { CommonExamType } from '../../../../Common/entities';
import {
  MainStack,
  OnboardingStack,
  TabNames,
} from '../../../../Common/services/utils/AppConstants';
import { setSATFreeTrialEnded } from '../../../../SAT/services/slices';
import { ProceedRedirectionAfterSATBootcampPurchaseParams } from '../../../entities';
import { ONBOARDING_SLICE_NAME } from '../OnboardingSlice';

export const proceedRedirectionAfterSATBootcampPurchase = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/proceedRedirectionAfterSATBootcampPurchase`,
  async (
    params: ProceedRedirectionAfterSATBootcampPurchaseParams,
    thunkApi,
  ) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const isAuthenticated = Boolean(rootState.auth.authUser?.id);
      const navigation = RootNavigatorRef.current;
      const isFromSignup = params.shouldShowUserNameAndPictureScreen;

      if (!navigation?.isReady() || !isAuthenticated) {
        return;
      }

      thunkApi.dispatch(setSATFreeTrialEnded(false));

      if (isFromSignup) {
        navigation?.reset({
          index: 0,
          routes: [
            {
              name: OnboardingStack.USERNAME_AND_PICTURE,
              params: {
                examType: CommonExamType.SAT,
              },
            },
          ],
        });
      } else {
        navigation?.reset({
          index: 0,
          routes: [
            {
              name: MainStack.BOTTOM_TABS,
              params: {
                screen: TabNames.TEST,
              },
            },
          ],
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);
