import React, { useMemo } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import locale from '../../../App/locale';
import { Colors, Fonts } from '../../../Common/services/utils/AppConstants';
import { useSATExamCountdownDuration } from '../../services/hooks';
import { formatDurationPart } from '../../services/utils';

type SATExamSummaryCountdownProps = {
  isFromOnboarding: boolean;
  showCountDownOnly?: boolean;
  textStyle?: TextStyle;
};

export const SATExamSummaryCountdown = ({
  isFromOnboarding,
  showCountDownOnly,
  textStyle,
}: SATExamSummaryCountdownProps): React.ReactElement => {
  const timeLeft = useSATExamCountdownDuration({ isFromOnboarding });

  const countdownText = useMemo(() => {
    const selectedText = showCountDownOnly
      ? locale.onboarding.exam_countdown_for_paywall
      : locale.onboarding.exam_countdown;

    return selectedText
      .replace(
        '${DAYS}',
        timeLeft.isExpired
          ? '0'
          : Math.floor(timeLeft.duration.asDays()).toString(),
      )
      .replace('${HOURS}', formatDurationPart(timeLeft, 'hours'))
      .replace('${MINUTES}', formatDurationPart(timeLeft, 'minutes'))
      .replace('${SECONDS}', formatDurationPart(timeLeft, 'seconds'));
  }, [timeLeft, showCountDownOnly]);

  const weeksLeft = useMemo(() => {
    return timeLeft.duration.asWeeks();
  }, [timeLeft]);

  const textColor = useMemo(() => {
    if (weeksLeft > 6) {
      return styles.textWhite;
    }
    if (weeksLeft > 2) {
      return styles.textOrange;
    }
    return styles.textRed;
  }, [weeksLeft]);

  const managedTextStyle = useMemo(
    () => [styles.text, textStyle, textColor],
    [textColor, textStyle],
  );

  return <Text style={managedTextStyle}>{countdownText}</Text>;
};

const styles = StyleSheet.create({
  text: {
    ...Fonts.smallBold,
  },
  textWhite: {
    color: 'white',
  },
  textOrange: {
    color: Colors.mustard,
  },
  textRed: {
    color: Colors.lightRed,
  },
});

export const renderSATExamSummaryCountdown = (
  props: SATExamSummaryCountdownProps,
): React.ReactElement => {
  return <SATExamSummaryCountdown {...props} />;
};
