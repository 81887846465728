import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { updateMixpanelUserProfile } from '../../../../Auth/services/slices';
import {
  CommonAppGoalKeys,
  CommonExamType,
  SATBootcampAddonPurchaseMetadata,
  SubmitDataType,
} from '../../../../Common/entities';
import { submitDataGraphQLCall } from '../../../../Common/graphql/mutations';
import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { mapUserFollowingSATCourseStatus } from '../../../../CourseEnrollment/services/mappers';
import {
  enrollUserToCourse,
  setCurrentSelectedCourse,
  setShowCourseListOnboarding,
  setShowCourseListStudyTab,
} from '../../../../CourseEnrollment/services/slices';
import { mapSATCourseData } from '../../../../Learn/services/mappers';
import {
  mapIsPaywallFromSATFreeAccess,
  mapSATPaywallWithApAddonSelectionStatus,
} from '../../../../Paywall/services/mappers';
import {
  resetBootcampPaywallSlice,
  setBootcampPaywallLoading,
} from '../../../../Paywall/services/slices';
import { getAndAssignUserFlags } from '../../../../Profile/services/slices';
import {
  getUserSATProgramDetails,
  setSATFreeTrialEnded,
} from '../../../../SAT/services/slices';
import { TestPrepEntryPoint } from '../../../../TestPrep/entities';
import { setTestPrepEntryPoint } from '../../../../TestPrep/services/slices';
import { setShowTutorialScreen } from '../../../../Tutorial/services/slices';
import { getUserAccess } from '../../../../UserAccess/services/slices';
import {
  BootcampEntryPoint,
  ProceedSATBootcampFlowAfterPurchaseParams,
  SATClaimedAccessType,
  SATLastScoreKey,
} from '../../../entities';
import {
  mapSatBootcampEntitlementStatus,
  mapSatBootcampSubmitData,
  mapShouldAllowFreeAccessBootcampStatus,
} from '../../mappers';
import {
  ONBOARDING_SLICE_NAME,
  OnboardingSliceState,
  resetExamInputData,
  setBootcampEntryPoint,
  setDoOnboardingFlowAfterSignup,
  setExamType,
  setIsOnboardingCompleted,
  setSATClaimedAccessType,
  setShouldOpenSatBootcampFlowForUpsell,
  setShouldPreventScreenListenerReset,
  setShouldUseSatBootcampDiscount,
  setShowEntitlementScreen,
  setSignUpExamType,
} from '../OnboardingSlice';

import { proceedRedirectionAfterSATBootcampPurchase } from './ProceedRedirectionAfterSATBootcampPurchaseAction';
import { trackEntitlementRedemption } from './TrackEntitlementRedemptionAction';

export const proceedSATBootcampFlowAfterPurchase = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/proceedSATBootcampFlowAfterPurchase`,
  async (
    props: ProceedSATBootcampFlowAfterPurchaseParams | undefined,
    thunkApi,
  ) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const from = rootState.onboarding.bootcampEntryPoint;
      const entitlementType = mapSatBootcampEntitlementStatus(rootState);
      const isFromFreeAccess = mapIsPaywallFromSATFreeAccess(rootState);

      let blockingPromises: Promise<unknown>[] = [];

      const SATCourse = mapSATCourseData(rootState);
      const isFollowingSATCourse = mapUserFollowingSATCourseStatus(rootState);

      if (SATCourse && !isFollowingSATCourse) {
        blockingPromises.push(
          thunkApi
            .dispatch(
              enrollUserToCourse({
                course: SATCourse,
                goal: CommonAppGoalKeys.CRUSH_UPCOMING_TEST,
                isFromCoursePicker: false,
                skipRedirectToPaywall: true,
              }),
            )
            .unwrap(),
        );

        trackAnalyticsEvent(Analytics.followCourseSatBootcamp, {
          from,
        });
      }

      thunkApi.dispatch(setShowTutorialScreen(false));
      thunkApi.dispatch(setShowCourseListOnboarding(false));
      thunkApi.dispatch(setShowCourseListStudyTab(false));
      thunkApi.dispatch(setExamType(CommonExamType.SAT));

      thunkApi.dispatch(setShouldOpenSatBootcampFlowForUpsell(false));
      thunkApi.dispatch(setShouldUseSatBootcampDiscount(false));
      thunkApi.dispatch(setShouldPreventScreenListenerReset(false));
      thunkApi.dispatch(resetExamInputData());
      thunkApi.dispatch(setSATFreeTrialEnded(false));

      const optedForAddon = mapSATPaywallWithApAddonSelectionStatus(rootState);
      const freePointsOffer =
        rootState.onboarding.SATConfigs?.freePointsOffer || 0;
      const shouldSubmitFreePoints =
        mapShouldAllowFreeAccessBootcampStatus(rootState) &&
        props?.isFreeAccess &&
        freePointsOffer > 0;
      const onboardingState = rootState.onboarding;

      if (isFromFreeAccess && optedForAddon) {
        blockingPromises.push(
          submitDataGraphQLCall({
            type: SubmitDataType.SAT_BOOTCAMP_ADDON_PURCHASE,
            metadata: {
              optedForAddon,
            } as SATBootcampAddonPurchaseMetadata,
          }),
        );
      }

      if (!isFromFreeAccess) {
        blockingPromises.push(
          submitDataGraphQLCall({
            type: SubmitDataType.SAT_BOOTCAMP_ONBOARDING,
            metadata: mapSatBootcampSubmitData({
              optedForAddon,
              SATProgramDetails: onboardingState.SATProgramDetails,
              mathScore: onboardingState.lastSATMathScore,
              readingWritingScore: onboardingState.lastSATReadingWritingScore,
              hasTakenTest:
                onboardingState.examLastScoreKey !== SATLastScoreKey.NOT_TAKEN,
              freePointsOffered: shouldSubmitFreePoints
                ? freePointsOffer
                : undefined,
            }),
          }).catch(e => {
            console.log('error', e);
          }),
        );
      }

      if (shouldSubmitFreePoints) {
        trackAnalyticsEvent(Analytics.SATFreeAccessClaimed, {
          freeAccessPoints: freePointsOffer,
        });
        thunkApi.dispatch(
          setTestPrepEntryPoint(TestPrepEntryPoint.SAT_FREE_ACCESS_CLAIMED),
        );
      }

      thunkApi.dispatch(
        setSATClaimedAccessType(
          shouldSubmitFreePoints
            ? SATClaimedAccessType.FREE
            : SATClaimedAccessType.PAID,
        ),
      );

      await Promise.all(blockingPromises);
      await thunkApi.dispatch(getUserAccess()).unwrap();

      thunkApi.dispatch(setBootcampEntryPoint(undefined));

      if (SATCourse) {
        thunkApi.dispatch(
          setCurrentSelectedCourse({
            id: SATCourse.id,
            tab: null,
          }),
        );
      }

      if (props?.fetchUserProgramDetails) {
        await thunkApi.dispatch(getUserSATProgramDetails()).unwrap();
      }

      thunkApi.dispatch(updateMixpanelUserProfile());

      if (!props?.isFreeAccess && !props?.shouldSendEntitlementCheck) {
        trackAnalyticsEvent(Analytics.purchasedSATBootcamp, {
          canBeSkipped: mapShouldAllowFreeAccessBootcampStatus(rootState),
        });
      }

      thunkApi.dispatch(setDoOnboardingFlowAfterSignup(false));
      thunkApi.dispatch(setShowEntitlementScreen(false));

      if (shouldSubmitFreePoints) {
        thunkApi.dispatch(getAndAssignUserFlags());
      }

      if (entitlementType && props?.shouldSendEntitlementCheck) {
        thunkApi.dispatch(
          trackEntitlementRedemption({
            from: from || BootcampEntryPoint.SAT_BOOTCAMP_INTRO,
            entitlementType: {
              sat: entitlementType,
            },
          }),
        );
      }

      thunkApi.dispatch(setIsOnboardingCompleted(true));
      thunkApi.dispatch(
        proceedRedirectionAfterSATBootcampPurchase({
          shouldShowUserNameAndPictureScreen:
            rootState.onboarding.shouldShowUsernamePictureScreen,
        }),
      );

      thunkApi.dispatch(setBootcampPaywallLoading(false));
      thunkApi.dispatch(resetBootcampPaywallSlice());
      thunkApi.dispatch(setSignUpExamType(undefined));
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);

export const proceedSATBootcampFlowAfterPurchaseExtraReducers = (
  builder: ActionReducerMapBuilder<OnboardingSliceState>,
): void => {
  builder.addCase(proceedSATBootcampFlowAfterPurchase.pending, state => {
    state.isLoading = true;
  });
  builder.addCase(proceedSATBootcampFlowAfterPurchase.rejected, state => {
    state.isLoading = false;
  });
  builder.addCase(proceedSATBootcampFlowAfterPurchase.fulfilled, state => {
    state.isLoading = false;
  });
};
