import BottomSheet from '@gorhom/bottom-sheet';
import { Portal } from '@gorhom/portal';
import { Picker } from '@react-native-picker/picker';
import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StyleSheet } from 'react-native';

import { useSafeAreaCustomInsets } from '../services/hooks';
import { Colors } from '../services/utils/AppConstants';

import { renderCommonSheetBackdrop } from './CommonSheetBackdrop';

export type CommonPickerRefType = {
  open: () => void;
  close: () => void;
};

type Props<T> = {
  items: T[];
  selectedValue?: T;
  onValueChange: (itemValue: T, itemIndex: number) => void;
  isDisabled?: boolean;

  getId?: (item: T, index: number) => string;
  getLabel?: (item: T, index: number) => string;
  onClose?: () => void;
  defaultValueIndex?: number;

  defaultHeight?: number;
};

export const CommonPicker = forwardRef<
  CommonPickerRefType | undefined,
  Props<any>
>((props, ref): React.ReactElement => {
  const bottomSheetRef = useRef<BottomSheet>(null);
  const [sheetIndex, setSheetIndex] = useState(-1);
  const { safeAreaBottom } = useSafeAreaCustomInsets();

  const isSheetVisible = useMemo(() => sheetIndex > -1, [sheetIndex]);

  const snapPoints = useMemo(
    () => [safeAreaBottom + (props.defaultHeight || 250)],
    [props.defaultHeight, safeAreaBottom],
  );

  const handleInitValue = useCallback(() => {
    if (isSheetVisible && props?.items?.length > 0) {
      const defaultIndex = props.defaultValueIndex || 0;

      if (!props.selectedValue) {
        props.onValueChange(props.items[defaultIndex], defaultIndex);
      } else if (props.items.indexOf(props.selectedValue) === -1) {
        props.onValueChange(props.items[defaultIndex], defaultIndex);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSheetVisible, props.items, props.selectedValue]);

  const handleValueChange = useCallback(
    (value: unknown, itemIndex: number) => {
      if (!props?.items?.length) {
        return;
      }

      props.onValueChange(props.items[itemIndex], itemIndex);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onValueChange, props.items],
  );

  const handleOpen = useCallback(() => {
    if (!props.isDisabled) {
      setSheetIndex(0);
      bottomSheetRef.current?.expand();
    }
  }, [props.isDisabled]);

  const handleClose = useCallback(() => {
    setSheetIndex(-1);
    bottomSheetRef.current?.close();

    if (props.onClose) {
      props.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClose]);

  useImperativeHandle(
    ref,
    () => {
      return {
        open: handleOpen,
        close: handleClose,
      };
    },
    [handleOpen, handleClose],
  );

  useEffect(() => {
    handleInitValue();
  }, [handleInitValue]);

  return (
    <Portal>
      <BottomSheet
        ref={bottomSheetRef}
        enablePanDownToClose
        index={sheetIndex}
        backgroundStyle={styles.background}
        handleIndicatorStyle={styles.handle}
        snapPoints={snapPoints}
        animateOnMount={false}
        onChange={setSheetIndex}
        backdropComponent={() =>
          renderCommonSheetBackdrop({
            onPress: handleClose,
            isVisible: isSheetVisible,
            style: styles.backdrop,
          })
        }>
        <Picker
          selectedValue={props.selectedValue}
          onValueChange={handleValueChange}>
          {props.items.map((item, index) => {
            return (
              <Picker.Item
                color="white"
                key={props?.getId ? props.getId(item, index) : index.toString()}
                label={props?.getLabel ? props.getLabel(item, index) : item}
                value={item}
              />
            );
          })}
        </Picker>
      </BottomSheet>
    </Portal>
  );
});

export const renderCommonPicker = <T,>(
  props: Props<T> & { ref?: Ref<CommonPickerRefType | undefined> },
): React.ReactElement => <CommonPicker {...props} />;

const styles = StyleSheet.create({
  background: {
    backgroundColor: Colors.darkGray,
  },
  backdrop: {
    backgroundColor: Colors.black20,
  },
  handle: {
    backgroundColor: 'white',
  },
});
