import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import {
  getBoolAnalyticsText,
  trackAnalyticsEvent,
} from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { mapShouldAllowFreeAccessBootcampStatus } from '../../../../Onboarding/services/mappers';
import { AddOnType } from '../../../entities';
import {
  BOOTCAMP_PAYWALL_SLICE_NAME,
  BootcampPaywallSliceState,
} from '../BootcampPaywallSlice';

export const toggleSATBootcampAPAddonTrackAction = createAsyncThunk(
  `${BOOTCAMP_PAYWALL_SLICE_NAME}/toggleSATBootcampAPAddonTrackAction`,
  async (selected: boolean, thunkAPI) => {
    const shouldAllowFreeAccess = mapShouldAllowFreeAccessBootcampStatus(
      thunkAPI.getState() as RootState,
    );

    trackAnalyticsEvent(Analytics.addOnToggled, {
      addOnType: AddOnType.AP_PROGRAM,
      canBeSkipped: getBoolAnalyticsText(shouldAllowFreeAccess),
    });

    return selected;
  },
);

export const toggleSATBootcampAPAddonTrackActionExtraReducers = (
  builder: ActionReducerMapBuilder<BootcampPaywallSliceState>,
): void => {
  builder.addCase(
    toggleSATBootcampAPAddonTrackAction.fulfilled,
    (state, action) => {
      state.isAPAddOnSelected = action.payload;
    },
  );
};
