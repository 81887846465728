import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { PaywallPurchaseType } from '../../../../Common/entities';
import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { getPaywallEntryPointFromBootcamp } from '../../../../CourseEnrollment/services/utils';
import { SatBootcampPersistedScreen } from '../../../entities';
import { DEFAULT_BOOTCAMP_PRODUCT_NAME } from '../../utils/PaywallUtils';
import {
  BOOTCAMP_PAYWALL_SLICE_NAME,
  BootcampPaywallSliceState,
} from '../BootcampPaywallSlice';

export const skipAndTrackSatBootcampPaywall = createAsyncThunk(
  `${BOOTCAMP_PAYWALL_SLICE_NAME}/skipAndTrackSatBootcampPaywall`,
  async (_, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const programDetails = rootState.onboarding.SATProgramDetails;

    trackAnalyticsEvent(Analytics.paywallSkipped, {
      course: programDetails?.programName ?? DEFAULT_BOOTCAMP_PRODUCT_NAME,
      purchaseType: PaywallPurchaseType.SAT_BOOTCAMP,
      examDate: programDetails?.examDate,
      from: getPaywallEntryPointFromBootcamp(undefined, true),
    });
  },
);

export const skipAndTrackSatBootcampPaywallExtraReducers = (
  builder: ActionReducerMapBuilder<BootcampPaywallSliceState>,
): void => {
  builder.addCase(skipAndTrackSatBootcampPaywall.fulfilled, state => {
    state.satBootcampPersistedScreen =
      SatBootcampPersistedScreen.FREE_ACCESS_OFFER;
  });
};
