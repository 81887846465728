import { createAsyncThunk } from '@reduxjs/toolkit';

import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import {
  incrementSATResultsExitCount,
  SAT_RESULTS_SLICE_NAME,
  setIsFlowClosed,
} from '../SATResultsSlice';

export const closeSATResultsFlow = createAsyncThunk(
  `${SAT_RESULTS_SLICE_NAME}/closeSATResultsFlow`,
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setIsFlowClosed(true));
    thunkAPI.dispatch(incrementSATResultsExitCount());

    trackAnalyticsEvent(Analytics.satExamResultScreenClosed);
  },
);
