import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { getExamTypeFromString } from '../../../../Common/services/utils';
import { setExamType } from '../../../../Onboarding/services/slices';
import { getTeachTapUserSettingsGraphQLCall } from '../../../../Profile/graphql';
import { setSignupMarketingConsent } from '../../../../Profile/services/slices';
import { handleNetworkActionErrorSilently } from '../../utils';
import { LIFECYCLE_SLICE_NAME, LifecycleSliceState } from '../LifecycleSlice';

export const getTeachTapUserSettings = createAsyncThunk(
  `${LIFECYCLE_SLICE_NAME}/getTeachTapUserSettings`,
  async (_, thunkApi) => {
    try {
      const response = await getTeachTapUserSettingsGraphQLCall();
      const examType = getExamTypeFromString(response.examType);
      if (examType) {
        thunkApi.dispatch(setExamType(examType));
      }

      const emailOptIn = response.emailOptIn || false;
      thunkApi.dispatch(setSignupMarketingConsent(emailOptIn));
      return response;
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);

export const getTeachTapUserSettingsExtraReducers = (
  builder: ActionReducerMapBuilder<LifecycleSliceState>,
): void => {
  builder.addCase(getTeachTapUserSettings.fulfilled, (state, action) => {
    if (action.payload?.goal) {
      state.userGoal = { goal: action.payload.goal };
    }
  });
};
