import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { mapSATExamResultsSubmittedAnalytics } from '../../mappers';
import { SAT_RESULTS_SLICE_NAME } from '../SATResultsSlice';

export const trackSATExamResultsSubmission = createAsyncThunk(
  `${SAT_RESULTS_SLICE_NAME}/trackSATExamResultsSubmission`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    trackAnalyticsEvent(
      Analytics.satExamResultsSubmitted,
      mapSATExamResultsSubmittedAnalytics(state),
    );
  },
);
