import { FunctionComponent, SVGAttributes } from 'react';
import { Platform } from 'react-native';

import ArrowRight from '../../../assets/icons/arrow_right.svg';
import BackArowButton from '../../../assets/icons/back_arrow_button.svg';
import BookmarksTabIcon from '../../../assets/icons/bookmarks_tab.svg';
import FavoritesTabIcon from '../../../assets/icons/bottom_tab/bookmark.svg';
import LeaderboardTabIcon from '../../../assets/icons/bottom_tab/leaderboard.svg';
import InboxTabIcon from '../../../assets/icons/bottom_tab/message.svg';
import ProfileTabIcon from '../../../assets/icons/bottom_tab/profile.svg';
import StudyTabIcon from '../../../assets/icons/bottom_tab/study.svg';
import TestTabIcon from '../../../assets/icons/bottom_tab/test.svg';
import CheckIcon from '../../../assets/icons/check.svg';
import SoonTag from '../../../assets/icons/discover/soon_tag.svg';
import DiscoverTabIcon from '../../../assets/icons/discover_tab.svg';
import BugIcon from '../../../assets/icons/feedback/bug_icon.svg';
import ContentFeedbackIcon from '../../../assets/icons/feedback/content_feedback_icon.svg';
import FeatureFeedbackIcon from '../../../assets/icons/feedback/feature_feedback_icon.svg';
import GeneralFeedbackIcon from '../../../assets/icons/feedback/general_feedback_icon.svg';
import FriendsTabIcon from '../../../assets/icons/friends_tab.svg';
import ProgressTick from '../../../assets/icons/history/progress_tick.svg';
import answerInfo from '../../../assets/icons/home/answer_info.svg';
import BookmarkIcon from '../../../assets/icons/home/bookmark.svg';
import BookmarkIconWeb from '../../../assets/icons/home/bookmark_web.svg';
import CardEyeClosed from '../../../assets/icons/home/card_eye_closed.svg';
import CardEyeOpen from '../../../assets/icons/home/card_eye_open.svg';
import CardNoText from '../../../assets/icons/home/card_no_text.svg';
import ChatIcon from '../../../assets/icons/home/chat_icon.svg';
import LeftChevron from '../../../assets/icons/home/chevron_left.svg';
import RightChevron from '../../../assets/icons/home/chevron_right.svg';
import CircleHintIcon from '../../../assets/icons/home/circle_hint.svg';
import ClearDisplayIcon from '../../../assets/icons/home/clear_display.svg';
import CloseItemCross from '../../../assets/icons/home/close_item_cross.svg';
import CommentSendIcon from '../../../assets/icons/home/comment_send.svg';
import CommentsIcon from '../../../assets/icons/home/comments.svg';
import ControversialIcon from '../../../assets/icons/home/controversial_icon.svg';
import CrossIcon from '../../../assets/icons/home/cross.svg';
import CrossedImageIcon from '../../../assets/icons/home/crossed_image.svg';
import dykTypeIcon from '../../../assets/icons/home/dyk_type.svg';
import EmailShareOptionIcon from '../../../assets/icons/home/email_share_option.svg';
import FlipIcon from '../../../assets/icons/home/flip.svg';
import FollowAvatarTick from '../../../assets/icons/home/follow_avatar_tick.svg';
import RaiseHandIcon from '../../../assets/icons/home/hand_icon.svg';
import IncorrectContentIcon from '../../../assets/icons/home/incorrect_content.svg';
import InfinityCircle from '../../../assets/icons/home/infinity_circle.svg';
import KeyboardIcon from '../../../assets/icons/home/keyboard.svg';
import lightbulbIcon from '../../../assets/icons/home/lightbulb.svg';
import LikeIcon from '../../../assets/icons/home/like.svg';
import LinkShareOptionIcon from '../../../assets/icons/home/link_share_option.svg';
import matchingPairsTypeIcon from '../../../assets/icons/home/matching_pairs_type.svg';
import mcqTypeIcon from '../../../assets/icons/home/mcq_type.svg';
import NotInterestedIcon from '../../../assets/icons/home/not_interested.svg';
import OtherOptionsIcon from '../../../assets/icons/home/other_options.svg';
import Paper from '../../../assets/icons/home/paper.svg';
import PaperPlus from '../../../assets/icons/home/paper_plus.svg';
import PlayVideoButton from '../../../assets/icons/home/play_video.svg';
import PlaylistIcon from '../../../assets/icons/home/playlist.svg';
import PurchaseCheck from '../../../assets/icons/home/purchased_check.svg';
import repeatIcon from '../../../assets/icons/home/repeat.svg';
import ShareIcon from '../../../assets/icons/home/share.svg';
import ShareMoreArrowIcon from '../../../assets/icons/home/share_more_arrow.svg';
import ShareIconWeb from '../../../assets/icons/home/share_web.svg';
import SmsShareOptionIcon from '../../../assets/icons/home/sms_share_option.svg';
import StartLearningIcon from '../../../assets/icons/home/start_learning.svg';
import StartPracticeIcon from '../../../assets/icons/home/start_practice.svg';
import ThumbDownIcon from '../../../assets/icons/home/thumb_down.svg';
import ThumbDownFilledIcon from '../../../assets/icons/home/thumb_down_filled.svg';
import TickIcon from '../../../assets/icons/home/tick.svg';
import TimerIcon from '../../../assets/icons/home/timer.svg';
import TruthOrLieIcon from '../../../assets/icons/home/truth_or_lie_icon.svg';
import VersusIcon from '../../../assets/icons/home/versus.svg';
import VolumeOffButton from '../../../assets/icons/home/volume_off.svg';
import VolumeOnButton from '../../../assets/icons/home/volume_on.svg';
import WeirdContentIcon from '../../../assets/icons/home/weird_content.svg';
import appleButtonPic from '../../../assets/icons/login/apple_button_pic.svg';
import check from '../../../assets/icons/login/check.svg';
import emailButtonPic from '../../../assets/icons/login/email_button_pic.svg';
import eye from '../../../assets/icons/login/eye.svg';
import eyeSlash from '../../../assets/icons/login/eye_slash.svg';
import facebookButtonPic from '../../../assets/icons/login/facebook_button_pic.svg';
import googleButtonPic from '../../../assets/icons/login/google_button_pic.svg';
import hidden from '../../../assets/icons/login/hidden.svg';
import instaButtonPic from '../../../assets/icons/login/insta_button_pic.svg';
import LoginQuestionButton from '../../../assets/icons/login/question_button.svg';
import twitterButtonPic from '../../../assets/icons/login/twitter_button_pic.svg';
import visible from '../../../assets/icons/login/visible.svg';
import Abraham from '../../../assets/icons/messages/abraham.svg';
import AlbertEinsten from '../../../assets/icons/messages/albert_einsten.svg';
import Aristotle from '../../../assets/icons/messages/aristotle.svg';
import ChatDislikeIcon from '../../../assets/icons/messages/chat_dislike_icon.svg';
import ChatLikeIcon from '../../../assets/icons/messages/chat_like_icon.svg';
import DotIcon from '../../../assets/icons/messages/dot_icon.svg';
import ElvisPresley from '../../../assets/icons/messages/elvis_presley.svg';
import GeorgeWashington from '../../../assets/icons/messages/george_washington.svg';
import SkullCommentIcon from '../../../assets/icons/messages/ghost_icon.svg';
import IsaacNewton from '../../../assets/icons/messages/isaac_newton.svg';
import MahatmaGandhi from '../../../assets/icons/messages/mahatma_gandhi.svg';
import MartinLuther from '../../../assets/icons/messages/martin_luther.svg';
import SearchIcon from '../../../assets/icons/messages/search_icon.svg';
import MessageSendIcon from '../../../assets/icons/messages/send_icon.svg';
import TheodoreRoosevelt from '../../../assets/icons/messages/theodore_roosevelt.svg';
import WinstonChurchill from '../../../assets/icons/messages/winston_churchill.svg';
import CoinPileIcon from '../../../assets/icons/profile/coin_pile_icon.svg';
import photoIcon from '../../../assets/icons/profile/photo.svg';
import SelectorArrow from '../../../assets/icons/profile/selector_arrow.svg';
import SelectorTick from '../../../assets/icons/profile/selector_tick.svg';
import ThumbsUpStat from '../../../assets/icons/profile/thumbs_up_stat.svg';
import ShareCompactIcon from '../../../assets/icons/share_compact.svg';
import TikTokIcon from '../../../assets/icons/tiktok.svg';
import BookMark from '../../TestPrep/assets/bookmark.svg';
import Bullseye from '../../TestPrep/assets/bullseye.svg';
import BullseyeArrow from '../../TestPrep/assets/bullseye_arrow.svg';
import TestChatIcon from '../../TestPrep/assets/chat.svg';
import CorrectOptionIcon from '../../TestPrep/assets/correct_option.svg';
import CorrectOptionWithBG from '../../TestPrep/assets/correct_option_bg_green.svg';
import TestPrepDashGrade from '../../TestPrep/assets/dash_grade.svg';
import FlagIcon from '../../TestPrep/assets/flag.svg';
import TestLaptopIcon from '../../TestPrep/assets/laptop.svg';
import TestLightBulbIcon from '../../TestPrep/assets/light_bulb.svg';
import MagnifyingGlass from '../../TestPrep/assets/magnifying_glass.svg';
import TestPrepMobile from '../../TestPrep/assets/mobile-button-duotone.svg';
import TestNotesIcon from '../../TestPrep/assets/notes.svg';
import PauseTimerIcon from '../../TestPrep/assets/pause_timer_icon.svg';
import PreviousQuestionIcon from '../../TestPrep/assets/previous_question_icon.svg';
import TestPrepShare from '../../TestPrep/assets/share.svg';
import SkippedAnswerIcon from '../../TestPrep/assets/skipped_answer.svg';
import StarIcon from '../../TestPrep/assets/star.svg';
import TimerClock from '../../TestPrep/assets/timer-clock.svg';
import TimerClockWithoutBackground from '../../TestPrep/assets/timer_clock_without_background.svg';
import TimesUpHourglass from '../../TestPrep/assets/times_up_hourglass.svg';
import WrongOptionIcon from '../../TestPrep/assets/wrong_option.svg';
import WrongOptionWithBG from '../../TestPrep/assets/wrong_option_bg_red.svg';
import YellowStar from '../../TestPrep/assets/yellow-star.svg';

export type Asset = {
  type: 'vector' | 'bitmap';
  path: string | FunctionComponent<SVGAttributes<SVGElement>>;
};

export default class Assets {
  static getVector(path: FunctionComponent<SVGAttributes<SVGElement>>): Asset {
    return {
      type: 'vector',
      path: path,
    };
  }

  static getBitmap(path: string): Asset {
    return {
      type: 'bitmap',
      path: path,
    };
  }

  static studyTabIcon = Assets.getVector(StudyTabIcon);
  static testTabIcon = Assets.getVector(TestTabIcon);
  static favoritesTabIcon = Assets.getVector(FavoritesTabIcon);
  static inboxTabIcon = Assets.getVector(InboxTabIcon);
  static profileTabIcon = Assets.getVector(ProfileTabIcon);
  static leaderboardTabIcon = Assets.getVector(LeaderboardTabIcon);

  static shareCompactIcon = Assets.getVector(ShareCompactIcon);
  static tiktokIcon = Assets.getVector(TikTokIcon);

  static friendsTabIcon = Assets.getVector(FriendsTabIcon);
  static bookmarksTabIcon = Assets.getVector(BookmarksTabIcon);
  static discoverTabIcon = Assets.getVector(DiscoverTabIcon);
  static platformIcon = Assets.getBitmap(
    require('../../../assets/images/platform/icon.png'),
  );

  static home = {
    timerIcon: Assets.getVector(TimerIcon),
    likeIcon: Assets.getVector(LikeIcon),
    bookmarkIcon: Platform.select({
      default: Assets.getVector(BookmarkIcon),
      web: Assets.getVector(BookmarkIconWeb),
    }),
    commentsIcon: Assets.getVector(CommentsIcon),
    shareIcon: Platform.select({
      default: Assets.getVector(ShareIcon),
      web: Assets.getVector(ShareIconWeb),
    }),
    flipIcon: Assets.getVector(FlipIcon),
    paperIcon: Assets.getVector(Paper),
    paperPlusIcon: Assets.getVector(PaperPlus),
    playlistIcon: Assets.getVector(PlaylistIcon),
    rightChevronIcon: Assets.getVector(RightChevron),
    leftChevronIcon: Assets.getVector(LeftChevron),

    tickIcon: Assets.getVector(TickIcon),
    crossIcon: Assets.getVector(CrossIcon),
    followAvatarTickIcon: Assets.getVector(FollowAvatarTick),
    closeItemCrossIcon: Assets.getVector(CloseItemCross),

    cardEyeClosedIcon: Assets.getVector(CardEyeClosed),
    cardEyeOpenIcon: Assets.getVector(CardEyeOpen),
    cardNoTextIcon: Assets.getVector(CardNoText),
    answerInfoIcon: Assets.getVector(answerInfo),

    tapToRevealGif: Assets.getBitmap(
      require('../../../assets/gifs/tap_to_reveal.gif'),
    ),

    notInterestedIcon: Assets.getVector(NotInterestedIcon),
    clearDisplayIcon: Assets.getVector(ClearDisplayIcon),
    emailShareOptionIcon: Assets.getVector(EmailShareOptionIcon),
    smsShareOptionIcon: Assets.getVector(SmsShareOptionIcon),
    linkShareOptionIcon: Assets.getVector(LinkShareOptionIcon),
    shareMoreArrowIcon: Assets.getVector(ShareMoreArrowIcon),

    commentSendIcon: Assets.getVector(CommentSendIcon),
    thumbDownIcon: Assets.getVector(ThumbDownIcon),

    volumeOnButtonIcon: Assets.getVector(VolumeOnButton),
    volumeOffButtonIcon: Assets.getVector(VolumeOffButton),

    circleHintIcon: Assets.getVector(CircleHintIcon),
    playVideoButtonIcon: Assets.getVector(PlayVideoButton),

    keyboardIcon: Assets.getVector(KeyboardIcon),

    lightbulbIcon: Assets.getVector(lightbulbIcon),

    dykTypeIcon: Assets.getVector(dykTypeIcon),

    mcqTypeIcon: Assets.getVector(mcqTypeIcon),
    matchingPairsTypeIcon: Assets.getVector(matchingPairsTypeIcon),

    repeatIcon: Assets.getVector(repeatIcon),
    controversialTypeIcon: Assets.getVector(ControversialIcon),
    versusIcon: Assets.getVector(VersusIcon),
    truthOrLieTypeIcon: Assets.getVector(TruthOrLieIcon),

    correctAnswerGif: Assets.getBitmap(
      require('../../../assets/gifs/correct_answer.gif'),
    ),

    wrongAnswerGif: Assets.getBitmap(
      require('../../../assets/gifs/wrong_answer.gif'),
    ),

    thumbDownFilledIcon: Assets.getVector(ThumbDownFilledIcon),
    crossedImageIcon: Assets.getVector(CrossedImageIcon),
    incorrectContentIcon: Assets.getVector(IncorrectContentIcon),

    weirdContentIcon: Assets.getVector(WeirdContentIcon),

    startLearningIcon: Assets.getVector(StartLearningIcon),
    startPracticeIcon: Assets.getVector(StartPracticeIcon),

    otherOptionsIcon: Assets.getVector(OtherOptionsIcon),
    chatIcon: Assets.getVector(ChatIcon),
    coinIcon: Assets.getBitmap(require('../../../assets/images/coin_icon.png')),
    raiseHandIcon: Assets.getVector(RaiseHandIcon),
    infinityCircle: Assets.getVector(InfinityCircle),
    purchaseCheck: Assets.getVector(PurchaseCheck),
  };

  static test = {
    star: Assets.getVector(StarIcon),
    expandQuestionGif: Assets.getBitmap(
      require('../../../assets/gifs/expand_question.gif'),
    ),
    timerIconWithouBackground: Assets.getVector(TimerClockWithoutBackground),
    bookMark: Assets.getVector(BookMark),
    timerClock: Assets.getVector(TimerClock),
    avatar: Assets.getBitmap(require('../../TestPrep/assets/avatar.png')),
    yellowStar: Assets.getVector(YellowStar),
    correctOption: Assets.getVector(CorrectOptionIcon),
    wrongOption: Assets.getVector(WrongOptionIcon),
    correctOptionWithBG: Assets.getVector(CorrectOptionWithBG),
    wrongOptionWithBG: Assets.getVector(WrongOptionWithBG),
    skippedAnswer: Assets.getVector(SkippedAnswerIcon),

    chat: Assets.getVector(TestChatIcon),
    notes: Assets.getVector(TestNotesIcon),
    laptop: Assets.getVector(TestLaptopIcon),
    mobile: Assets.getVector(TestPrepMobile),
    share: Assets.getVector(TestPrepShare),

    notAvailableGif: Assets.getBitmap(
      require('../../../assets/gifs/high_school_not_available.gif'),
    ),
    lightBulbIcon: Assets.getVector(TestLightBulbIcon),
    magnifyingGlass: Assets.getVector(MagnifyingGlass),
    bullseyeArrow: Assets.getVector(BullseyeArrow),
    bullseye: Assets.getVector(Bullseye),
    bullseyeArrowGif: Assets.getBitmap(
      require('../../../assets/gifs/bullseye_arrow.gif'),
    ),
    pauseTimerIcon: Assets.getVector(PauseTimerIcon),

    dashGrade: Assets.getVector(TestPrepDashGrade),

    cuteDisappointedBun: Assets.getBitmap(
      require('../../TestPrep/assets/cute_disappointed_bun.gif'),
    ),
    timesUpHourglass: Assets.getVector(TimesUpHourglass),
    previousQuestionIcon: Assets.getVector(PreviousQuestionIcon),
    flagIcon: Assets.getVector(FlagIcon),
  };

  static userGoal = {
    userGoalGif: Assets.getBitmap(
      require('../../../assets/gifs/user_goal.gif'),
    ),
  };

  static discover = {
    soonTag: Assets.getVector(SoonTag),
  };

  static bookmarks = {
    emptyBox: Assets.getBitmap(require('../../../assets/images/empty_box.png')),
  };

  static feedback = {
    bugIcon: Assets.getVector(BugIcon),
    featureFeedbackIcon: Assets.getVector(FeatureFeedbackIcon),
    contentFeedbackIcon: Assets.getVector(ContentFeedbackIcon),
    generalFeedbackIcon: Assets.getVector(GeneralFeedbackIcon),
    feedbackSubmitGif: Assets.getBitmap(
      require('../../../assets/gifs/feedback_submitted.gif'),
    ),
  };

  static tutorial = {
    tutorial_1_header: Assets.getBitmap(
      require('../../../assets/images/tutorial/tutorial_1_header.png'),
    ),
    tutorial_2_header: Assets.getBitmap(
      require('../../../assets/images/tutorial/tutorial_2_header.png'),
    ),
    tutorial_3_header: Assets.getBitmap(
      require('../../../assets/images/tutorial/tutorial_3_header.png'),
    ),

    tutorial_1_screen: Assets.getBitmap(
      require('../../../assets/images/tutorial/tutorial_1_screen.png'),
    ),
    tutorial_2_screen: Assets.getBitmap(
      require('../../../assets/images/tutorial/tutorial_2_screen.png'),
    ),
    tutorial_3_screen: Assets.getBitmap(
      require('../../../assets/images/tutorial/tutorial_3_screen.png'),
    ),
  };

  static courses = {
    progressTick: Assets.getVector(ProgressTick),
    courseCompletionGif: Assets.getBitmap(
      require('../../../assets/gifs/course_completion.gif'),
    ),
    unitCompletionGif: Assets.getBitmap(
      require('../../../assets/gifs/unit_completion.gif'),
    ),

    levelMasteredGif: Assets.getBitmap(
      require('../../../assets/gifs/level_mastered.gif'),
    ),

    bonusPointsEarnedGif: Assets.getBitmap(
      require('../../../assets/gifs/bonus_points_earned.gif'),
    ),
  };

  static message = {
    skullCommentIcon: Assets.getVector(SkullCommentIcon),
    abraham: Assets.getVector(Abraham),
    albertEinsten: Assets.getVector(AlbertEinsten),
    aristotle: Assets.getVector(Aristotle),
    elvisPresley: Assets.getVector(ElvisPresley),
    issacNewton: Assets.getVector(IsaacNewton),
    georgeWashington: Assets.getVector(GeorgeWashington),
    martinLuther: Assets.getVector(MartinLuther),
    theodoreRoosevelt: Assets.getVector(TheodoreRoosevelt),
    winstonChurchill: Assets.getVector(WinstonChurchill),
    mahatmaGandhi: Assets.getVector(MahatmaGandhi),
    searchIcon: Assets.getVector(SearchIcon),
    messageSendIcon: Assets.getVector(MessageSendIcon),
    dotIcon: Assets.getVector(DotIcon),
    dotGif: Assets.getBitmap(require('../../../assets/gifs/dots_loading.gif')),
    chatLikeIcon: Assets.getVector(ChatLikeIcon),
    chatDislikeIcon: Assets.getVector(ChatDislikeIcon),
    arrowRight: Assets.getVector(ArrowRight),
  };

  static profile = {
    selectorArrowIcon: Assets.getVector(SelectorArrow),
    selectorTickIcon: Assets.getVector(SelectorTick),
    photoIcon: Assets.getVector(photoIcon),
    thumbsUpStatIcon: Assets.getVector(ThumbsUpStat),
    coinPileIcon: Assets.getVector(CoinPileIcon),
  };

  static login = {
    questionButtonIcon: Assets.getVector(LoginQuestionButton),
    emailButtonPicIcon: Assets.getVector(emailButtonPic),
    googleButtonPicIcon: Assets.getVector(googleButtonPic),
    facebookButtonPicIcon: Assets.getVector(facebookButtonPic),
    twitterButtonPicIcon: Assets.getVector(twitterButtonPic),
    appleButtonPicIcon: Assets.getVector(appleButtonPic),
    instaButtonPicIcon: Assets.getVector(instaButtonPic),
    hiddenIcon: Assets.getVector(hidden),
    visibleIcon: Assets.getVector(visible),
    checkIcon: Assets.getVector(check),
    eyeIcon: Assets.getVector(eye),
    eyeSlashIcon: Assets.getVector(eyeSlash),
  };

  static common = {
    backArrowButtonIcon: Assets.getVector(BackArowButton),
    check: Assets.getVector(CheckIcon),
  };

  static splash = {
    splashScreen: Assets.getBitmap(
      require('../../../assets/images/splash/bootsplash_logo.png'),
    ),
  };

  static updateApp = {
    arrowRightIcon: Assets.getVector(ArrowRight),
  };

  //static avatar = Assets.getBitmap(require('../../assets/imgs/avatar.png'));
}
