import * as Sentry from '@sentry/react-native';
import moment from 'moment';
import { Linking } from 'react-native';
import { InAppBrowser } from 'react-native-inappbrowser-reborn';
import VersionCheck from 'react-native-version-check';

import { AppInstallerPackageName } from '../../../App/entities';
import { getRootState } from '../../../App/services/utils';
import { CommonExamType, CommonTrueFalse } from '../../entities';

import { isWebPlatform } from './AppConstants';
import { ENV_KEYS, environment } from './DynamicEnvironment';
import { DeviceInfo } from './polyfills';

export const roundToTenth = (num: number): string => {
  var rounded = Math.round(num * 10) / 10;
  return rounded.toString().replace(/\.0$/, '');
};

export const validateEmail = (email: string): boolean => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const redirectToAppstore = (): void => {
  const WEB_APP_REDIRECT_LINK = environment.getValue(
    ENV_KEYS.WEB_APP_REDIRECT_LINK,
  );
  if (isWebPlatform) {
    window.location.href = WEB_APP_REDIRECT_LINK;
    return;
  }

  Linking.openURL(WEB_APP_REDIRECT_LINK).catch(error => {
    Sentry.captureException(error, {
      tags: {
        type: 'redirectToAppstore',
      },
      extra: { link: WEB_APP_REDIRECT_LINK },
    });
  });
};

export const redirectToLink = (link: string): void => {
  if (isWebPlatform) {
    window.location.href = link;
    return;
  }

  Linking.openURL(link).catch(error => {
    Sentry.captureException(error, {
      tags: {
        type: 'redirectToLink',
      },
      extra: { link },
    });
  });
};

type Env =
  | 'development'
  | 'qc'
  | 'production'
  | 'unknown (possible CI/CD issue)';

export const getAppEnvironment = (): Env => {
  if (isWebPlatform) {
    return 'development';
  }

  // get app bundle version string
  const currentAppVersion = VersionCheck.getCurrentVersion() ?? '0.1';

  // development if app is running in debug mode
  if (__DEV__ || currentAppVersion === '0.1') {
    return 'development';
  }

  // if currentAppVersion is x.y.z return qc
  if (currentAppVersion.split('.').length === 3) {
    return 'qc';
  }

  // if currentAppVersion is x.y return production
  if (currentAppVersion.split('.').length === 2) {
    return 'production';
  }

  return 'unknown (possible CI/CD issue)';
};

export const isDevelopmentEnv = (): boolean => {
  return getAppEnvironment() === 'development';
};

export const isProductionEnv = (): boolean => {
  return getAppEnvironment() === 'production';
};

export const isQcEnv = (): boolean => {
  return getAppEnvironment() === 'qc';
};

export const isAppStoreBuild = (): boolean =>
  DeviceInfo.getInstallerPackageNameSync() === AppInstallerPackageName.APPSTORE;

export const formatNumber = (num: number): string => {
  // 12340 -> "12,340"
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const formatLocalizedPrice = (
  localizedPrice?: string,
): string | undefined => {
  if (!localizedPrice) {
    return localizedPrice;
  }
  if (localizedPrice.endsWith('.00') || localizedPrice.endsWith(',00')) {
    return localizedPrice.slice(0, -3);
  }
  return localizedPrice;
};

export const selectRandomFromArray = <T>(array: T[]): T => {
  return array[Math.floor(Math.random() * array.length)];
};

export const checkIfDateBeforeCoolDown = (
  dateIsoString: number | string | Date,
  coolDownMs: number,
): boolean => {
  const date = new Date(dateIsoString);
  const currentDate = new Date();
  return currentDate.getTime() - date.getTime() < coolDownMs;
};

export const checkIfShouldSkipActionWithCoolDown = (
  coolDownMs: number,
  dateIsoString?: number | string | Date,
  enforce?: boolean,
): boolean => {
  if (enforce || !dateIsoString) {
    return false;
  }

  return checkIfDateBeforeCoolDown(dateIsoString, coolDownMs);
};

export const getExamTypeFromString = (
  examType?: string,
): CommonExamType | undefined => {
  if (CommonExamType.SAT === examType) {
    return CommonExamType.SAT;
  } else if (CommonExamType.AP === examType) {
    return CommonExamType.AP;
  }

  return undefined;
};

export const getOrdinalSuffixOf = (number: number): string => {
  const j = number % 10;
  const k = number % 100;

  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
};

export const openWebLink = async (url: string): Promise<void> => {
  if (isWebPlatform) {
    Linking.openURL(url);
    return;
  }

  if (await InAppBrowser.isAvailable()) {
    await InAppBrowser.open(url);
  } else {
    Linking.openURL(url);
  }
};

export const getUserLocalTimeStamp = (): string =>
  moment().format('YYYY-MM-DDTHH:mm:ss');

export const getIsAppActive = (): boolean => {
  return getRootState().app.isAppActive;
};

export const getBoolAnalyticsText = (value?: boolean): CommonTrueFalse => {
  return value ? CommonTrueFalse.TRUE : CommonTrueFalse.FALSE;
};
