import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Alert, AppState, Linking, Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import {
  Notification,
  NotificationBackgroundFetchResult,
  NotificationCompletion,
  Notifications,
  Registered,
  RegistrationError,
} from 'react-native-notifications';
import { PersistConfig, persistReducer } from 'redux-persist';

import locale from '../../../App/locale';
import { RootNavigatorRef } from '../../../App/navigation/RootNavigator';
import { resetDeviceToken, setDeviceToken } from '../../../App/services/slices';
import { handleNetworkActionErrorSilently } from '../../../App/services/utils';
import store, { RootState } from '../../../App/store';
import { shouldShowUpdateScreen } from '../../../AppVersion/services/slices';
import { ApResultsEntryPoint } from '../../../ApResults/entities';
import { setApResultsEntryPoint } from '../../../ApResults/services/slices';
import { DEEPLINK_SCHEME } from '../../../Common/data';
import { Course, PaywallEntryPoint, Unit } from '../../../Common/entities';
import {
  getActiveRouteName,
  trackAnalyticsEvent,
  updateUserProperties,
} from '../../../Common/services/utils';
import {
  Analytics,
  Durations,
  isIOSPlatform,
  isWebPlatform,
  MainStack,
  ScreenNames,
  TabNames,
  TestStack,
} from '../../../Common/services/utils/AppConstants';
import { SelectedCourseEntryPoint } from '../../../CourseEnrollment/entities';
import { setCurrentSelectedCourse } from '../../../CourseEnrollment/services/slices';
import { MessageScreenEntryPoints } from '../../../Messages/entities';
import {
  getAllReceivedDataFromNotification,
  getDMFigures,
  getTotalUnreadMessages,
  getVideoReceivedFromNotification,
  navigateToMessageScreen,
  setReceivedDataFromNotification,
} from '../../../Messages/services/slices';
import { shouldShowOnboarding } from '../../../Onboarding/services/mappers';
import { setShowNotificationRequestScreen } from '../../../Onboarding/services/slices';
import { PaywallScreenProps } from '../../../Paywall/screens/PaywallScreen';
import { SATResultsEntryPoint } from '../../../SATResults/entities';
import { setSATResultsEntryPoint } from '../../../SATResults/services/slices';
import { TestPrepCourseAlgorithm } from '../../../TestPrep/algorithms';
import { TestPrepEntryPoint } from '../../../TestPrep/entities';
import { TestPreviewOrigin } from '../../../TestPrep/screens';
import { setTestPrepEntryPoint } from '../../../TestPrep/services/slices';
import {
  NotificationRedirectionsScreen,
  PushNotificationEnvironment,
  RegisterPushNotificationsListenersProps,
  ShowCoursePaywallNotificationPayload,
  StartUnitTestNotificationPayload,
  TestPrepTabNotificationPayload,
  TrackNotificationPermissionPayload,
  VideoNotificationType,
} from '../../entities/NotificationTypes';
import { emitUpdateCourseUnitEvent } from '../../events/NotificationEmitters';
import {
  registerDeviceForPushNotificationsGraphQLCall,
  unregisterDeviceForPushNotificationsGraphQLCall,
} from '../../graphql/mutations/NotificationGraphQLMutation';
import { updateBadgeCount } from '../utils/NotificationUtil';

const SLICE_NAME = 'NotificationSlice';

type State = {
  // flag to track if the user has allowed notifications
  isNotificationAllowed?: boolean;

  // flag to track if local-notification cleared-out. One time flag.
  isLocalNotificationCleared: boolean;

  // init notification trigger called
  isInitialNotificationDataProcessed: boolean;

  // notification request time - ISO string
  notificationRequestTime?: string;
};

const persistConfig = {
  key: SLICE_NAME,
  storage: AsyncStorage,
  whitelist: ['isNotificationAllowed', 'isLocalNotificationCleared'],
  blacklist: ['isInitialNotificationDataProcessed', 'notificationRequestTime'],
} as PersistConfig<State>;

const initialState: State = {
  isNotificationAllowed: undefined,
  isLocalNotificationCleared: false,
  isInitialNotificationDataProcessed: false,
  notificationRequestTime: undefined,
};

const registerPushNotificationsListeners = (
  props: RegisterPushNotificationsListenersProps,
) => {
  Notifications.events().registerRemoteNotificationsRegistered(
    (event: Registered) => props.onRegistered(event.deviceToken),
  );
  Notifications.events().registerRemoteNotificationsRegistrationFailed(
    (event: RegistrationError) => props.onRegistrationFailed(event),
  );
  Notifications.events().registerRemoteNotificationsRegistrationDenied(() =>
    props.onRegistrationDenied(),
  );
  Notifications.events().registerNotificationReceivedForeground(
    (
      notification: Notification,
      completion: (response: NotificationCompletion) => void,
    ) => {
      props.onForegroundNotification(notification);
      completion({ alert: true, sound: true, badge: false });
    },
  );
  Notifications.events().registerNotificationReceivedBackground(
    (
      notification: Notification,
      completion: (response: NotificationBackgroundFetchResult) => void,
    ) => {
      props.onBackgroundNotification(notification, completion);
    },
  );
  Notifications.events().registerNotificationOpened(
    (notification: Notification, completion: () => void) => {
      props.onNotificationOpened(notification, completion);
    },
  );
};

export const getNotificationPermission = createAsyncThunk(
  `${SLICE_NAME}/getNotificationPermission`,
  async _ => {
    if (isWebPlatform) {
      return undefined;
    }

    try {
      const result = await Notifications.isRegisteredForRemoteNotifications();
      if (result) {
        Notifications.registerRemoteNotifications();
      }

      return result;
    } catch (e) {
      if (e instanceof Error) {
        handleNetworkActionErrorSilently(e);
      }
      return false;
    }
  },
);

export const subscribeForPushNotifications = createAsyncThunk(
  `${SLICE_NAME}/subscribeForPushNotifications`,
  (_, thunkAPI) => {
    if (isWebPlatform) {
      return;
    }

    thunkAPI.dispatch(setNotificationRequestTime(new Date().toISOString()));
    Notifications.registerRemoteNotifications();
  },
);

export const unsubscribeFromPushNotifications = createAsyncThunk(
  'LifecycleSlice/unsubscribeFromPushNotifications',
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    if (isWebPlatform || !state.lifecycle.deviceToken) {
      return;
    }

    try {
      thunkApi.dispatch(resetDeviceToken());
      thunkApi.dispatch(setNotificationRequestTime());
      await unregisterDeviceForPushNotificationsGraphQLCall(
        await DeviceInfo.getUniqueId(),
      );
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);

export const trackNotificationPermission = createAsyncThunk(
  `${SLICE_NAME}/trackNotificationPermission`,
  async (payload: TrackNotificationPermissionPayload, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const isNotificationAllowed = state.notification.isNotificationAllowed;
    const { allowed } = payload;
    let autoRejectNotificationScreen = true;
    updateUserProperties({
      pushNotificationsOptIn: allowed,
    });

    if (!allowed) {
      const notificationRequestTime =
        state.notification.notificationRequestTime;

      // if the notification request time is less than 500ms, we want to make sure
      // user at least see the notification request message
      if (notificationRequestTime) {
        const requestTime = new Date(notificationRequestTime);
        const currentTime = new Date();

        const diff = currentTime.getTime() - requestTime.getTime();
        if (diff <= Durations.quickTransition) {
          autoRejectNotificationScreen = false;

          Alert.alert(
            locale.notification_request.request_settings_title,
            locale.notification_request.request_settings_description,
            [
              {
                text: locale.notification_request.open_settings,
                onPress: () => {
                  thunkApi.dispatch(setShowNotificationRequestScreen(false));
                  Linking.openSettings();
                },
              },
              {
                text: locale.common.not_now,
                style: 'destructive',
                onPress: () => {
                  thunkApi.dispatch(setShowNotificationRequestScreen(false));
                },
              },
            ],
          );
        }
      }
    }

    if (isNotificationAllowed !== allowed) {
      trackAnalyticsEvent(Analytics.notificationPermission, {
        allowed: allowed ? 'TRUE' : 'FALSE',
      });
    }

    thunkApi.dispatch(setIsNotificationAllowed(allowed));

    if (autoRejectNotificationScreen) {
      thunkApi.dispatch(setShowNotificationRequestScreen(false));
    }
  },
);

export const clearScheduledLocalNotification = createAsyncThunk(
  `${SLICE_NAME}/clearLocalNotification`,
  async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    if (rootState.notification.isLocalNotificationCleared) {
      return;
    }

    if (isIOSPlatform) {
      Notifications.ios.cancelAllLocalNotifications();
    }

    thunkApi.dispatch(setIsLocalNotificationCleared());
  },
);

export const isNavigationReadyForNotification = (): boolean => {
  const state = store.getState() as RootState;
  const isAppLoaded = state.lifecycle.isAppLoaded;
  const isAppStateActiveOrBackground =
    AppState.currentState === 'active' ||
    AppState.currentState === 'background' ||
    AppState.currentState === 'inactive';

  return isAppLoaded && isAppStateActiveOrBackground;
};

export const isAppMainPageLoaded = (): boolean => {
  const state = store.getState() as RootState;
  const isAppLoading = state.lifecycle.appLoading;

  const isAuthLoading = state.auth.signInLoading || state.signup.isLoading;
  const isLoggedIn = !!state.auth.authUser?.id;
  const shouldShowAuthScreen = !isLoggedIn || isAuthLoading;

  const shouldOpenUpdateScreen = shouldShowUpdateScreen(state.appVersion);

  const hasBeenLoggedInBefore = state.auth.hasBeenLoggedInBefore;
  const showOnboarding = shouldShowOnboarding(state.onboarding, isLoggedIn);
  const shouldOpenOnboarding =
    (!hasBeenLoggedInBefore || showOnboarding) && !isAuthLoading;

  const shouldShowCourseListOnboarding =
    state.courseEnrollments.showCourseListOnboarding;
  const shouldShowTutorialScreen = state.tutorial.showTutorialScreen;

  return (
    !isAppLoading &&
    !shouldShowAuthScreen &&
    !shouldOpenUpdateScreen &&
    !shouldOpenOnboarding &&
    !shouldShowCourseListOnboarding &&
    !shouldShowTutorialScreen
  );
};

const shouldRedirectToScreen = (): {
  openStudyScreen: boolean;
  shouldSelectTestPrepTab: boolean;
} => {
  const state = store.getState() as RootState;

  const isAuthLoading =
    state.auth.signInLoading ||
    state.signup.isLoading ||
    state.signup.signUpLoading;

  const isUserSignedIn = !!state.auth.authUser?.id;

  const shouldSelectTestPrepTab =
    TestPrepCourseAlgorithm.shouldSelectTestPrepAsDefaultTab(
      state.courseEnrollments.following,
      state.courses.coursesMap,
    );

  // a bit complicated because we want to make sure the app is fully loaded
  // before we navigate to the user goal screen
  const openStudyScreen =
    !state.lifecycle.appLoading &&
    isUserSignedIn &&
    !isAuthLoading &&
    !shouldShowUpdateScreen(state.appVersion) &&
    !shouldShowOnboarding(state.onboarding, isUserSignedIn) &&
    !state.courseEnrollments.showCourseListOnboarding &&
    !state.tutorial.showTutorialScreen;
  return { openStudyScreen, shouldSelectTestPrepTab };
};

export const getFilterCourseSelector = (
  state: RootState,
  courseId: string,
): Course | null =>
  state.courses.coursesMap[courseId]
    ? state.courses.coursesMap[courseId]
    : null;

export const getFilterUnitSelector = (
  state: RootState,
  courseId: string,
  unitId: string,
): Unit | null => {
  const filterCourse = getFilterCourseSelector(state, courseId);
  if (!filterCourse) {
    return null;
  }
  return filterCourse.units.find(unit => unit.id === unitId) ?? null;
};

export const getRootNavigationState = (): any => {
  return RootNavigatorRef.current?.getRootState();
};

const handleNavigationState = () => {
  const rootNavState = getRootNavigationState();
  const currentRouteName = getActiveRouteName(rootNavState);
  return currentRouteName;
};

export const handleRedirectionToTestPrep = createAsyncThunk(
  `${SLICE_NAME}/handleRedirectionToTestPrep`,
  async (payload: TestPrepTabNotificationPayload, thunkApi) => {
    const isReadyToNavigate = isNavigationReadyForNotification();
    if (!isReadyToNavigate) {
      return;
    }

    const { openStudyScreen, shouldSelectTestPrepTab } =
      shouldRedirectToScreen();

    if (openStudyScreen && RootNavigatorRef.isReady()) {
      const courseId = payload.courseId;
      thunkApi.dispatch(
        setCurrentSelectedCourse({
          id: courseId,
          tab: SelectedCourseEntryPoint.TEST_PREP,
        }),
      );

      thunkApi.dispatch(
        setTestPrepEntryPoint(TestPrepEntryPoint.PUSH_NOTIFICATION),
      );

      const route = handleNavigationState();
      if (route === MainStack.TEST_SCREEN) {
        RootNavigatorRef.current?.goBack();
      } else {
        RootNavigatorRef?.current?.reset({
          index: 0,
          routes: [
            {
              name: shouldSelectTestPrepTab ? TabNames.TEST : TabNames.HOME,
              params: {
                screen: TabNames.TEST,
                params: {
                  screen: TestStack.TEST_PREP_LIST,
                },
              },
            },
          ],
        });
      }
    }
  },
);

const handleRedirectionToStartTest = createAsyncThunk(
  `${SLICE_NAME}/handleRedirectionToStartTest`,
  async (payload: StartUnitTestNotificationPayload, thunkApi) => {
    const isReadyToNavigate = isNavigationReadyForNotification();
    if (!isReadyToNavigate) {
      return;
    }

    const state = thunkApi.getState() as RootState;
    const { openStudyScreen } = shouldRedirectToScreen();

    if (openStudyScreen && RootNavigatorRef.isReady()) {
      const courseId = payload.courseId;
      thunkApi.dispatch(
        setCurrentSelectedCourse({
          id: courseId,
          tab: SelectedCourseEntryPoint.TEST_PREP,
        }),
      );

      thunkApi.dispatch(setTestPrepEntryPoint(TestPrepEntryPoint.NAVBAR));

      const course = getFilterCourseSelector(state, courseId);
      const unit = getFilterUnitSelector(state, courseId, payload.unitId);
      if (course && unit) {
        const route = handleNavigationState();
        if (route === MainStack.TEST_SCREEN) {
          emitUpdateCourseUnitEvent({
            course,
            unit,
            origin: TestPreviewOrigin.TEST_PREP_TAB,
          });
        } else {
          RootNavigatorRef?.current?.reset({
            index: 1,
            routes: [
              {
                name: ScreenNames.MainStack.BOTTOM_TABS,
                params: {
                  screen: TabNames.TEST,
                  params: {
                    screen: TestStack.TEST_PREP_LIST,
                    params: {
                      screen: MainStack.TEST_SCREEN,
                    },
                  },
                },
              },
              {
                name: MainStack.TEST_SCREEN,
                params: {
                  course,
                  unit,
                  origin: TestPreviewOrigin.TEST_PREP_TAB,
                },
              },
            ],
          });
        }
      }
    }
  },
);

export const handleRedirectionToPaywall = createAsyncThunk(
  `${SLICE_NAME}/handleRedirectionToPaywall`,
  async (payload: ShowCoursePaywallNotificationPayload, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    const course = getFilterCourseSelector(state, payload.courseId);

    const isReadyToNavigate = isNavigationReadyForNotification();
    if (!isReadyToNavigate) {
      return;
    }

    if (RootNavigatorRef.isReady()) {
      RootNavigatorRef.reset({
        index: 2,
        routes: [
          {
            name: ScreenNames.MainStack.BOTTOM_TABS,
            params: {
              screen: TabNames.PROFILE,
              params: {
                screen: MainStack.PROFILE_MAIN,
                params: { screen: MainStack.COURSE_LIST_PROFILE_SCREEN },
              },
            },
          },
          {
            name: MainStack.COURSE_LIST_PROFILE_SCREEN,
          },
          {
            name: MainStack.PAYWALL_SCREEN,
            params: {
              course,
              entryPoint: PaywallEntryPoint.PUSH_NOTIFICATION,
            } as PaywallScreenProps,
          },
        ],
      });
    }
  },
);

export const handleDmsNotificationReceived = createAsyncThunk(
  `${SLICE_NAME}/handleDmsNotificationReceived`,
  async (payload: { notification: Notification }, thunkApi) => {
    const notification = payload.notification;
    const notificationPayload = {
      ...(notification.payload || {}),
      ...(notification.payload?.aps || {}),
    };

    const generatedContentId = notificationPayload.contentId;

    const isReadyToNavigate = isNavigationReadyForNotification();

    if (generatedContentId) {
      const username = notificationPayload.handle;

      if (username && generatedContentId && isReadyToNavigate) {
        await thunkApi
          .dispatch(
            getVideoReceivedFromNotification({
              contentId: generatedContentId,
              category: notificationPayload.category,
            }),
          )
          .unwrap();

        const state = thunkApi.getState() as RootState;
        const badgeCount = getTotalUnreadMessages(state.messages);
        updateBadgeCount(badgeCount);
      } else if (username && generatedContentId) {
        thunkApi.dispatch(
          setReceivedDataFromNotification({
            figureName: notificationPayload.name,
            username: notificationPayload.handle,
            generatedContentId: generatedContentId,
            receivedAt: new Date().toISOString(),
            category: notificationPayload.category,
          }),
        );
      }
    }
  },
);

export const handleDmsNotificationOpened = createAsyncThunk(
  `${SLICE_NAME}/handleDmsNotificationOpened`,
  async (payload: { notification: Notification }, thunkApi) => {
    const notification = payload.notification;
    const generatedContentId = notification.payload.contentId;

    if (generatedContentId) {
      const username = notification.payload.handle;
      const figureName = notification.payload.name;

      const isReadyToNavigate = isNavigationReadyForNotification();

      if (username && isReadyToNavigate) {
        const category =
          notification.payload.category ??
          notification.payload?.aps.category ??
          'dmsFromDead';
        const entryPoint =
          VideoNotificationType.DMS_FROM_DEAD === category
            ? MessageScreenEntryPoints.DM_PUSH_NOTIFICATION
            : MessageScreenEntryPoints.VIDEO_PUSH_NOTIFICATION;

        trackAnalyticsEvent(Analytics.notificationOpened, {
          category: category,
          title: notification.title,
          body: notification.body,
          segment:
            notification.payload.segment ??
            NotificationRedirectionsScreen.TEST_PREP_TAB.toString(),
          contentId: generatedContentId,
        });

        if (!isWebPlatform) {
          thunkApi.dispatch(
            navigateToMessageScreen({
              username,
              name: figureName,
              entryPoint: entryPoint,
            }),
          );
        }

        const rootState = thunkApi.getState() as RootState;

        if (rootState.messages.receivedDataFromNotification.length) {
          const recordedGeneratedContent =
            rootState.messages.receivedDataFromNotification.some(
              item => item.generatedContentId === generatedContentId,
            );

          await thunkApi
            .dispatch(getAllReceivedDataFromNotification())
            .unwrap();

          if (!recordedGeneratedContent) {
            await thunkApi
              .dispatch(
                getVideoReceivedFromNotification({
                  contentId: generatedContentId,
                  category,
                }),
              )
              .unwrap();
          }
        } else {
          await thunkApi
            .dispatch(
              getVideoReceivedFromNotification({
                contentId: generatedContentId,
                category,
              }),
            )
            .unwrap();
        }

        const state = thunkApi.getState() as RootState;
        const badgeCount = getTotalUnreadMessages(state.messages);
        updateBadgeCount(badgeCount);
      }
    }
  },
);

export const handleProcessReceivedNotification = createAsyncThunk(
  `${SLICE_NAME}/handleProcessReceivedNotification`,
  async (payload: { notification: Notification }, thunkApi) => {
    if (!isWebPlatform) {
      return;
    }

    const notification = payload.notification;
    const notificationPayload = {
      ...(notification.payload || {}),
      ...(notification.payload?.aps || {}),
    };

    const generatedContentId = notificationPayload.contentId;

    if (generatedContentId) {
      thunkApi.dispatch(handleDmsNotificationReceived({ notification }));
    }
  },
);

export const handleNotificationOpened = createAsyncThunk(
  `${SLICE_NAME}/handleNotificationOpened`,
  async (payload: { notification: Notification }, thunkApi) => {
    const { notification } = payload;

    const deeplink = notification.payload.data.pinpoint.deeplink;
    if (deeplink && deeplink.startsWith(`${DEEPLINK_SCHEME}://`)) {
      Linking.openURL(deeplink);
      return;
    }

    const generatedContentId = notification.payload.contentId;
    if (generatedContentId) {
      thunkApi.dispatch(handleDmsNotificationOpened({ notification }));
    } else {
      thunkApi.dispatch(handleNotificationPayload({ notification }));
    }
  },
);

export const handleProcessInitialNotificationData = createAsyncThunk(
  `${SLICE_NAME}/handleProcessInitialNotificationData`,
  async (_, thunkApi) => {
    try {
      if (isWebPlatform) {
        return;
      }

      const notification = await Notifications.getInitialNotification();

      if (notification) {
        // Notification payload here a bit different compared to the one in onNotificationOpened
        // we need to re-assign the "aps" into the "payload" it-self for consistent data structure
        if (notification.payload?.aps) {
          notification.payload = {
            ...notification.payload,
            ...(notification.payload?.aps || {}),
          };
        }

        thunkApi.dispatch(handleNotificationOpened({ notification }));
      }
    } catch (_e) {}
  },
);

export const handleInvalidateInitialNotification = createAsyncThunk(
  `${SLICE_NAME}/handleInvalidateInitialNotification`,
  async _ => {
    if (isWebPlatform) {
      return;
    }

    Notifications.getInitialNotification()
      .then(notification => {
        if (notification && isIOSPlatform && notification?.identifier) {
          Notifications.ios.removeDeliveredNotifications([
            notification.identifier,
          ]);
        }
      })
      .catch(err => console.error('getInitialNotifiation() failed', err));
  },
);

export const handleTrackReceivedNotification = createAsyncThunk(
  `${SLICE_NAME}/handleTrackReceivedNotification`,
  async (
    payload: { notification: Notification; isFromBackground: boolean },
    _thunkApi,
  ) => {
    const notification = payload.notification;

    const category = notification.payload?.aps.category ?? 'dmsFromDead';

    trackAnalyticsEvent(Analytics.notificationReceived, {
      category: category,
      title: notification.title,
      body: notification.body,
      segment: notification.payload?.segment,
      contentId: notification.payload?.contentId,
      username: notification.payload?.handle,
      figureName: notification.payload?.name,
      isBackgroundNotification: payload.isFromBackground,
    });
  },
);

export const handleNotificationPayload = createAsyncThunk(
  `${SLICE_NAME}/handleNotificationPayload`,
  async (payload: { notification: Notification }, thunkApi) => {
    const notification = payload.notification;

    const category = notification.payload.category;
    switch (category) {
      case NotificationRedirectionsScreen.TEST_PREP_TAB.toString():
        trackAnalyticsEvent(Analytics.notificationOpened, {
          category: notification.payload.category,
          title: notification.title,
          body: notification.body,
          segment:
            notification.payload.segment ??
            NotificationRedirectionsScreen.TEST_PREP_TAB.toString(),
          deepLink: `teachtap://testprep?courseId=${notification.payload.courseId}`,
        });
        thunkApi.dispatch(
          handleRedirectionToTestPrep({
            courseId: notification.payload.courseId,
          }),
        );
        break;
      case NotificationRedirectionsScreen.START_UNIT_TEST.toString():
        trackAnalyticsEvent(Analytics.notificationOpened, {
          category: notification.payload.category,
          title: notification.title,
          body: notification.body,
          segment:
            notification.payload.segment ??
            NotificationRedirectionsScreen.START_UNIT_TEST.toString(),
          deepLink: `teachtap://startunittest?courseId=${notification.payload.courseId}&unitId=${notification.payload.unitId}`,
        });
        thunkApi.dispatch(
          handleRedirectionToStartTest({
            courseId: notification.payload.courseId,
            unitId: notification.payload.unitId,
          }),
        );
        break;
      case NotificationRedirectionsScreen.PAYWALL.toString():
        thunkApi.dispatch(
          handleRedirectionToPaywall({
            courseId: notification.payload.courseId,
          }),
        );
        break;
      case NotificationRedirectionsScreen.AP_RESULTS_SCREEN.toString():
        thunkApi.dispatch(
          setApResultsEntryPoint(ApResultsEntryPoint.PUSH_NOTIFICATION),
        );
        break;
      case NotificationRedirectionsScreen.SAT_RESULTS_SCREEN.toString():
        thunkApi.dispatch(
          setSATResultsEntryPoint(SATResultsEntryPoint.PUSH_NOTIFICATION),
        );
        break;
      default:
        break;
    }
  },
);

export const initNotificationListener = createAsyncThunk(
  `${SLICE_NAME}/initNotificationListener`,
  async (_, thunkApi) => {
    registerPushNotificationsListeners({
      onRegistered: async deviceToken => {
        thunkApi.dispatch(trackNotificationPermission({ allowed: true }));

        const state = thunkApi.getState() as RootState;

        const isLoggedIn = !!state.auth.authUser?.id;
        if (!isLoggedIn) {
          return;
        }

        registerDeviceForPushNotificationsGraphQLCall({
          deviceToken: deviceToken,
          devicePlatform: Platform.OS,
          deviceId: await DeviceInfo.getUniqueId(),
          environment: PushNotificationEnvironment.PRODUCTION,
        })
          .then(() => {
            thunkApi.dispatch(setDeviceToken(deviceToken));
            thunkApi.dispatch(getDMFigures());
          })
          .catch(e => {
            handleNetworkActionErrorSilently(e);
          });
      },
      onRegistrationFailed: error => {
        thunkApi.dispatch(trackNotificationPermission({ allowed: false }));

        handleNetworkActionErrorSilently({
          message: error.localizedDescription,
        });
      },
      onRegistrationDenied: () => {
        thunkApi.dispatch(trackNotificationPermission({ allowed: false }));
      },
      onForegroundNotification: function (notification: Notification): void {
        thunkApi.dispatch(
          handleProcessReceivedNotification({
            notification,
          }),
        );

        thunkApi.dispatch(
          handleTrackReceivedNotification({
            notification,
            isFromBackground: false,
          }),
        );
      },
      onBackgroundNotification: async function (
        notification: Notification,
        completion: (response: NotificationBackgroundFetchResult) => void,
      ): Promise<void> {
        thunkApi.dispatch(
          handleProcessReceivedNotification({
            notification,
          }),
        );

        thunkApi.dispatch(
          handleTrackReceivedNotification({
            notification,
            isFromBackground: true,
          }),
        );

        completion(NotificationBackgroundFetchResult.NEW_DATA);
      },
      onNotificationOpened: async function (
        notification: Notification,
        completion: () => void,
      ): Promise<void> {
        thunkApi.dispatch(handleNotificationOpened({ notification }));
        completion();
      },
    });
  },
);

const slice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    setIsNotificationAllowed: (
      state: State,
      action: PayloadAction<boolean>,
    ) => {
      state.isNotificationAllowed = action.payload;
    },
    setIsLocalNotificationCleared: (state: State) => {
      state.isLocalNotificationCleared = true;
    },
    setNotificationRequestTime: (
      state: State,
      action: PayloadAction<string | undefined>,
    ) => {
      state.notificationRequestTime = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(handleProcessInitialNotificationData.fulfilled, state => {
      state.isInitialNotificationDataProcessed = true;
    });
  },
});

export const {
  setIsNotificationAllowed,
  setIsLocalNotificationCleared,
  setNotificationRequestTime,
} = slice.actions;

export default persistReducer(persistConfig, slice.reducer);
