import { RootState } from '../../../App/store';
import { getSATLastScoreData } from '../../../Onboarding/services/mappers';

import {
  mapSatBootcampFreeTrialStatus,
  mapSatBootcampFullAccessStatus,
} from './SATDetailMapper';

export const mapUserSATCurrentScore = (state: RootState): number => {
  return (
    state.SAT.bootcamp?.details?.currentScore || getSATLastScoreData()[0].value
  );
};

export const mapShouldUserFillSATMathAndReadingWritingScore = (
  state: RootState,
): boolean => {
  const isFullAccess = mapSatBootcampFullAccessStatus(state);
  const isFreeTrial = mapSatBootcampFreeTrialStatus(state);

  if (!isFullAccess && !isFreeTrial) {
    return false;
  }

  const { mathScore, readingWritingScore } = state.SAT.bootcamp?.details || {};
  if (Number(mathScore) > 0 && Number(readingWritingScore) > 0) {
    return false;
  }

  return true;
};
